import { useTypedSelector } from "../../../store";
import { Link } from "react-router-dom";

const jobsOffersFunc = state => state.offers_jobs;

const TBodyInnerJobsOffers = ({horizontalScrollState}) => {
	const table = useTypedSelector(jobsOffersFunc);

	//TODO: nt real data
	return (
		<tbody className="table__tbody offers-table">
		{
			table.offers?.map(item => {
				return (
          <tr className="table__tr" key={item.id}>
            <td
              className={`table__td sticky offers-table__column__default  ${
                horizontalScrollState ? "moved" : ""
              }`}
            >
              <Link className="offers-table__link" to={`/recruitment/offers/${item.id}`}>
                {item.jobTitle}
              </Link>
            </td>
            {table.tabFilter.filter(i=>i.active).map((value) => {
              if (value.id === 2) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">{item.jobLocation}</p>
                    </td>
              }
              if (value.id === 3) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">
                        {item.jobOpening}
                      </p>
                    </td>
              }
              if (value.id === 4) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">
                        {item.pendingCandidates}
                      </p>
                    </td>
              }
              if (value.id === 5) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">
                        {item.offerAccepted||0}
                      </p>
                    </td>
              }
              if (value.id === 6) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">
                        {item.offerYetToAccept||0}
                      </p>
                    </td>
              }
              if (value.id === 7) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">
                        {item.offerYetToSend||0}
                      </p>
                    </td>
              }
              if (value.id === 8) {
                return <td
                      className="table__td actions offers-table__column__default"
                      key={value.displayName}
                    >
                      <p className="offers-table__text green">
                        {item.rejectedOffer||0}
                      </p>
                    </td>
              }
            })}
          </tr>
        );
			})
		}
		</tbody>
	);
};

export default TBodyInnerJobsOffers;