export const deadlineList:any = [
	{
		label: '12 Hrs',
		value: 12
	},
	{
		label: '24 Hrs',
		value: 24
	}, {
		label: '36 Hrs',
		value: 36
	},
	{
		label: '48 Hrs',
		value: 48
	},
	{
		label: '60 Hrs',
		value: 60
	},
	{
		label: '72 Hrs',
		value: 72
	}
]

export const durationTime=[
	{
		label: '1 Hrs',
		value: 1
	},
	{
		label: '2 Hrs',
		value: 2
	}, {
		label: '3 Hrs',
		value: 3
	},
	{
		label: '4 Hrs',
		value: 4
	},
	{
		label: '5 Hrs',
		value: 5
	},
	{
		label: '6 Hrs',
		value: 6
	},
]

export const daysOptions = [
	{
		value: 'Select all days',
		label: 'Select all days'
	},
	{
		value: 'Monday',
		label: 'Monday'
	},
	{
		value: 'Tuesday',
		label: 'Tuesday'
	},
	{
		value: 'Wednesday',
		label: 'Wednesday'
	},
	{
		value: 'Thursday',
		label: 'Thursday'
	},
	{
		value: 'Friday',
		label: 'Friday'
	},
	{
		value: 'Saturday',
		label: 'Saturday'
	},
	{
		value: 'Sunday',
		label: 'Sunday'
	},
]

export const timeOptions = [
	{
		label: '12:00 am',
		value: '12:00 am'
	},
	{
		label: '01:00 am',
		value: '01:00 am'
	},
	{
		label: '02:00 am',
		value: '02:00 am'
	},
	{
		label: '03:00 am',
		value: '03:00 am'
	},
	{
		label: '04:00 am',
		value: '04:00 am'
	},
	{
		label: '05:00 am',
		value: '05:00 am'
	},
	{
		label: '06:00 am',
		value: '06:00 am'
	},
	{
		label: '07:00 am',
		value: '07:00 am'
	},
	{
		label: '08:00 am',
		value: '08:00 am'
	},
	{
		label: '09:00 am',
		value: '09:00 am'
	},
	{
		label: '10:00 am',
		value: '10:00 am'
	},
	{
		label: '11:00 am',
		value: '11:00 am'
	},
	{
		label: '12:00 pm',
		value: '12:00 pm'
	},
	{
		label: '01:00 pm',
		value: '01:00 pm'
	},
	{
		label: '02:00 pm',
		value: '02:00 pm'
	},
	{
		label: '03:00 pm',
		value: '03:00 pm'
	},
	{
		label: '04:00 pm',
		value: '04:00 pm'
	},
	{
		label: '05:00 pm',
		value: '05:00 pm'
	},
	{
		label: '06:00 pm',
		value: '06:00 pm'
	},
	{
		label: '07:00 pm',
		value: '07:00 pm'
	},
	{
		label: '08:00 pm',
		value: '08:00 pm'
	},
	{
		label: '09:00 pm',
		value: '09:00 pm'
	},
	{
		label: '10:00 pm',
		value: '10:00 pm'
	},
	{
		label: '11:00 pm',
		value: '11:00 pm'
	},
]

export const educationOption = [
	{
		value: 'High School',
		label: 'High School'
	},
	{
		value: 'Associate degree',
		label: 'Associate degree'
	},
	{
		value: 'Bachelor\'s degree',
		label: 'Bachelor\'s degree'
	},
	{
		value: 'Master\'s degree',
		label: 'Master\'s degree'
	},
	{
		value: 'Doctorate degree',
		label: 'Doctorate degree'
	},
	{
		value: 'Professional degree',
		label: 'Professional degree'
	},
]

export const timeDurationList = [
	{ value: "15 min", label: "15 min", },
	{ value: "30 min", label: "30 min", },
	{ value: "1 Hr", label: "1 Hr", },
	{ value: "1 Hr 30 min", label: "1 Hr 30 min", },
	{ value: "2 Hrs 30 min", label: "2 Hrs 30 min", },
	{ value: "3 Hrs", label: "3 Hrs", },
	{ value: "3 Hrs 30 min", label: "3 Hrs 30 min", },
	{ value: "4 Hrs", label: "4 Hrs", },
	{ value: "4 Hrs 30 min", label: "4 Hrs 30 min", },
	{ value: "5 Hrs", label: "5 Hrs", },
  ];


  export const AssessmentType=[
	{value:"domain", label: "Domain Assessment"},
	{value:"coding", label: "Coding Assessment"},
  ]
  