import { memo, useEffect, useState } from 'react';
import axios from 'axios';
import Loader from '../Loader';
import { getEnv } from "@urecruits/api";
import PopupGalleryItem from "./PopupGalleryItem";
import { getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const backIc = require('../../image/icon/arrow_back_ic.svg')
const uploadIc = require('../../image/icon/upload-cloud_ic.svg')


const PopupPictureUpload = ({setDisplayGalleryPopup, setRefetch, userData}) => {
  const token: string = localStorage.getItem('token')
  const [loader, setLoader] = useState(false)
  const [images, setImages] = useState([])
  const [step, setStep] = useState(1)

  const [fileTypeError, setFileTypeError] = useState(false)
  const [fileSizeError, setFileSizeError] = useState([])
  const [fileAmount, setFileAmount] = useState(false)
  const {API_RECRUITMENT} = getEnv()

  const formValidate = (data) => {
    const typeValidate = data.filter(item => !(item.type === 'image/jpeg' || item.type === 'image/png'))
    const sizeValidate = data.filter(item => (item.size / 1024 > 5000))
    const amountValidate = data.length > 6

    if (typeValidate.length === 0 && sizeValidate.length === 0 && !amountValidate) {
      return true
    }
  }

  const handleNewFileUpload = async (e) => {
    const data = [...e.target.files]

    data.length > 6 ? setFileAmount(true) : setFileAmount(false)

    //file size error
    setFileSizeError(() => {
      return data.filter(item => {
        return item.size / 1024 > 5000
      }).map(item => item.name)
    })

    //file type error
    data.map(item => {
      item.type === 'image/jpeg' || item.type === 'image/png' ? setFileTypeError(false) : setFileTypeError(true)
    })

    //move to upload step when validate true
    if (formValidate(data)) {
      setImages(e.target.files)
      setStep(2)
    }
  }

  const onSave = async (e, allImages) => {
    e.preventDefault()
    setLoader(true)

    const formData = new FormData()

    //set images to form data
    allImages.map((item, index) => {
      formData.append('upload', allImages[index])
    })

    const {data} = await axios.post(`${API_RECRUITMENT}/api/file/public-multi`, formData, 
      getConfig())

    const result = userData.gallery ? userData.gallery.concat(data): data

    if (data) {
      await axios.patch(`${API_RECRUITMENT}/api/company`, {gallery: result}, 
        getConfig())
        .then(res => {
          if (res.status === 200) {
            setLoader(false)
            setDisplayGalleryPopup(false)
            setRefetch(new Date())
          }
        })
    }
  }

  //trigger to first step when all images deleted
  useEffect(() => {
    if (step === 2) {
      images.length === 0 && setStep(1)
    }
  }, [images])

  return (
    <div className="popup">
      {step == 1 && (
        <div className="popup__step second">
          <div className="popup__step__head">
            <p className="popup__step__headline">
              Upload files
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="popup__step__close"
                 onClick={() => setDisplayGalleryPopup(false)}>
              <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </div>
          <form className="drag">
            <label htmlFor="fileUpload" className="drag__inner">
              <input
                type="file"
                onChange={handleNewFileUpload}
                accept="image/*"
                id="fileUpload"
                className="drag__input"
                multiple
              />
              <p className="drag__headline">
                Drag your files here
              </p>
              <div className="drag__information">
                <p className="drag__information__text">
                  or click
                </p>
                <div className="drag__information__upload">
                  <img src={uploadIc} alt="upload icon" className="drag__information__upload--icon"/>
                  <p className="drag__information__upload--text">
                    Upload
                  </p>
                </div>
              </div>
            </label>
          </form>
          <div className="popup__step__control">
            <div className="popup__step__remove">
              <div className="popup__step__button button--empty" onClick={() => setDisplayGalleryPopup(false)}>
                Cancel
              </div>
            </div>
            <p className="error-message">
              {fileAmount && 'You can upload over 6 file for 1 time.'}{fileSizeError.length > 0 ? `File${fileSizeError.length > 1 ? 's' : ''}: ${fileSizeError.map(item => ' ' + item.slice(0, 15) + (item.length > 15 ? '...' : ''))} must be less than 5 MB.` : ''} {fileTypeError ? ' Incorrect file type.' : ''}
            </p>
          </div>
        </div>
      )}
      {step == 2 && (
        <div className="popup__step">
          <div className="popup__step__head">
            <div className="popup__step__back" onClick={() => setStep(1)}>
              <img src={backIc} alt="back icon" className="popup__step__back--icon"/>
              <p className="popup__step__back--text">Back</p>
            </div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="popup__step__close"
                 onClick={() => setDisplayGalleryPopup(false)}>
              <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </div>
          <div className="popup__step__body">
            {loader && (
              <Loader/>
            )}
            <div className="popup-gallery">
              {
                [...images].map((item, index) => {
                  return <PopupGalleryItem item={item} key={index} images={images} setImages={setImages}/>
                })
              }
            </div>
            <p className="popup__step__info center">
              You can remove or <span onClick={() => setStep(1)}>upload</span> other pictures.
            </p>
          </div>
          <div className="popup__step__control end">
            <button
              className="popup__step__button button--filled"
              type="submit"
              onClick={(e) => onSave(e, [...images])}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(PopupPictureUpload)