import firebase from "firebase/app";
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCixwo1k_ECNLyXNQZ6AZC2R74TOdCZJ40",
  authDomain: "fir-cloud-messaging-9464e.firebaseapp.com",
  projectId: "fir-cloud-messaging-9464e",
  storageBucket: "fir-cloud-messaging-9464e.appspot.com",
  messagingSenderId: "785743649993",
  appId: "1:785743649993:web:216e410e2297132fdf0118"
};

export const app = firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging.isSupported() &&  firebase.messaging();


messaging && messaging.onMessage((payload) => {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,        
  };

  if (!("Notification" in window)) {
      console.log("This browser does not support system notifications.");
  } else if (Notification.permission === "granted") {
      let notification = new Notification(notificationTitle,notificationOptions);
      notification.onclick = (event)=> {
          event.preventDefault();
          window.open(payload.data.click_action , '_blank');
          notification.close();
      }
  }
});