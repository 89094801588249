import IntegrationItem from './IntegrationItem';
import { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import { getConfig, useHasPermission, useSubscription } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
const { API_RECRUITMENT } = getEnv();
//TODO: need to change interface
interface ICategoryItem {
  name: string;
}

interface IIntegrationItem {
  name: string;
  description: string | null;
  integration_category: {
    name: string;
  };
  image: {
    url: string;
  };
}

const packages= ['job','background','drug']
const isMatch = text=>packages.some(keyword => text.toLowerCase().includes(keyword));
const Integrations = () => {
  const { API_RECRUITMENT } = getEnv();

  const [category, setCategory] = useState<Array<ICategoryItem>>([]);
  const [integrations, setIntegrations] = useState<Array<IIntegrationItem>>([]);
  const [connectionIntegrations, setConnectionIntegrations] = useState([]);
  const {checkCurrentPackageType} = useSubscription()
  const {checkUserPermission} = useHasPermission()
  const hasAssessmentPackage = checkCurrentPackageType('assessment')

  useLayoutEffect(() => {
    axios
      .get(`https://cms-dev.urecruits.com/integration-categories`)
      .then((res) => {
        if (res.status == 200) {
          setCategory(res.data);
        }
      });

    axios
      .get(`https://cms-dev.urecruits.com/integrations`)
      .then(async (res) => {
        if (res.status == 200) {
         setIntegrations(res.data);
        }
      });

    axios
      .get(
        `${API_RECRUITMENT}/api/integrations/connection-status?providers=gmail,gcal,outlook,mcal,not-exists-provider`,
        getConfig()
      )
      .then((res) => {
        if (res.status == 200) {
          setConnectionIntegrations(res.data);
        }
      });
  }, []);

  useEffect(() => { 
    if((hasAssessmentPackage || checkUserPermission('candidate'))&& category.length>0){
      setCategory(category.filter(i => !isMatch(i.name)))
    }
  }, [category?.length, hasAssessmentPackage, checkUserPermission('candidate')])

  return (
    <div className="profile__right__inner integrations">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">Integrations</p>
        </div>
      </div>
      <div className="integrations">
        {integrations &&
          connectionIntegrations &&
          category.map((item, index) => {
            return (
              <IntegrationItem
                category={item}
                key={index}
                integrations={integrations}
                connectionIntegrations={connectionIntegrations}
                setIntegrations={setIntegrations}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Integrations;