import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HelloSign from "hellosign-embedded";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { SmallLoaderComponent, useHasPermission } from "../src/ucrecruits-globalstyle";
import { getConfigForAuthorization } from "../utils/getConfigForAuthorization";

const { API_RECRUITMENT, HELLOSIGN_CLIENTID } = getEnv();
const hellosignClient = new HelloSign()

const getEmbeddedSignUrl = async (offerId) => {
  const getSignUrl = async () => {
    const data = await axios.get(`${API_RECRUITMENT}/api/offer/hellosign/sign-url/${offerId}`, getConfigForAuthorization())
    return data.data
  }
  return await getSignUrl()
}

const OfferSignScreen = ({ callbackUrl }: { callbackUrl: string }) => {
  const {checkUserPermission} = useHasPermission()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { offerId } = useParams()
  useEffect(() => {
    checkUserPermission(['candidate','recruiter']) && getEmbeddedSignUrl(offerId).then(data => {
      if (data && data.sign_url) {
        hellosignClient.open(data.sign_url, {
          clientId: HELLOSIGN_CLIENTID,
          skipDomainVerification: true,
        })
        setIsLoading(false)
      }
    }).catch(err => navigate(callbackUrl))
  }, [offerId])

  hellosignClient.on('close', () => {
        navigate(callbackUrl)
  })

  return <div>
    {isLoading && <SmallLoaderComponent/>}
  </div>
}
export default OfferSignScreen