import { memo, useEffect, useRef, useState } from 'react';
import EducationsDetailsTabItem from './EducationsDetailsTabItem';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import patchData from "../../../hook/patchData";
import { getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const editIc = require('../../../image/icon/edit_ic.svg')
const plusIc = require('../../../image/icon/plus_ic.svg')

const EducationsDetailsTab = ({ data, setRefetch, setData }) => {
  const token: string = localStorage.getItem('token')
  const [validateStatus, setValidateStatus] = useState(false)
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [addedMoreClicked, setAddedMoreClicked] = useState(false)
  const addedMoreEducationButtonRef = useRef(null)
  const submitButtonRef = useRef(null)

  //form state
  const [educationsDetails, setEducationsDetails] = useState<any>([])

  useEffect(() => {
    if (data) {
      setEducationsDetails(data.educations)
      setAddedMoreClicked(() => false)
      const isEditMode = data.educations.some(item => item?.isResumeParsed);
      setEditMode(editMode ? editMode : isEditMode)
      setDisplayButton(editMode? editMode : isEditMode)
    }
  }, [data])

  const onFormChange = () => {
    setDisplayButton(true)
  }

  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)
    setEducationsDetails(data.educations)

    const educations = data.educations.filter(item => !item.isResumeParsed);
    setEducationsDetails(educations);
    setData(oldData => {
      return {...oldData,educations}
    })
  }
  const {API_RECRUITMENT}=getEnv()

  function setDataOnSuccess(res,itemId) {
    if (res.status === 200 || res.status === 201) {
      setSuccess(true)
      setData(oldData => {
        let educations = [...oldData?.educations];
        oldData.educations = educations?.map(i => i.id === itemId ? { ...res.data } : i)
        return { ...oldData }
      });
    } else {
      setSuccess(false)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidateStatus(false)

    if (validateStatus) {
      setSuccess(true)

      //Need to added post
      for (const itemMain of educationsDetails) {
        const item = {...itemMain};
        if(!item?.isResumeParsed) {
          await patchData(`${API_RECRUITMENT}/api/candidate/education`, item).then((res) => {
            setDataOnSuccess(res,item.id)
          })
        } else {
          const itemId = item.id
          delete item.id;
          await axios.post(`${API_RECRUITMENT}/api/candidate/education`, item, getConfig()).then((res) => {
            setDataOnSuccess(res,itemId)
          }).catch(err => {
            console.log(err)
          })
        }
      }

      setTimeout(() => {
        setSuccess(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }

  const onAddEducationHandler = async () => {
    if (editMode) {
      // empty object
      const obg = {
        universityName: "",
        courseName: "",
        specification: "",
        coursePeriodStart: null,
        coursePeriodEnd: null,
        gpa: "",
        candidateId: data.id,
        present: false,
      }
      setValidateStatus(false)
      if (validateStatus && !addedMoreClicked  || !educationsDetails?.length) {
        setAddedMoreClicked(() => true)
        for (const item of educationsDetails) {
         await patchData(`${API_RECRUITMENT}/api/candidate/education`, item).then((res) => {
          })
        }
        //Need to added post
        await axios.post(`${API_RECRUITMENT}/api/candidate/education`, obg, getConfig()).then((res) => {
          setRefetch(new Date())
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Educations Details {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        {
          educationsDetails.map((item, index) => {
            return (
              <EducationsDetailsTabItem
                educationsDetails={educationsDetails}
                item={item}
                setEducationsDetails={setEducationsDetails}
                editMode={editMode}
                setData={setData}
                key={item?.id}
                validateStatus={validateStatus}
                setValidateStatus={setValidateStatus}
                displayButton={displayButton}
                addedMoreEducationButtonRef={addedMoreEducationButtonRef}
                submitButtonRef={submitButtonRef}
                index={index}
                setRefetch={setRefetch}
                setDisplayButton={setDisplayButton}
              />
            )
          })
        }
        {
          editMode ?
            <div className="profile__form__group">
              <div className={`profile__form__more`}
                   id="educationsMoreButton"
                   ref={addedMoreEducationButtonRef}
                   onClick={() => onAddEducationHandler()}
              >
                <img src={plusIc} alt="plust icon" className="profile__form__more--icon"/>
                <p className="profile__form__more--text">Add one more education</p>
              </div>
            </div>
            : null
        }
        {
          displayButton && editMode?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
                </button>
                <button className="profile__form__submit button--filled" type="submit" ref={submitButtonRef}>Save
                </button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default memo(EducationsDetailsTab)