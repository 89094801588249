import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";
const fetchData = async (api) => {
    const res = await axios.get(api, getConfig())
    if (res && res.data) {
        return res.data
    }
}
export const postData=async (api,data)=>{
    const res = await axios.post(api,data, getConfig())
    if (res && res.data) {
        return res.data
    }
}
export const deleteData=async (api,data)=>{
    const res = await axios.delete(api, {...getConfig(),data:data})

    if (res && res.data) {
        return res.data
    }
}
export const patchData=async (api,data)=>{
    const res = await axios.patch(api, data,getConfig())
    if (res && res.data) {
        return res.data
    }
}
export default fetchData
