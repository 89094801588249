import glob from "../../image/icon/globe.svg";
import facebook from "../../image/icon/facebook_ic_green.svg";
import twitter from "../../image/icon/twitter_ic_green.svg";
import instagram from "../../image/icon/instagram_ic_green.svg";
import linkedin from "../../image/icon/linkedIn_ic_green.svg";
import JobAction from "./JobAction";
import { memo } from "react";
import { numberWithCommas } from "../../hook/numberWithCommas";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const JobInformation = ({data, applicationForm}) => {

	return (
		<div className="job-information">
			<div className="job-information__inner">
				<div className="job-information__head">
					<p className="job-information__head__headline">
						Key Job Details
					</p>
					{/*//TODO: need to add when matched logic when has been created(candidate/recruiter)*/}
					{/*<Link className="job-information__head__link" to="/">*/}
					{/*  See all matches: <span>324</span>*/}
					{/*</Link>*/}
				</div>
				<ul className="job-information__list">
					<li className="job-information__item">
						<span>Location: </span>{
						data?.locations.map((item: any) => {
							return item.city + ", " + item.state + ". ";
						})
					}
					</li>
					<li className="job-information__item">
						<span>Salary: </span>${numberWithCommas(data?.salaryYearMin)} - ${numberWithCommas(data?.salaryYearMax)} PA
					</li>
					<li className="job-information__item">
						<span>Experience: </span>{data?.experienceMin}-{data?.experienceMax} years
					</li>
					<li className="job-information__item">
						<span>Required Education: </span>{data?.education}
					</li>
					{
						data?.jobType && (
							<li className="job-information__item">
								<span>Job Type: </span>{data?.jobType}
							</li>
						)
					}
					{
						data?.preferableShift && (
							<li className="job-information__item">
								<span>Preferable Shift: </span>{data?.preferableShift}
							</li>
						)
					}
					{
						data?.industryType && (
							<li className="job-information__item">
								<span>Industry Type: </span>{data?.industryType}
							</li>
						)
					}
					{
						data?.jobType && (
							<li className="job-information__item">
								<span>Functional Area: </span>{data?.functionalArea}
							</li>
						)
					}
				</ul>
				<AuthGuard module='candidate'>
				<JobAction
							color="black"
							applicationForm={applicationForm}
						/>
				</AuthGuard>
			</div>
			<div className="job-information__social">
				<div className="job-information__social__left">
					{
						data?.company.website && (
							<div className="job-information__social__website">
								<img src={glob} alt="icon" className="job-information__social__icon small"/>
								<span className="job-information__social__name">company website:</span>
								<a href={`${!data.company.website.includes('http') ? 'https://' : ''}${data.company.website}`} className="job-information__social__link" target="_blank">
									{data.company.website}
								</a>
							</div>
						)
					}
					{
						data?.careerPortal && (
							<div className="job-information__social__website">
								<img src={glob} alt="" className="job-information__social__icon small"/>
								<span className="job-information__social__name">career portal:</span>
								<a href={`${!data.careerPortal.includes('http') ? 'https://' : ''}${data.careerPortal}`} className="job-information__social__link" target="_blank">
									{data?.careerPortal}
								</a>
							</div>
						)
					}
				</div>
				<div className="job-information__social__right">
					{
						data?.facebook?.length > 0 && (
							<a href={data?.facebook} className="job-information__social__item">
								<img src={facebook} alt="facebook" className="job-information__social__icon"/>
							</a>
						)
					}
					{
						data?.twitter?.length > 0 && (
							<a href={data?.twitter} className="job-information__social__item">
								<img src={twitter} alt="twitter" className="job-information__social__icon"/>
							</a>
						)
					}
					{
						data?.instagram?.length > 0 && (
							<a href={data?.instagram} className="job-information__social__item">
								<img src={instagram} alt="instagram" className="job-information__social__icon"/>
							</a>
						)
					}
					{
						data?.linkedin?.length > 0 && (
							<a href={data?.linkedin} className="job-information__social__item">
								<img src={linkedin} alt="linkedin" className="job-information__social__icon"/>
							</a>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default memo(JobInformation);