import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import AsyncSelect from "react-select/async";
import TextEditor from "./TextEditor";
import { getEnv } from "@urecruits/api";
import { components } from "react-select";
import axios from "axios";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import HelloSign from "hellosign-embedded";
import useSnackbar from "../../hook/useSnackbar";
import { getConfig, useSubscription, WarningBoxComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const cross = require("../../image/icon/cross.svg");

const { API_RECRUITMENT, HELLOSIGN_CLIENTID } = getEnv();

const client = new HelloSign();
const AsideModal = ({ setIsCreateTemplate ,overLimit=false}) => {
  const {subscriptionData} = useSubscription()
  const { ErrorElement, APIExecutor } = useSnackbar();

  const OfferSchema = z.object({
    templateName: z.string().trim().min(1, { message: "Required field" }),
    templateDescription: z
      .string()
      .trim()
      .min(1, { message: "Required field" }),
    templateBody: z.string().trim().min(1, { message: "Required field" }),
    signers: z.any().array().nonempty(),
  });

  type OfferSchemaType = z.infer<typeof OfferSchema>;
  const [signerTemp, setSignerTemp] = useState();
  const [isOpenEmbedded, setIsOpenEmbedded] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
    ...form
  } = useForm<OfferSchemaType>({
    resolver: zodResolver(OfferSchema.required()),
  });

  const handleClose = () => {
    setIsCreateTemplate({ visible: false, overLimit: false });
  };

  const onSubmit = async (data) => {
    setIsOpenEmbedded(true);
    const templateInfo: any = await APIExecutor(() => setCreateTemplate(data));

    let clientId;
    // clientId = await getClientId();

    if (!clientId) {
      clientId = HELLOSIGN_CLIENTID;
    }

    client.open(templateInfo.editUrl, {
      clientId,
      skipDomainVerification: true,
    });

    client.once("createTemplate", async (data) => {
      const reqData = { templateId: data.templateId };
      await updateTemplateHandler(templateInfo.id, reqData);
      await axios.post(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: "offer", type: "increase" }, getConfig())

      setIsCreateTemplate({ visible: false, overLimit: false });
      setIsOpenEmbedded(false);
    });

    client.once("cancel", async () => {
      const resData = await APIExecutor(() =>
        axios
          .delete(
            `${API_RECRUITMENT}/api/offer/hellosign/template/${templateInfo.id}`,
            getConfig()
          )
          .then(({ data }) => data)
      );
      setIsOpenEmbedded(false);
    });
  };

  return (
    <>
      <ErrorElement />
      <div className="template-modal">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="template-modal__container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="template-modal__head__wrapper">
              <div className="template-modal__head">
                <h1 className="template-modal__head__title">
                  Create New Template
                </h1>
                <button
                  className="template-modal__head__cross-icon"
                  onClick={handleClose}
                >
                  <img src={cross} alt="cross-icon" />
                </button>
              </div>
            </div>

            <div className="template-modal__container__wrapper">
              <div className="template-modal__item">
                <p className="template-modal__item__label">
                  Template Name<span> *</span>
                </p>
                <input
                  type="text"
                  className="template-modal__item__input"
                  name="templateName"
                  id="templateName"
                  placeholder="Enter template name"
                  autoComplete="off"
                  {...register("templateName", { required: true })}
                />
                {errors.templateName && (
                  <p className="template-modal__item__error-message">
                    {errors.templateName.message}
                  </p>
                )}
              </div>

              <div className="template-modal__item">
                <p className="template-modal__item__label">
                  Template Description<span> *</span>
                </p>
                <input
                  type="text"
                  className="template-modal__item__input"
                  name="templateDescription"
                  id="templateDescription"
                  placeholder="Enter template description"
                  autoComplete="off"
                  {...register("templateDescription", { required: true })}
                />
                {errors.templateDescription && (
                  <p className="template-modal__item__error-message">
                    {errors.templateDescription.message}
                  </p>
                )}
              </div>

              <div className="template-modal__item">
                <p className="template-modal__item__label">
                  Template Body<span> *</span>
                </p>
                <TextEditor
                  value={getValues("templateBody")}
                  setValue={(val) => setValue("templateBody", val)}
                  validateFlag={false}
                  setValidate={null}
                  placeholder="Write about company"
                />
                {errors.templateBody && (
                  <p className="template-modal__item__error-message">
                    {errors.templateBody.message}
                  </p>
                )}
              </div>

              <div className="template-modal__item">
                <p className="template-modal__item__label">
                  Signer<span> *</span>
                </p>
                <AsyncSelect
                  cacheOptions
                  loadOptions={() => signerCallbackFunc()}
                  isMulti
                  defaultOptions
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(currentValue: any) => {
                    setSignerTemp(currentValue);
                    setValue("signers", currentValue);
                  }}
                  value={signerTemp || getValues("signers")}
                  placeholder={"Select signers"}
                  components={{
                    Option,
                  }}
                  styles={selectCustomStyle}
                  id="openings"
                  instanceId="openings"
                />
                {errors.signers && (
                  <p className="template-modal__item__error-message">
                    {errors.signers.message}
                  </p>
                )}
              </div>
            </div>
            {subscriptionData.status !== 'trialing' && overLimit && <WarningBoxComponent message="You’ve reached your template creation limit. Further templates will be charged accordingly." />}
            <div className="template-modal__footer">
              <button
                className={`template-modal__footer__cancel ${isOpenEmbedded ? "disabled" : ""
                  }`}
                onClick={handleClose}
                disabled={isOpenEmbedded}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="template-modal__footer__save"
                disabled={isOpenEmbedded}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AsideModal;

const signerCallbackFunc = async () => {
  const req = await axios(
    `${API_RECRUITMENT}/api/company/get-lead-users`,
    getConfig()
  ).then(({ data }) => data);

  return req.map((item) => {
    return {
      value: `${item.firstname} ${item.lastname}`,
      label: `${item.firstname} ${item.lastname}`,
      id: item.id,
      emailAddress: item.email,
    };
  });
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="select-component">
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={props.value}>
          <span />
        </label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  );
};

const setCreateTemplate = async (templateData) => {
  templateData.templateBody =
    '<div style="margin:30px">' + (templateData.templateBody ?? "") + "</div>";

  const resData = await axios
    .post(
      `${API_RECRUITMENT}/api/offer/hellosign/template`,
      templateData,
      getConfig()
    )
    .then(
      ({
        data: {
          template: { template },
          data: { id },
        },
      }) => ({ id, editUrl: template.edit_url })
    );

  return resData;
};

const updateTemplateHandler = async (templateId, data) => {
  const response = await axios.patch(
    `${API_RECRUITMENT}/api/offer/hellosign/template/${templateId}`,
    data,
    getConfig()
  );
};

const getClientId = async () => {
  const {
    data: { clientId },
  } = await axios(`${API_RECRUITMENT}/api/hellosign-integration`, getConfig());

  return clientId;
};
