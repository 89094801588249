
export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }
  return `${uri + separator + key}=${value}`;
};

const buildSearchProductUrl = (requestConfig, url) => {
  Object.keys(requestConfig).forEach((key) => {
    if (typeof requestConfig[key] === "string" && requestConfig[key] !== "") {
      url = updateQueryStringParameter(
        url,
        key,
        encodeURIComponent(requestConfig[key])
      );
    } else if (
      typeof requestConfig[key] === "number" ||
      (typeof requestConfig[key] === "object" && requestConfig[key] !== null && requestConfig[key].length > 0)
    ) {
      url = updateQueryStringParameter(
        url,
        key,
        encodeURIComponent(JSON.stringify(requestConfig[key]))
      );
    } else if (
      typeof requestConfig[key] === "boolean" &&
      requestConfig[key] !== false
    ) {
      url = updateQueryStringParameter(
        url,
        key,
        encodeURIComponent(requestConfig[key])
      );
    }
  });
  return url;
};

export const requestSearchUrl = (url, requestConfig) => {
  const requestUrl = buildSearchProductUrl(requestConfig, url);

  return requestUrl;
};

export const uuidv4 = () => {
  const crypto = window.crypto;
  // @ts-ignore
  // eslint-disable-next-line
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const updateAssessmentTasks = (
  items: any,
  itemId: any,
  objId: any,
  newObjProps: any
) => {
  return items.map((item: any) => {
    if (item[objId] === itemId) {
      return { ...item, ...newObjProps };
    }
    return item;
  });
};

export const validateFormField = (value, validState, event) => {
  return (
    (value === "" || event) &&
    (value === null ||
      value === "<p></p>\n" ||
      value === "Untitled question" ||
      ((value === "" || event) && validState))
  );
};
