import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useHasPermission } from '../components/AuthProvider';
import NoActiveSubscriptionPage from '../components/FallbackPages/NoActiveSubscriptionPage';
import SmallLoader from '../components/Table/SmallLoader';
import { getConfigForAuthorization } from '../utils/getConfigForAuthorization';
interface SubscriptionContextType {
    subscriptionData: any;
    isLoadingSubscription: boolean;
    packageType: string | null;
    checkCompanySubscription: () => boolean;
    checkCurrentPackageType: (pacType: string | string[]) => boolean;
    hasCompanySubscriptionElement: (element: any) => JSX.Element;
    setIsLoadingSubscription: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const useSubscription = () => {
    const context = React.useContext(SubscriptionContext);
    if (!context) {
        throw new Error('useSubscription must be used within a SubscriptionProvider');
    }
    return context;
};

interface SubscriptionProviderProps {
    children: ReactNode;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
    let retry = 0;

    const [isLoadingSubscription, setIsLoadingSubscription] = useState<boolean>(false);
    const [subscriptionData, setSubscriptionData] = useState<any>(null);
    const [packageType, setPackageType] = useState<string | null>(null);
    const { checkUserPermission, companyId } = useHasPermission()
	const isCompanyDomain = ()=> localStorage.getItem('isCompanyDomain');


    useEffect(() => {
        setIsLoadingSubscription(true);
        const getData = async () => {
            const token = localStorage.getItem('token')

            try {
                const res = token && await axios(`https://recruitment-micro.urecruits.com/api/subscription/get-company-main-subscription`,getConfigForAuthorization());
                // const res = await axios(`http://localhost:5478/api/subscription/get-company-main-subscription`, config);
                if (res?.data && Object.keys(res?.data).length > 0) {
                    setSubscriptionData(res.data);
                    setPackageType(res.data.packageType ?? null);
                    setIsLoadingSubscription(false);
                }
            } catch (err) {
                if(err.response.status === 401 && retry < 3){
                    retry++;
                    await delay(1000);
                    await getData();
                }else{
                    setIsLoadingSubscription(false);
                }
            }
        };
        !subscriptionData && !!companyId && isCompanyDomain() !=='false' && getData();
    }, [companyId]);

    const checkCompanySubscription = () => {
        return !isLoadingSubscription && subscriptionData && subscriptionData.subId !== "noSubscription";
    };

    const checkCurrentPackageType = (pacType: string | string[]) => {
        if (!isLoadingSubscription && packageType && pacType) {
            if (typeof pacType === 'string' && packageType === pacType) {
                return true;
            }
            if (Array.isArray(pacType) && pacType.includes(packageType)) {
                return true;
            }
        }
        return false;
    };

    const hasCompanySubscriptionElement = (element: any) => {
        if (checkCompanySubscription()) {
            return element;
        }
        if (isLoadingSubscription || localStorage.getItem('firstLogin') == 'true'||localStorage.getItem('isCompanyDomain')=='false') {
            return <SmallLoader />;
        }
        return <NoActiveSubscriptionPage />;
    };

    return (
        <SubscriptionContext.Provider
            value={{
                subscriptionData,
                isLoadingSubscription,
                packageType,
                checkCompanySubscription,
                checkCurrentPackageType,
                hasCompanySubscriptionElement,
                setIsLoadingSubscription,
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
};
