import { useEffect, useCallback, useState } from "react";
import { TableCardViewComp, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import {
  InterviewsJobsTableHeader,
  RoundStatus,
  InterviewsFilter,
} from "../enums";
import { Link } from "react-router-dom";
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../store";
import {
  setCurrentPage,
  setLimit,
  setLocationFilter,
  setSearchValue,
  setSortByFilter,
  setSortTypeFilter,
  setTotalCount,
  defaultFilters,
  setOrderColumns,
  setInterviewType,
} from "../store/reducers/interviewsJobsReducer";
import { JobsFilters, InterviewTypeButton } from "../components/Interviews";
import {
  ifAllInterviewsCompleted,
  ifAnyInterviewInProgress,
  ifAllInterviewsPending,
  ifAnyInterviewRejected,
} from "../utils";

const { API_RECRUITMENT } = getEnv();

const getTable = (store) => store.interviews_jobs;
const getTableFilters = (store) => store.interviews_jobs.filters;
const getTableFilterData = (store) => store.interviews_jobs.filterInfo;

export const InterviewsJobs = () => {
  const table = useTypedSelector(getTable);
  const selectedInterviewType = table.filters.interviewType;
  const [filterString, setFilterString] = useState<string>("");
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  useEffect(() => {
    const filters = table.filters;
    if (isFilterSubmit && filters) {
      setFilterString(
        ` ${
          filters.locations
            ? filters.locations
                .map((x: any) => `&locations=${x.value}`)
                .join("")
            : ""
        }`
      );
    }
  }, [isFilterSubmit]);

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValue(value));
    store.dispatch(setCurrentPage(1));
  }, []);

  const setCurrentPageCallback = useCallback((value) => {
    store.dispatch(setCurrentPage(value));
  }, []);

  const FilterItems = (state) => {
    return <JobsFilters activeTab={state} />;
  };

  const createAPIString = () => {
    const filters = table.filters;
    const pagination = table.pagination;
    const interviewType = filters.interviewType === InterviewsFilter.ALL_INTERVIEW ? '' : filters.interviewType
    return `${API_RECRUITMENT}/api/interviews/jobs?limit=${
      pagination.limit
    }&offset=${
      (pagination.currentPage - 1) * pagination.limit
    } ${filterString}${
      filters.searchValue ? "&search=" + filters.searchValue : ""
    }${filters.sortBy ? "&sortBy=" + filters.sortBy : ""}${
      filters.sortType ? "&sortType=" + filters.sortType : ""
      }${interviewType ? "&roundName=" + interviewType :""}`;
  };

  const resetFilters = (pagination) => {
    store.dispatch(setSortByFilter(defaultFilters.sortBy));
    store.dispatch(setSortTypeFilter(defaultFilters.sortType));
    store.dispatch(setSearchValue(defaultFilters.searchValue));
    store.dispatch(setLocationFilter(defaultFilters.locations));
    setIsFilterSubmit(true);
  };
  const columnCallbacks = {
    jobTitle: (cellValue, rowData) => {
      const jobTitle = cellValue || "-";
      const jobLocation = rowData.locations
        .map((location) => `${location?.city ?? ""},${location?.state ?? ""}`)
        .join(" | ");
      return (
        <Link
          to={`/recruitment/interviews/candidates/${rowData.id}`}
          state={{ jobTitle, jobLocation }}
          className="interviews__jobs__link"
        >
          {jobTitle}
        </Link>
      );
    },
    jobLocation: (cellValue, rowData) => {
      return (
        <p className="interviews__jobs__location">
          {cellValue
            .map(
              (location) => `${location?.city ?? ""},${location?.state ?? ""}`
            )
            .join(" | ")}
        </p>
      );
    },
    openings: (cellValue, rowData) => {
      return <p className="interviews__jobs__count">{cellValue || "-"}</p>;
    },
    pendingCount: (cellValue, rowData) => {
      const count = getCount(
        rowData?.rounds,
        RoundStatus.PENDING,
        selectedInterviewType
      );
      return <p className="interviews__jobs__count">{count}</p>;
    },
    inProgressCount: (cellValue, rowData) => {
      const count = getCount(
        rowData?.rounds,
        RoundStatus.IN_PROGRESS,
        selectedInterviewType
      );
      return <p className="interviews__jobs__count">{count}</p>;
    },
    completed: (cellValue, rowData) => {
      const count = getCount(
        rowData?.rounds,
        RoundStatus.COMPLETED,
        selectedInterviewType
      );
      return <p className="interviews__jobs__count">{count}</p>;
    },
    rejected: (cellValue, rowData) => {
      const count = getCount(
        rowData?.rounds,
        RoundStatus.REJECTED,
        selectedInterviewType
      );
      return <p className="interviews__jobs__count">{count}</p>;
    },
    workflowAssigned: (cellValue, rowData) => {
      const workflowTitle = cellValue?.title;
      return (
        <p
          className={`${
            workflowTitle
              ? "table__status-block table__status-block--yellow"
              : ""
          }`}
        >
          {workflowTitle || "-"}
        </p>
      );
    },
  };

  const columns: Array<IColumns> = [
    {
      headerName: InterviewsJobsTableHeader.JOB_TITLE,
      field: "title",
      pinnable: true,
      renderCell: columnCallbacks.jobTitle,
      mobileRenderCell: columnCallbacks.jobTitle,
      visibility: true,
      isMobileTitle: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.JOB_LOCATION,
      field: "locations",
      renderCell: columnCallbacks.jobLocation,
      mobileRenderCell: columnCallbacks.jobLocation,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.OPENINGS,
      field: "numberOpenings",
      renderCell: columnCallbacks.openings,
      mobileRenderCell: columnCallbacks.openings,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.PENDING_TO_SCHEDULE,
      field: "pending to schedule",
      renderCell: columnCallbacks.pendingCount,
      mobileRenderCell: columnCallbacks.pendingCount,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.IN_PROGRESS,
      field: "inProgress",
      renderCell: columnCallbacks.inProgressCount,
      mobileRenderCell: columnCallbacks.inProgressCount,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.COMPLETED,
      field: "completed",
      renderCell: columnCallbacks.completed,
      mobileRenderCell: columnCallbacks.completed,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.REJECTED,
      field: "rejected",
      renderCell: columnCallbacks.rejected,
      mobileRenderCell: columnCallbacks.rejected,
      visibility: true,
      className: "",
    },
    {
      headerName: InterviewsJobsTableHeader.WORKFLOW_ASSIGNED,
      field: "workflow",
      renderCell: columnCallbacks.workflowAssigned,
      mobileRenderCell: columnCallbacks.workflowAssigned,
      visibility: true,
      className: "",
    },
  ];

  const setInterviewTypeCallback = (type: string) => {
    store.dispatch(setInterviewType(type));
  };

  return (
    <><AuthGuard module='job-post' permission='view'>
      <div className="table-screen-top">
        <div className="table-screen-top__wrapper">
          <h2>Interviews</h2>
          <div className="table-screen-top__buttons-group">
            <InterviewTypeButton
              interviewType={InterviewsFilter.ALL_INTERVIEW}
              clickListner={setInterviewTypeCallback}
            />
            <InterviewTypeButton
              interviewType={InterviewsFilter.HR_AUDIO_VIDEO_INTERVIEW}
              clickListner={setInterviewTypeCallback}
            />
            <InterviewTypeButton
              interviewType={
                InterviewsFilter.TECHNICAL_VIDEO_AUDIO_INTERVIEW
              }
              clickListner={setInterviewTypeCallback}
            />
            <InterviewTypeButton
              interviewType={InterviewsFilter.SENIOR_HR_AUDIO_VIDEO_INTERVIEW}
              clickListner={setInterviewTypeCallback}
            />
          </div>
        </div>
      </div>

      <TableCardViewComp
        tableViewType="table"
        columns={columns}
        rowsData={{api:createAPIString()}}
        searchField={{
          searchValue: table.filters.searchValue,
          setSearchValue: setSearchCallback,
          placeholder: "Enter job title",
        }}
        pagination={{
          limit: table.pagination.limit,
          currentPage: table.pagination.currentPage,
          totalCount: table.pagination.totalCount,
          setTotalCount: (count) => {
            store.dispatch(setTotalCount(count));
          },
          setCurrentPage: setCurrentPageCallback,
          setLimit: (value) => {
            store.dispatch(setLimit(value));
          },
          filters: table.filters,
        }}
        emptyTable={{
          title: "Table is empty",
          desc: "There are no jobs yet",
        }}
        rearrangeColumns={{ setColumnOrder: setOrderColumns }}
        storeName="interviews_jobs"
        store={store}
        useTypedSelector={useTypedSelector}
        filters={{
          filterData: useTypedSelector(getTableFilterData),
          filter: useTypedSelector(getTableFilters),
          resetFilters,
          filterItems: FilterItems,
          isFilterSubmit,
          setIsFilterSubmit: (value) => setIsFilterSubmit(value),
          isEmptyFilter,
          setIsEmptyFilter: (value) => setIsEmptyFilter(value),
        }}
      />
      </AuthGuard>
    </>
  );
};

const getCount = (jobRounds, stasticsType, interviewType) => {
  let count = 0;
  jobRounds?.forEach((userRound) => {
    const workflowRounds = userRound?.rounds;

    if (Array.isArray(workflowRounds)) {
      if (interviewType === InterviewsFilter.ALL_INTERVIEW) {
        if (
          (stasticsType === RoundStatus.PENDING &&
            ifAllInterviewsPending(workflowRounds)) ||
          (stasticsType === RoundStatus.IN_PROGRESS &&
            ifAnyInterviewInProgress(workflowRounds)) ||
          (stasticsType === RoundStatus.COMPLETED &&
            ifAllInterviewsCompleted(workflowRounds)) ||
          (stasticsType === RoundStatus.REJECTED &&
            ifAnyInterviewRejected(workflowRounds))
        )
          count++;
        else return 0;
      } else {
        workflowRounds?.forEach((workflowRound) => {
          if (
            workflowRound?.title === interviewType &&
            workflowRound?.status === stasticsType
          )
            count++;
        });
      }
    }
  });

  return count;
};
