import HotJobs from "../components/AboutCompany/HotJobs";
import mailIc from "../image/icon/mail_green_ic.svg";
import phoneIc from "../image/icon/phone_green_ic.svg";
import { useLayoutEffect, useState } from "react";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "photoswipe/dist/photoswipe.css";
import { Gallery as PhotoswipeGallery, Item } from "react-photoswipe-gallery";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv();

const getImageProperty = (src) => {
	const img = new Image();
	img.src = src;

	return {
		width: img.width,
		height: img.height,
	};
};

export interface ICompany {
	about: string,
	addresses: Array<{
		location: string,
		country: {} | string,
	}>,
	gallery: Array<{
		location: string
	}> | Array<null>
	avatar: string,
	email: string,
	name: string,
	phone: string,
	jobs: Array<IJob>
	tenantId: string,
	website?:string|null,
}

export interface IJob {
	experienceMax: number,
	experienceMin: number,
	id: number,
	locations: Array<{
		city: string,
		state: string,
		id: number
	}>,
	salaryMonthMax: number,
	salaryMonthMin: number,
	salaryYearMax: number,
	salaryYearMin: number,
	skills: Array<string>
	title: string
}

const AboutCompany = () => {
	const params = useParams();
	const [company, setCompany] = useState<ICompany>();
	const [showMoreGallery, setShowMoreGallery] = useState(true);
	const navigate= useNavigate()
	useLayoutEffect(() => {
		axios.get(`${API_RECRUITMENT}/api/company/public/${params.tenantId}`, getConfig())
			.then(res => setCompany(res.data));
	}, []);

	return (
			<div className="about-company__body">
				<div className="about-company__left">
					{
						company?.gallery && (
							<div className="about-company__item">
								<p className="about-company__item__name">Gallery <span>({company?.gallery.length})</span></p>
								<div className="gallery">
									<PhotoswipeGallery>
										<div className="gallery__list">
											{
												company?.gallery?.map((item, index) => {
													if (showMoreGallery) {
														if (index <= 3) {
															return (
																<Item
																	original={item.location}
																	thumbnail={item.location}
																	width={getImageProperty(item.location).width}
																	height={getImageProperty(item.location).height}
																	key={index}
																>
																	{({ref, open}: { ref: any, open: any }) => (
																		<img ref={ref} className="gallery__list__item" alt={item.location} onClick={open} src={item.location}/>
																	)}
																</Item>
															);
														}
													} else {
														return (
															<Item
																original={item.location}
																thumbnail={item.location}
																width={getImageProperty(item.location).width}
																height={getImageProperty(item.location).height}
																key={index}
															>
																{({ref, open}: { ref: any, open: any }) => (
																	<img ref={ref} className="gallery__list__item" alt={item.location} onClick={open} src={item.location}/>
																)}
															</Item>
														);
													}
												})
											}
											{
												company?.gallery.length > 4 && (
													<>
														{
															showMoreGallery && (
																<div
																	className="gallery__list__item empty"
																	onClick={() => setShowMoreGallery((prev) => !prev)}
																>
																	<p className="gallery__list__text">
																		See all photos
																	</p>
																</div>
															)
														}
													</>
												)
											}
										</div>
									</PhotoswipeGallery>
								</div>
							</div>
						)
					}
					{
						company?.about && (
							<div className="about-company__item">
								<p className="about-company__item__name">About Company</p>
								<div className="about-company__item__info" dangerouslySetInnerHTML={{__html: `${company?.about}`}}>
								</div>
							</div>
						)
					}
					<div className="about-company__item">
						<p className="about-company__item__name">Contact</p>
						<ul>
							<li className="about-company__contacts">
								<li className="about-company__contacts__item">
									<a href={`tel:${company?.phone}`} target="_blank" className="about-company__contacts__link">
										<img src={phoneIc} alt="phone icon"/>
										{company?.phone}
									</a>
								</li>
								{
									company?.email && (
										<li className="about-company__contacts__item">
											<button className="about-company__contacts__link" onClick={()=>navigate('/recruitment/mail-box?email='+encodeURIComponent(company.email))}>
												<img src={mailIc} alt="email icon"/>
												{company?.email}
											</button>
										</li>
									)
								}
							</li>
						</ul>
					</div>
				</div>
				{
					!!company?.jobs.length && (
						<div className="about-company__right">
							<HotJobs jobs={company?.jobs} companyLogo={company?.avatar}/>
						</div>
					)
				}
			</div>

	);
};

export default AboutCompany;