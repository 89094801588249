const cancel = require("./../images/icon/cancel.svg");
const cross = require("./../images/icon/cross.svg");

function Dialog({ message, onDelete,onCancel, nameProduct }) {
  return (
    <div className="coding-dialog">
      <div
        className="coding-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="coding-dialog__head">
          <h2 className="coding-dialog__head__title">
            Delete Coding Assessment
          </h2>
          <button
            className="coding-dialog__head__cross-ico"
            onClick={onCancel}
          >
            <img src={cross} alt="cross-icon" />
          </button>
        </div>
        <h1 className="coding-dialog__name">{nameProduct}</h1>
        <img
          className="coding-dialog__center-img"
          src={cancel}
          alt="cancel-icon"
        />
        <h3 className="coding-dialog__message">{message}</h3>
        <div className="coding-dialog__footer">
          <div
            className="coding-dialog__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="coding-dialog__footer__container__cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="coding-dialog__footer__container__delete"
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dialog;
