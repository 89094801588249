import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";

const fetchData = async (api) => {
  try {
    const res = await axios.get(api, getConfig());
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};

export const postData = async (api, body) => {
  try {
    const res = await axios.post(api, body, getConfig());
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};
export const deleteData = async (api, data) => {
  const res = await axios.delete(api, { ...getConfig(), data: data });

  if (res && res.data) {
    return res.data;
  }
};
export const patchData = async (api, data) => {
  const res = await axios.patch(api, data, getConfig());
  if (res && res.data) {
    return res.data;
  }
};
export default fetchData;
