import axios from 'axios';
import { getEnv } from "@urecruits/api";
import { OfferKeys, OfferLetterStatus } from "../../enums/usersOffers/UsersOffersEnums";
import { setUsersOffersHandler } from "../../screen/UsersOffersScreen";
import { useTypedSelector } from "../../store";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();
const getTableOffersUsers = (state) => state.offers_users;
export const OfferRejected = ({setModal,offerId})=>{
    const table = useTypedSelector(getTableOffersUsers);
    const handleRejectOffer = ()=>{
        const updateLetterStatus = async () =>{
            await axios.patch(`${API_RECRUITMENT}/api/offer/${offerId}`, 
            {[OfferKeys.LETTERSTATUS]:OfferLetterStatus.REJECTED},
            getConfig());
        }
        updateLetterStatus()
        .then(()=>{
            setUsersOffersHandler(
                table.pagination.currentPage,
                table.pagination.limit,
                table.filters
              );
        })
        .finally(()=>{
            setModal(false);
        });   
    }
    return(
        <>
        <div className="modal__body__offer-rejected">
           <p className="modal__body__text">Please write some reasons to the executor about rejecting the offer</p>
           <textarea className="popup__body__area"></textarea>
        </div>
        
        <div className="modal__actions">
           <button className="modal__actions__cancel__outlined" onClick={()=>{setModal(false)}}>Cancel</button>
           <button className="modal__actions__send" onClick={handleRejectOffer}>Reject</button> 
        </div>
        </>
    );
}