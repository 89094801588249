import { memo, useEffect, useRef, useState } from 'react';
import SkillsDetailsTabItem from './SkillsDetailsTabItem';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import patchData from "../../../hook/patchData";
import { getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const editIc = require('../../../image/icon/edit_ic.svg')
const plusIc = require('../../../image/icon/plus_ic.svg')

const earsOfExperienceOption = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '10-15', label: '10-15' },
  { value: '15-20', label: '15-20' },
  { value: '20-25', label: '20-25' },
  { value: '25-30', label: '25-30' },
  { value: '30-35', label: '30-35' },
  { value: '35-40', label: '35-40' },
  { value: '40-45', label: '40-45' },
  { value: '45-50', label: '45-50' },
]

const SkillsDetailsTab = ({ data, setRefetch,resumeParsingData,setData }) => {
  const token: string = localStorage.getItem('token')
  const [validateStatus, setValidateStatus] = useState(false)
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [addedMoreClicked, setAddedMoreClicked] = useState(false)
  const addedMoreButtonRef = useRef(null)
  const submitButtonRef = useRef(null)

  //form state
  const [dataDetails, setDataDetails] = useState([])

  useEffect(() => {
    if (data) {
      setDataDetails(data.skills)
      setAddedMoreClicked(() => false)
      const isEditMode = data.skills.some(item => item?.isResumeParsed);
      setEditMode(editMode ? editMode : isEditMode)
      setDisplayButton(editMode? editMode : isEditMode)
    }
  }, [data])

  const onFormChange = () => {
    setDisplayButton(true)
  }
  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)
    const skills = data.skills.filter(item => !item.isResumeParsed);
    setDataDetails(skills);
    setData(oldData => {
      return {...oldData,skills}
    })
  }
  const {API_RECRUITMENT}=getEnv()

  function setDataOnSuccess(res,itemId) {
    if (res.status === 200 || res.status === 201) {
      setSuccess(true)
      setData(oldData => {
        let skills = [...oldData?.skills];
        oldData.skills = skills?.map(i => i.id === itemId ? { ...res.data } : i)
        return { ...oldData }
      });
    } else {
      setSuccess(false)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidateStatus(false)

    if (validateStatus) {
      setSuccess(true)

      //Need to added post
      for (const item of dataDetails) {
        if(!item?.isResumeParsed) {
          await patchData(`${API_RECRUITMENT}/api/candidate/skill`, item).then((res) => {
            setDataOnSuccess(res,item.id)
          })
        } else {
          await axios.post(`${API_RECRUITMENT}/api/candidate/skill`, { name: item.name, years: item.years }, getConfig()).then((res) => {
            setDataOnSuccess(res,item.id)
          }).catch(err => {
            console.log(err)
          })
        }
      }

      setTimeout(() => {
        setSuccess(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }
  const onAddMoreItemHandler = async () => {
    if (editMode) {
      // empty object
      const obg = {
        name: '',
        years: '',
        candidateId: data.id,
      }

      setValidateStatus(false)

      if (validateStatus && !addedMoreClicked || !dataDetails?.length) {
        setAddedMoreClicked(() => true)
        for (const item of dataDetails) {
          await patchData(`${API_RECRUITMENT}/api/candidate/skill`, item).then((res) => {})
        }
        await axios.post(`${API_RECRUITMENT}/api/candidate/skill`, obg, getConfig()).then((res) => {
          //res.status === 201 ? setDataDetails([...dataDetails, res.data]) : ''
          setRefetch(new Date())
        }).catch(err => {
          console.log(err)
        })

      }
    }
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head with-description">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Skills {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
        <p className="profile__head__description">
          Here you can specify the programs and soft / hard skills that you own
        </p>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        {
          dataDetails.map((item, index) => {
            return (
              <SkillsDetailsTabItem
                key={item.id}
                dataDetails={dataDetails}
                currentItem={item}
                setDataDetails={setDataDetails}
                editMode={editMode}
                setData={setData}
                validateStatus={validateStatus}
                setValidateStatus={setValidateStatus}
                displayButton={displayButton}
                addedMoreButtonRef={addedMoreButtonRef}
                submitButtonRef={submitButtonRef}
                earsOfExperienceOption={earsOfExperienceOption}
                index={index}
                setRefetch={setRefetch}
                setDisplayButton={setDisplayButton}
              />
            )
          })
        }
        {
          editMode ?
            <div className="profile__form__group ">
              <div
                className={`profile__form__more`}
                ref={addedMoreButtonRef}
                onClick={() => onAddMoreItemHandler()}
              >
                <img src={plusIc} alt="plust icon" className="profile__form__more--icon"/>
                <p className="profile__form__more--text">Add one more skill</p>
              </div>
            </div>
            : null
        }
        {
          displayButton && editMode ?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel</button>
                <button className="profile__form__submit button--filled" ref={submitButtonRef}>Save</button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default memo(SkillsDetailsTab)