import { memo, useEffect, useRef } from "react";
import RolesTableHead from "./RolesTableHead";
import RolesTableRow from "./RolesTableRow";

const RolesTable = ({
  allUsersPermissionData,
  setActiveRole,
  activeRole,
  setActiveTab,
  setSortedBy,
  sortedBy,
  setRefetch,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  //Y scroll for table when not have free place
  const wheelHandler = (e: any) => {
    e.preventDefault();
    if (scrollRef.current !== null) {
      scrollRef.current.scrollLeft += e.deltaY;
    }
  };

  //trigger Y scroll function
  useEffect(() => {
    if (scrollRef.current !== null) {
      scrollRef.current.addEventListener("wheel", (e) => {
        wheelHandler(e);
      });
    }

    return () => {
      window.removeEventListener("wheel", wheelHandler);
    };
  }, []);

  return (
    <div className="roles-main">
      <div className="roles-table" ref={scrollRef}>
        <RolesTableHead setSortedBy={setSortedBy} sortedBy={sortedBy} />
        <div className="roles-table__list">
          {allUsersPermissionData?.map((item, index) => {
            return (
              !item.role?.includes("Company Owner") && (
                <RolesTableRow
                  item={item}
                  key={index}
                  setActiveRole={setActiveRole}
                  activeRole={activeRole}
                  setActiveTab={setActiveTab}
                  isDefault={item.isDefault}
                  setRefetch={setRefetch}
                />
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(RolesTable);
