import type {PayloadAction} from '@reduxjs/toolkit'
// eslint-disable-next-line no-duplicate-imports
import {createSlice} from '@reduxjs/toolkit'
import {IJobsTable, IJobsTableItem} from "../../types/redux/jobs";
import {JobTabs} from "../../enums/jobs/jobsEnums";
import {ITabsFilter} from "../../types/global/global";

export const defaultFiltersJobs = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	status: [],
	date: {
		from: null,
		to: null
	}
}
const initialStateMTM: IJobsTable = {
	topButtons: 'all',
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersJobs,
	tableItems: [],
	fixedTab: {
		id: 1,
		displayName: JobTabs.JOB_TITLE,
	},
	deletePopupInfo: {
		open: false,
		name: '',
		id: -1
	},
	changeWorkflow:{
		title:'',
		open:false,
		id:-1,
		jobId:-1
	},
	approvePopup: {
		open: false,
		name: '',
		id: -1
	},
	rejectPopup: {
		open: false,
		name: '',
		id: -1
	},
	workflowList:[],
	tabFilter: [
		{
			id: 2,
			displayName: JobTabs.EMPLOYER,
			active: true,
			dbName: 'employer'
		},
		{
			id: 3,
			displayName: JobTabs.SALARY_RANGE,
			active: true,
			dbName: 'salaryYearMin'
		},
		{
			id: 4,
			displayName: JobTabs.POSTED_ON,
			active: true,
			dbName: 'createdAt'
		},

		{
			id: 6,
			displayName: JobTabs.STATUS,
			active: true,
			dbName: 'status'
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				JobTabs.STATUS,
				JobTabs.DATE,
			],
	}
}

export const jobs = createSlice({
	name: 'jobs',
	initialState: initialStateMTM,
	reducers: {
		setChangeWorkflowListJobs:(state, action: PayloadAction<any>) => {
			state.workflowList = action.payload
		},
		setChangeWorkflowPopupJobs:(state, action: PayloadAction<{ open: boolean, title: string, id: number,jobId:number }>) => {
			state.changeWorkflow = action.payload
		},
		setTopButtonsJobs: (state, action: PayloadAction<string>) => {
			state.topButtons = action.payload
		},
		setDeletePopupJobs: (state, action: PayloadAction<{ open: boolean, name: string, id: number }>) => {
			state.deletePopupInfo = action.payload
		},
		setApprovePopupJobs: (state, action: PayloadAction<{ open: boolean, name: string, id: number }>) => {
			state.approvePopup = action.payload
		},
		setRejectPopupJobs: (state, action: PayloadAction<{ open: boolean, name: string, id: number }>) => {
			state.rejectPopup = action.payload
		},
		setTableItemsJobs: (state, action: PayloadAction<Array<IJobsTableItem>>) => {
			state.tableItems = action.payload
		},
		setDateSelectedJobs: (state, action: PayloadAction<{ from: string | null, to: string | null }>) => {
			state.filters.date = action.payload
		},
		setSearchValueJobs: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderJobs: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setLimitJobs: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1
		},
		setSortByFilterJobs: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeFilterJobs: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setStatusFilterJobs: (state, action: PayloadAction<Array<string>>) => {
			state.filters.status = action.payload
		},
		setCurrentPageJobs: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setTotalCountJobs: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setEmptySearchJobs: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setEmptyTableJobs: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
	},
})


export const {
	setChangeWorkflowListJobs,
	setChangeWorkflowPopupJobs,
	setRejectPopupJobs,
	setApprovePopupJobs,
	setTopButtonsJobs,
	setEmptyTableJobs,
	setDeletePopupJobs,
	setEmptySearchJobs,
	setTotalCountJobs,
	setCurrentPageJobs,
	setStatusFilterJobs,
	setSearchValueJobs,
	setOrderJobs,
	setLimitJobs,
	setSortTypeFilterJobs,
	setSortByFilterJobs,
	setDateSelectedJobs,
	setTableItemsJobs
} = jobs.actions

export default jobs.reducer