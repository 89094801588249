import { useAuth0 } from "@auth0/auth0-react";
import { useLayoutEffect } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";

export default function LoginRedirect() {
	const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
	const isCompanyDomain = localStorage.getItem('isCompanyDomain');
	useLayoutEffect(() => {
		(async () => {

			try {
				if (!isLoading && isAuthenticated && user) {
					const token = await getAccessTokenSilently({
						ignoreCache: true,
						scope: 'openid profile email offline_access'
					});
					const data = jwtDecode<JwtPayload>(token);
					const url = /:\/\/([^\/]+)/.exec(window.location.href)[1];
					const domain = url.split('.')[0];
					if (window.location.hostname != 'localhost') {
						if (domain != data['https://urecruits.com/tenantId']) {
							const newUrl = window.location.href.replace(domain, `${data['https://urecruits.com/tenantId']}`)
							window.location.replace(newUrl);
						}
					}
					if(domain == data['https://urecruits.com/tenantId'] || window.location.hostname == 'localhost'){
						localStorage.setItem('isCompanyDomain', 'true');

					}else{
						localStorage.setItem('isCompanyDomain', 'false');
					}
					localStorage.setItem('token', token);
					localStorage.setItem('auth0Id', user.sub);

					const tokenExpiry = (data.exp * 1000) - Date.now();
					const refreshInterval = tokenExpiry > 0 ? Math.max(tokenExpiry - (1 * 60 * 1000), 0) : 2 * 60 * 1000;

					setInterval(async () => {
						await getAccessTokenSilently({
							ignoreCache: true,
							scope: 'openid profile email offline_access',
						}).then(newAccessToken => {
							// Use the new access token
							localStorage.setItem('token', newAccessToken);
						}).catch(error => {
							loginWithRedirect().then()
						});
					}, refreshInterval);
				} else if (!isLoading && !isAuthenticated) {
					loginWithRedirect().then()
				}
			} catch (e) {
				console.error("Unable to request access token", e);
			}
		})();
	}, [getAccessTokenSilently, isAuthenticated, isLoading, user]);


	return (
		<>
		</>
	)
}