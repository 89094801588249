import { Link } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { selectSmallStyle } from "../../styles/selectSmallStyle";
import { useEffect, useState } from "react";
import plusIcon from '../../image/icon/plus_ic.svg';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import SingleMobileItem from "@ucrecruits/globalstyle/components/Table/MobileTable/SingleMobileItem";
import { DashboardEmptyDataComponent, getConfig, ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import fetchData from "../../hook/http";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_ASSESSMENT, API_RECRUITMENT } = getEnv();


const colNames = ["JobTitle", "Openings", "Workflow"]

const JobsTable = () => {
	const [currentFilter, setCurrentFilter] = useState<any>();
	const [data, setData] = useState([])
	const [options, setOptions] = useState([])
	const [isEmptyData, setIsEmptyData] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const { checkUserPermission } = useHasPermission();


	useEffect(() => {
		const updateScreenSize = () => {
			setScreenWidth(window.innerWidth);
		};
		addEventListener("resize", updateScreenSize);
		return () => {
			removeEventListener("resize", updateScreenSize);
		};
	}, []);

	useEffect(() => {
		async function getJobsData() {
			try {
				const jobs = await fetchData(`${API_RECRUITMENT}/api/company/jobs?limit=20&offset=0&status=publish${currentFilter?.label ? "&search=" + currentFilter.label : ""}`).then(data => data?.rows)
				if (!jobs?.length) {
					setIsEmptyData(true)
				} else {
					setData(jobs)
					await axios.get(`${API_RECRUITMENT}/api/company/jobs?status=publish`, getConfig()).then(data => setOptions(data.data?.rows.map(i => ({ value: i.id, label: i.title })))).catch(err => console.log(err))
				}
				setIsLoading(false)
			} catch (error) {
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}

		checkUserPermission('job-post', 'view') && getJobsData().then()
	}, [currentFilter?.value])

	return (
		<div className="dashboard-table">
			<div className="dashboard-table__header">
				<div className="dashboard-table__header__flex">
					<p className="dashboard-table__header__title">
						Posted Jobs
					</p>
					<AuthGuard module='job-post' permission='view' >
						{!isEmptyData && options.length > 0 &&

							<div className="dashboard-table__select">
								<ReactSelect
									options={[{ value: "", label: "--" }, ...options]}
									value={currentFilter}
									onChange={(option: any) => {

										if (!option?.value) {
											setCurrentFilter({ ...option, label: "" })
										} else {
											setCurrentFilter(option);
										}
									}}
									hideSelectedOptions={false}
									styles={selectSmallStyle}
									id="filterSelect"
									instanceId="filterSelect"
								/>
							</div>
						}
					</AuthGuard>
				</div>

				<div className="dashboard-list__links">
					{!isEmptyData && (
						<AuthGuard module='job-post' permission='view'>
							<Link to={"/recruitment/jobs"} className="dashboard-table__header__link">View all</Link>
						</AuthGuard>
					)}
					<AuthGuard module='job-post' permission='add' >
						<ShowToolTipComponent text="Click to create a Job">
						<Link to="/job/create">
							<img src={plusIcon} alt="Add Domain Assessment Icon" />
						</Link>
						</ShowToolTipComponent>
					</AuthGuard>
				</div>

			</div>
			<AuthGuard module='job-post' permission='view' >
				{
					isLoading && <Stack height={"100%"} padding={'12px'}>
						<Skeleton animation="wave" />
					</Stack>
				}
				{isEmptyData ? <DashboardEmptyDataComponent />
					: !isLoading && <>
						{
							screenWidth <= 768 && data.length > 0 &&
							<>
								<div className="dashboard-table__table">
									<div className="dashboard-table__tbody">
										{data.map(item => <MobileItem item={item} key={item.id} />)}
									</div>
								</div>
							</>
						}
						{screenWidth > 768 && data.length > 0 &&
							<table className="dashboard-table__table">
								<thead className="dashboard-table__thead">
									{colNames.map((col, index) => {
										return <td className="dashboard-table__thead__td" key={index}>
											{col}
										</td>
									})}
								</thead>
								<tbody className="dashboard-table__tbody">
									{
										data.map(job => {
											return <tr className="dashboard-table__tbody__tr" key={job.id} >
												<td className="dashboard-table__tbody__td">
													<Link to={`/recruitment/job/${job.id}/matched-profiles`} className="dashboard-table__link">
														{job.title || ""}
													</Link>
												</td>
												<td className="dashboard-table__tbody__td">
													{job.numberOpenings || 0}
												</td>
												<td className="dashboard-table__tbody__td">
													{job.workflow?.title ?
														<div className="dashboard-table__status publish">
															{job.workflow?.title || ""}
														</div>
														:
														"-"
													}
												</td>
											</tr>
										})
									}
								</tbody>
							</table>
						}
					</>}
			</AuthGuard>
		</div>
	);
};

export default JobsTable;

const MobileItem = ({ item }) => {
	const [openItem, setOpenItem] = useState(false)
	return <div className="table-mobile__item dashboard-table__mobile__item">
		<div
			className={`table-mobile__top ${openItem ? "active" : ""}`}
			onClick={() => {
				setOpenItem((prev) => !prev);
			}}>
			<div className="table-mobile__top__left">
				<p className="table-mobile__top__name dashboard-table__mobile__item__text__bold">
					<Link to={`/recruitment/job/${item.id}/matched-profiles`} className="dashboard-table__link">{item.title || ""}</Link>
				</p>
			</div>
			<ArrowSvg />
		</div>
		<div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
			<SingleMobileItem
				title={colNames[0]}
				content={<Link to={`/recruitment/job/${item.id}/matched-profiles`} className="dashboard-table__link">{item.title || ""}</Link>}
				textStyle="table-mobile__item__text"
			/>
			<SingleMobileItem title={colNames[1]} content={item.numberOpenings || 0} textStyle="table-mobile__item__text" />
			<SingleMobileItem
				title={colNames[2]}
				content={<div className="dashboard-table__status publish">{item.workflow?.title || ""}</div>}
				textStyle="table-mobile__item__text"
			/>
		</div>
	</div>
}

const ArrowSvg = () => {
	return (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_6751_20970)">
				<path
					d="M1 3L5 7L9 3"
					stroke="#5F6E80"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6751_20970">
					<rect
						width="10"
						height="10"
						fill="white"
						transform="translate(0 10) rotate(-90)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};