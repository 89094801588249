import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";

export const patchData = async (api, data) => {
  const res = await axios.patch(api, data, getConfig());
  if (res && res.data) {
    return res.data;
  }
};

export default patchData;
