import { useState } from "react";

import { navigate } from "@reach/router";
import DialogSuccessSaveDraft from "./DialogSuccessSaveDraft";

function DialogDraftQuestion({ onDialog, onSendToDraft }) {
  const [dialog, setDialog] = useState(false);

  const onSuccessDialog = () => {
    if (
      localStorage.getItem("prevRoute") ===
      "/recruitment/position-workflow/create"
    ) {
      navigate("/recruitment/position-workflow/create");
      localStorage.setItem("prevRoute", "");
    } else {
      navigate("/coding-assessments");
    }
  };

  return (
    <div className="dialog-draft" onClick={() => onDialog(false)}>
      <div
        className="dialog-draft__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-draft__head">
          <h2 className="dialog-draft__head__title">Send to Draft</h2>
          <button
            className="dialog-draft__head__cross-ico"
            onClick={() => {
              onDialog(true);
              onDialog(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <h3 className="dialog-draft__message">
          Are you sure you want to send this coding question to draft? You can come back to this later.
        </h3>
        <div className="dialog-draft__footer">
          <div
            className="dialog-draft__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="dialog-draft__footer__container__cancel"
              onClick={() => onDialog(false)}
            >
              Cancel
            </button>
            <button
              className="dialog-draft__footer__container__draft"
              onClick={(e) => {
                onSendToDraft(e);
                onDialog(true);
                setDialog(true);
              }}
            >
              Send to Draft
            </button>
            {dialog && <DialogSuccessSaveDraft onDialog={setDialog} onConfirm={onSuccessDialog} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogDraftQuestion;
