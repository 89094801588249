import { getEnv } from "@urecruits/api";
import { memo } from "react";
import { ManageAssignmentFilterTabs } from "../../enums/manage-assignment/manageAssignment";
import { store, useTypedSelector } from "../../store";
import {
  setAssessmentTypeManageAssignment,
  setJobManageAssignment,
} from "../../store/reducers/manageAssignmentReducer";
import { AssessmentType } from "../../utils/constants";
import Option from "../Global/SelectOptions";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();

const getFiltersFunc = (state) => state.manage_assignment.filters;
const FilterPopupManageAssignment = ({ activeTab }) => {
  const filter = useTypedSelector(getFiltersFunc);
  const getJobs = async (inputValue) => {
    try {
      const response = await axios(`${API_RECRUITMENT}/api/company/jobs`, {
        ...getConfig(),
        params: {
          search: inputValue,
        },
      });

      const jobOptions = response?.data?.rows.map((job) => ({
        value: job.id,
        label: job.title,
      }));

      return jobOptions || [];
    } catch (error) {
      console.error("Error fetching jobs:", error);
      return [];
    }
  };

  return (
    <>
      {activeTab === ManageAssignmentFilterTabs.assessmentType && (
        <div className="table-filter-popup__right__content">
          {
            <ul className="filters-popup-mtm-buttons-list">
              {AssessmentType.map((item, index) => {
                return (
                  <li
                    className={`${
                      filter?.assessmentType.find((x) => x === item.value)
                        ? "filters-popup-mtm-buttons-list__item active"
                        : "filters-popup-mtm-buttons-list__item"
                    }`}
                    key={index}
                    onClick={() =>
                      store.dispatch(
                        setAssessmentTypeManageAssignment(
                          filter.assessmentType.find((x) => x === item.value)
                            ? filter.assessmentType.filter(
                                (x) => x !== item.value
                              )
                            : [...filter.assessmentType, item.value]
                        )
                      )
                    }>
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          }
        </div>
      )}{" "}
      {activeTab === ManageAssignmentFilterTabs.jobId && (
        <div className="table-filter-popup__right__content">
          <AsyncSelect
            isMulti
            components={{
              Option,
            }}
            cacheOptions
            loadOptions={(inputValue) =>
              getJobs(inputValue).then((options) => options)
            }
            defaultOptions
            value={filter.jobId}
            onChange={(option: any) => {
              store.dispatch(setJobManageAssignment(option));
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            placeholder="Search by Job"
            styles={selectCustomStyle}
            id="locationSelect"
            instanceId="locationSelect"
          />
        </div>
      )}
    </>
  );
};
export default memo(FilterPopupManageAssignment);
