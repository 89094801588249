import axios from "axios";
import { getEnv } from "@urecruits/api";
import { getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
const deleteIcon = require('../../image/icon/delete_image_popup_ic.svg');
const { API_RECRUITMENT } = getEnv();

export const DeleteTemplateModal = ({setModal,templateId})=>{
  
  const handleDeleteTemplate = async ()=>{
    const response  = await axios.delete(`${API_RECRUITMENT}/api/offer/hellosign/template/${templateId}`,getConfig());  
    setModal(false);
  }
  
    return(
    <>
     <div className="modal__body__delete">
    <img src={deleteIcon} alt="" className="modal__body__delete__image"/>
    <p className="modal__body__delete__text">
    Are you Sure to delete <strong>this template permanently ?</strong>
        </p>
    </div>

    <div className="modal__actions">
     <button className="modal__actions__cancel__outlined" onClick={()=>setModal(false)} >Cancel</button>
     <button className="modal__actions__send" onClick={handleDeleteTemplate}>Delete</button>
    </div>
    </>
  );
}