import type { PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from "@reduxjs/toolkit";
import { IMembersAgeBreakdownPie } from "../../../types/redux/members-age-breakdown";

const initialStateMTM: IMembersAgeBreakdownPie = {
  totalMembers: 0,
  membersData: [],
};

export const membersAgeBreakdownPie = createSlice({
  name: "jobs_pie",
  initialState: initialStateMTM,
  reducers: {
    setMembersAgePie: (
      state,
      action: PayloadAction<IMembersAgeBreakdownPie>
    ) => {
      state.totalMembers = action.payload.totalMembers;
      state.membersData = action.payload.membersData;
    }
  },
});

export const { setMembersAgePie } = membersAgeBreakdownPie.actions;

export default membersAgeBreakdownPie.reducer;
