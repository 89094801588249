import { Outlet, useNavigate } from "react-router-dom";
import AnalyticsEntryCard from "../components/Report/AnalyticsEntryCard";
const emailCandidateProfile = require("../image/icon/email_candidate_profile_ic.svg");

const ReportScreen = () => {
  const navigate = useNavigate();
  const hrAnalyticsBaseUrl = "/hr-analytics/reports";
  const reportsList = [
    {
      reportType: "General",
      repportTypeList: [
        // {
        //   icon: emailCandidateProfile,
        //   title: "Audit trial",
        //   description: "Report on any actions in the system",
        //   lastViewDate: "Jun 3",
        //   link: "",
        // },
        // {
        //   icon: emailCandidateProfile,
        //   title: "Billing History",
        //   description: "Transaction and plan change report.",
        //   lastViewDate: "Oct 12",
        //   link: `${hrAnalyticsBaseUrl}/billing-history-report`,
        // },
        // {
        //   icon: emailCandidateProfile,
        //   title: "Change History",
        //   description:
        //     "Report on all changes in jobs. Any changes that have occurred over a certain period of time.",
        //   lastViewDate: "Jan 12",
        //   link: "",
        // },

        // {
        //   icon: emailCandidateProfile,
        //   title: "Company Admin Reports",
        //   description:
        //     "Report capturing resources such as the number of candidates interviewed, vacancies created and posted, etc.",
        //   lastViewDate: "Aug 1",
        //   link: "",
        // },
        {
          icon: emailCandidateProfile,
          title: "Members Report",
          description:
            "Report on company employees, their age, activity, date of joining and more.",
          // lastViewDate: "Feb 13",
          link: `${hrAnalyticsBaseUrl}/members-report`,
        },
        // {
        //   icon: emailCandidateProfile,
        //   title: "Missing Data",
        //   description: "Always be aware of missing data.",
        //   lastViewDate: "Jul 23",
        //   link: "",
        // },
        // {
        //   icon: emailCandidateProfile,
        //   title: "uRecruits Admin Reports",
        //   description: "Tenant and subscription reports",
        //   lastViewDate: "Jun 3",
        //   link: "",
        // },
      ],
    },
    {
      reportType: "Recruitment",
      repportTypeList: [
        // {
        //   icon: emailCandidateProfile,
        //   title: "Audit trial",
        //   description: "Report on any actions in the system",
        //   lastViewDate: "Jun 3",
        //   link: "",
        // },
        // {
        //   icon: emailCandidateProfile,
        //   title: "",
        //   description: "",
        //   lastViewDate: "",
        //   link: "",
        // },
        {
          icon: emailCandidateProfile,
          title: "Jobs",
          description: "Report on how many jobs have been created and managed.",
          // lastViewDate: "",
          link: `${hrAnalyticsBaseUrl}/job-report`,
        },
      ],
    },
    {
      reportType: "Assessment",
      repportTypeList: [
        // {
        //   icon: emailCandidateProfile,
        //   title: "Audit trial",
        //   description: "Report on any actions in the system",
        //   lastViewDate: "Jun 3",
        //   link: "",
        // },
        // {
        //   icon: emailCandidateProfile,
        //   title: "",
        //   description: "",
        //   lastViewDate: "",
        //   link: "",
        // },
        {
          icon: emailCandidateProfile,
          title: "Assessments",
          description: "Report on How many assessments have been created and managed.",
          // lastViewDate: "",
          link: `${hrAnalyticsBaseUrl}/assessment-report`,
          buttonText:"Coming Soon"
        },
      ],
    },
  ];

  return (
    <section className="report">
      <Outlet />

      <div className="report-screen-top">
        <div className="report-screen-top__title__wrapper">
          <h2 className="report-screen-top__title">Reports</h2>
        </div>
        {reportsList.map((reportData) => (
          <div className="report-wrapper" key={reportData.reportType}>
            <div className="report-inner-header">{reportData.reportType}</div>
            <div className="report-inner-card">
              {reportData.repportTypeList.map((reportDataType) => (
                <AnalyticsEntryCard
                  icon={reportDataType.icon}
                  title={reportDataType.title}
                  description={reportDataType.description}
                  lastViewDate={reportDataType.lastViewDate}
                  link={reportDataType.link}
                  key={reportDataType.title}
                  buttonText={reportDataType?.buttonText}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default ReportScreen;
