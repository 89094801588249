import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";

const fetchData = async (api, setData) => {
  const res = await axios.get(api, getConfig());
  if (res && res.data) {
    setData(res.data);

    return res;
  }
};

export default fetchData;
