import { useNavigate } from "react-router-dom";
import { handleSendRequest } from "../../screen/OfferPreviewScreen";
import { OfferLetterStatus } from "../../enums/usersOffers/UsersOffersEnums";
const OfferPreviewHeader = ({ offers, setModal, setSelectedOffer }) => {
  const navigate = useNavigate();

  const offer = offers?.toReversed()[0];
  const isActionDisplay = !(
    offer?.letterStatus === OfferLetterStatus.PENDING ||
    offer?.letterStatus === OfferLetterStatus.SENT ||
    offer?.letterStatus === OfferLetterStatus.CLOSED ||
    offer?.letterStatus === OfferLetterStatus.REJECTED
  );
  return (
    <div className="offer-preview__head">
      <div className="offer-preview__head__left">
        <p className="offer-preview__head__headline">
          Offer Letter: {offer?.jobTitle}
        </p>
      </div>

      <div className="offer-preview__head__right__desktop">
        <div className="modal__actions">
          <button
            className="modal__actions__cancel__text"
            onClick={() => navigate(`/recruitment/offers/${offer?.jobId}`)}
          >
            Back to Candidate List
          </button>
          {offers.length === 1 && (
            <>
              {isActionDisplay && (
                <button
                  className="modal__actions__approval"
                  onClick={() => {
                    setSelectedOffer(offer);
                    setModal("sendForApproval");
                  }}
                >
                  <SendForApprovalIcon />
                  <p className="modal__actions__approval__text">
                    Send For Approval
                  </p>
                </button>
              )}

              {isActionDisplay && (
                <button
                  className="modal__actions__send"
                  onClick={() => {
                    setModal("saveAndSend");
                    setSelectedOffer(offer);
                  }}
                >
                  Save & Send
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="offer-preview__head__right__mobile">
        <button
          className="offer-preview__head__right__mobile__action"
          onClick={() => navigate(`/recruitment/offers/${offer?.jobId}`)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2008 1.59961L4.80078 7.99961L11.2008 14.3996"
              stroke="#999EA5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <button
          className="offer-preview__head__actions__send"
          onClick={() => {
            handleSendRequest(offer.id, setModal, offer.jobId, offer.userId);
          }}
        >
          Save & Send
        </button>

        <button
          className="offer-preview__head__right__mobile__action"
          onClick={() => {
            setSelectedOffer(offer);
            setModal("sendForApproval");
          }}
        >
          <SendForApprovalIcon />
        </button>
      </div>
    </div>
  );
};
export default OfferPreviewHeader;

const SendForApprovalIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2668_39857)">
      <path
        d="M18.3327 1.6665L9.16602 10.8332"
        stroke="#099C73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3327 1.6665L12.4993 18.3332L9.16602 10.8332L1.66602 7.49984L18.3327 1.6665Z"
        stroke="#099C73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2668_39857">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
