import { memo } from "react";
import { useSelector } from 'react-redux';
import { CodingAssessmentTableEnums } from '../enums/CodingAssessmentEnums';
import { setLanguagesFilterCAT, setQuestionTypeFilterCAT, setStatusFilterCAT } from '../store/coding-assessment/codingAssessmentsTableReducer';
import { useDispatch } from 'react-redux';
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { selectCustomStyle } from "../styles/selectCustomStyle";

const Option = (props: any) => {

    return (
        <div>
            <components.Option {...props} className="manage-team-popup__form__checkbox">
                <input
                    id={props.value}
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label htmlFor={props.value}><span/></label>
                <p>{props.label}</p>
            </components.Option>
        </div>
    )
}


const getFiltersFunc = state => state.codingAssessmentTable.filters
const getFilterDataFunc = state => state.codingAssessmentTable.filterInfo
const FiltersCodingAssessments = ({ activeTab }) => {
    const dispatch = useDispatch()
    const filter = useSelector(getFiltersFunc)
    const filterData = useSelector(getFilterDataFunc)

    const loadOptions = async (input) => {
        if (input) {
            const newLanguages = filterData.languageList.filter(i => i.name.toLowerCase().includes(input.toLowerCase()))
            return newLanguages
        }
        return filterData.languageList
    }
    return <>
        {
            activeTab === CodingAssessmentTableEnums.STATUS &&
            <div className='table-filter-popup__right__content'>
                {
                    <ul className='filters-popup-coding-buttons-list'>
                        <li
                            className={`filters-popup-coding-buttons-list__item ${filter.status.find(i => i == 'DRAFT') ? 'active' : ''}`}
                            key={'draft'}
                            onClick={() => dispatch(setStatusFilterCAT(filter.status.find(i => i == 'DRAFT') ? filter.status.filter(i => i !== 'DRAFT') : [...filter.status, 'DRAFT']))}
                        >
                            DRAFT
                        </li>
                        <li
                            className={`filters-popup-coding-buttons-list__item ${filter.status.find(i => i == 'ACTIVE') ? 'active' : ''}`}
                            key={'active'}
                            onClick={() => dispatch(setStatusFilterCAT(filter.status.find(i => i == 'ACTIVE') ? filter.status.filter(i => i !== 'ACTIVE') : [...filter.status, 'ACTIVE']))}
                        >
                            ACTIVE
                        </li>
                    </ul>
                }
            </div>
        }
        {
            activeTab === CodingAssessmentTableEnums.QUESTION_TYPE &&
            <div className='table-filter-popup__right__content'>
                <ul className='filters-popup-coding-buttons-list'>
                    <li
                        className={`filters-popup-coding-buttons-list__item ${filter.questionType == 'live-task' ? 'active' : ''}`}
                        key={'draft'}
                        onClick={() => dispatch(setQuestionTypeFilterCAT(filter.questionType == 'live-task' ? '' : 'live-task'))}
                    >
                        Live-Task
                    </li>
                    <li
                        className={`filters-popup-coding-buttons-list__item ${filter.questionType == 'take-home' ? 'active' : ''}`}
                        key={'active'}
                        onClick={() => dispatch(setQuestionTypeFilterCAT(filter.questionType == 'take-home' ? '' : 'take-home'))}
                    >
                        Take-Home Task
                    </li>
                </ul>
            </div>
        }
        {
            activeTab === CodingAssessmentTableEnums.LANGUAGES &&
            <div className='table-filter-popup__right__content'>
                <div className='table-filter-popup__right__content'>
                    <AsyncSelect
                        components={{
                            Option,
                        }}
                        isMulti
                        cacheOptions
                        loadOptions={(input) => loadOptions(input)} // Ensure this function fetches data correctly
                        hideSelectedOptions={false}
                        defaultOptions
                        value={filter.languages.map((id) =>
                            filterData.languageList.find((i) => i.id === id)
                        )}
                        onChange={(selectedOptions) => {
                            const selectedIds = selectedOptions.map((option) => option.id);
                            dispatch(setLanguagesFilterCAT(selectedIds));
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        closeMenuOnSelect={false}
                        placeholder="Search by Languages"
                        styles={selectCustomStyle}
                        id="department"
                        instanceId="department"
                    />
                </div>
            </div>
        }
    </>
}

export default memo(FiltersCodingAssessments)