import {
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { Media } from "twilio-chat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { conversationSelectors } from "../../store/conversation/conversation.selectors";
import {
  setChatOpen
} from "../../store/conversation/conversation.actions";
import { saveAs } from "file-saver";
import send from "../../assets/icons/Send.svg";
import attachments from "../../assets/icons/attachments.svg";
import cross from "../../assets/icons/cross.svg";

function truncateFilename(filename, maxLength) {
  if (filename.length <= maxLength) {
    return filename;
  }
  const extensionIndex = filename.lastIndexOf(".");
  if (extensionIndex !== -1) {
    const extension = filename.slice(extensionIndex);
    const truncatedName = filename.slice(0, maxLength - extension.length);
    return `${truncatedName}...${extension}`;
  }
  return filename.slice(0, maxLength) + "...";
}

const MessageDrawer = ({ room }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    messagesList,
    isOpen,
    token,
    userIdentity,
    channel,
  } = useSelector(conversationSelectors?.getAllState);
  const messageBoxRef = useRef<any>();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [msgBoxText, setMsgBoxText] = useState<string>();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
 
  const handleSendMessage = () => {
    if (channel) {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        channel.sendMessage(formData);
        setSelectedFile(null);
      } else if (msgBoxText) {
        const messageText = msgBoxText.trim();
        if (messageText) {
          channel.sendMessage(messageText);
          setMsgBoxText("");
        }
      }
    }
  };

  useEffect(()=>{
    messageBoxRef?.current?.scrollTo({
      top: messageBoxRef?.current?.scrollHeight,
    });
  },[messagesList])

  useEffect(() => {
    if (!isChatWindowOpenRef.current && messagesList.length) {
      setHasUnreadMessages(true);
    }
  }, [messagesList]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  const isOpenFn = () => open;
  const isOpen1 = useRef(isOpenFn);
  isOpen1.current = isOpenFn;
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const download = async (urlType: Media) => {
    const selectedFile = { name: urlType.filename };
    urlType.getContentTemporaryUrl().then(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      saveAs(blob, selectedFile.name);
    });
  };

  const crossHandler = () => {
    dispatch(setChatOpen(false));
  };
  
  // useEffect(() => {
  //   if (!channel) return;
  //   const listener = async (msg: Message) => {
  //     const newMessage = {
  //       sid: msg.sid,
  //       text: msg.body,
  //       username: msg.author,
  //       dateCreated: msg.dateCreated,
  //       timeAgo: calculateTimeAgo(msg.dateCreated),
  //       media: msg.media,
  //       memberSid: msg.memberSid,
  //     };

  //     dispatch(addMessage(newMessage));
  //   };

  //   channel?.on("messageAdded", listener);
  //   // return () => {
  //   //   channel.removeListener("messageAdded", listener);
  //   // };
  // }, [channel]);

  // const calculateTimeAgo = (date: Date) => {
  //   const dateObject = new Date(date);
  //   const hours = dateObject.getHours();
  //   const minutes = dateObject.getMinutes();
  //   const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  //   return formattedTime;
  // };
  

  return (
    <>
      <Box
        sx={{
          width: "auto",
          height: "100%",
          overflow: "hidden",
        }}
        role="presentation">
        <Box className="chat-container">
          <Box className="chat-header">
            <Typography className="chat-header__title">Chat</Typography>
            <img src={cross} alt="cross-icon"  className="cross-button" onClick={crossHandler}/>
          </Box>
          <Box className="chat-area" ref={messageBoxRef}>
            {messagesList?.length > 0 &&
              Array.from({ length: messagesList?.length }, (v, index) => {
                return (
                  <div
                    className="message-wrapper"
                    key={messagesList[index]?.sid}>
                    <div
                      className="messageContent"
                      key={messagesList[index]?.sid}>
                      {(index > 0 &&
                        messagesList[index - 1]?.memberSid ==
                          messagesList[index].memberSid &&
                        messagesList[index - 1]?.timeAgo ===
                          messagesList[index]?.timeAgo) || (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignContent: "center",
                            alignItems: "center",
                            paddingTop: "3px",
                            marginTop: index === 0 ? "0px" : "10px",
                          }}
                          key={index}>
                          <Typography
                            component="span"
                            className="manage-team__text--gray7"
                            sx={{
                              fontWeight: "bold",
                              fontFamily: "Google Sans,Roboto,Arial,sans-serif",
                              color: "var(--hotlane-on-background-color)",
                              fontSize: "14px",
                              lineHeight: "1.25rem",
                              paddingTop: "0",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}>
                            {messagesList[index]?.username !== userIdentity
                              ? messagesList[index]?.username
                              : "You"}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              fontWeight: "500",
                              color: "gray",
                              fontSize: "14px",
                              lineHeight: "1.25rem",
                              paddingTop: "0",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}>
                            {messagesList[index]?.timeAgo}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        className="message"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.25rem",
                          paddingTop: "0",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          padding: "2px 0",
                        }}>
                        {messagesList[index]?.text}
                      </Box>
                      {messagesList[index]?.media && (
                        <Box
                          component="span"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => download(messagesList[index]?.media)}>
                          <img
                            src={attachments}
                            style={{width:"16px",marginRight:"10px"}}
                          />
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography>
                              {truncateFilename(
                                messagesList[index]?.media?.filename,
                                10
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </div>
                  </div>
                );
              })}
          </Box>
          <Box className="chat-typing-area-wrapper">
            {selectedFile && (
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px solid LightGray",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "15px",
                  padding: "10px 10px",
                  marginBottom: "5px",
                  maxWidth: "fit-content",
                }}>
                <Box>
                  <Typography
                    component="span"
                    sx={{
                      color: "#1A1A1A99",
                      fontWeight: "500",
                    }}>
                    Selected file
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "600",
                    }}>
                    : {truncateFilename(selectedFile.name, 20)}
                  </Typography>
                </Box>
                <CancelIcon
                  sx={{
                    cursor: "pointer",
                    width: "30px",
                    height: "24px",
                    color: "rgba(255, 99, 71, 0.8)",
                  }}
                  onClick={() => setSelectedFile(null)}
                />
              </Box>
            )}

            <Box className="chat-typing-area">
              <input
                type="text"
                placeholder="Type your message..."
                className="chat-input"
                value={msgBoxText}
                onChange={(e) => setMsgBoxText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "20px",
                }}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={handleFileChange}
                />
                <label htmlFor="myFile">
                  <Tooltip title="Attachments">
                    <AttachFileIcon
                      sx={{
                        transform: "rotate(-312deg) translateX(3px)",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </label>
              </Box>
              <Tooltip title="Send message">
                <button
                  className="send-button"
                  onClick={() => handleSendMessage()}
                  style={{
                    color: selectedFile || msgBoxText ? "red" : "gray",
                    cursor:
                      selectedFile || msgBoxText ? "pointer" : "not-allowed",
                  }}>
                  <img
                    src={send}
                    alt=""
                    style={{
                      cursor:
                        selectedFile || msgBoxText ? "pointer" : "not-allowed",
                    }}
                  />
                </button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default MessageDrawer;
