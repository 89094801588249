import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";

const fetchData = async (api) => {
  try {
    const res = await axios.get(api, getConfig());
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};

export const postData = async (api, body) => {
  try {
    const res = await axios.post(api, body, getConfig());
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    throw Error("Unexpected Error");
  }
};

export const postFormData = async (api, body) => {
  try {
    const res = await axios({
      method: "post",
      url: api,
      data: body,
      ...getConfig()
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};

export default fetchData;
