import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();

const HellosignCard = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  useEffect(() => {
    const getHellosignCredentials = async () => {
      await axios(`${API_RECRUITMENT}/api/hellosign-integration`, getConfig())
        .then(({ data }) => {
          if (data?.clientId && data?.key) {
            setIsConnected(true);
          }
        })
        .catch((e) =>
          console.log("error occured during fetching Hellosign credentials", e)
        );
    };

    if (!isConnected) getHellosignCredentials();
  }, []);
  const HellosignSchema = z.object({
    clientId: z.string().trim().min(1, { message: "This is required" }),
    key: z.string().trim().min(1, { message: "This is required" }),
  });

  type HellosignSchemaType = z.infer<typeof HellosignSchema>;

  const {
    handleSubmit,
    formState: { errors },
    register,
    ...form
  } = useForm<HellosignSchemaType>({
    resolver: zodResolver(HellosignSchema.required()),
  });

  const onSubmit = (data) => {
    const postHellosign = async () => {
      await axios.post(
        `${API_RECRUITMENT}/api/hellosign-integration`,
        data,
        getConfig()
      );
    };
    postHellosign().then(() => setIsConnected(true));
  };

  const handleDisconnectHellosign = async () => {
    const res = await axios
      .delete(`${API_RECRUITMENT}/api/hellosign-integration/remove`, getConfig())
      .then((res) => {
        if (res.status === 200) setIsConnected(false);
      })
      .catch((e) => {
        console.log("error occured during disconecting Hellosign", e);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isConnected && (
          <div className="integrations__card__row">
            <div className="integrations__card__item">
              <p className="integrations__card__item__label">
                Client Id<span>*</span>
              </p>
              <input
                type="text"
                id="clientId"
                placeholder="Enter Client Id here"
                {...register("clientId", { required: true })}
                className="integrations__card__item__input"
              />
              {errors.clientId && (
                <p className="integrations__card__item__error-message">
                  {errors.clientId.message}
                </p>
              )}
            </div>

            <div className="integrations__card__item">
              <p className="integrations__card__item__label">
                API Key<span>*</span>
              </p>
              <input
                type="text"
                id="key"
                placeholder="Enter API Key here"
                {...register("key", { required: true })}
                className="integrations__card__item__input"
              />
              {errors.key && (
                <p className="integrations__card__item__error-message">
                  {errors.key.message}
                </p>
              )}
            </div>
          </div>
        )}

        <div className="integrations__card__integration-button">
          {!isConnected ? (
            <button
              type="submit"
              className="integrations__card__integration-button__button button--filled"
            >
              Connect
            </button>
          ) : (
            <button
              type="button"
              className="integrations__card__integration-button__button button--empty"
              onClick={() => {
                handleDisconnectHellosign();
              }}
            >
              Disconnect
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default HellosignCard;
