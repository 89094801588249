import { default as ReactSelect } from "react-select";
import { memo, useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { selectSmallStyle } from "../../styles/selectSmallStyle";

const defaultPieColors= ['#029CA5','#021CA5','#737980','#E9C715','#FF8541','#7D97F4','#FE4672']
export type CircleChartType = {
  title: string;
  pieChartData: {
    data: {
      doc_count: number;
      key: string;
    }[];
    totalCount: number;
    showCount?: boolean;
  };
  countText: string;
  pieColors: string[];
  showPecentage?: boolean;
  durantionChange?:{
      changePieDurationHandler: (duration: string) => void;
      duration: string;
  }
};

const CircleChart = ({
  title,
  pieChartData,
  countText,
  pieColors=defaultPieColors,
  showPecentage=false,
  durantionChange
  
}: CircleChartType) => {
  const { totalCount: totalCount, data: chartData=[],showCount=false } = pieChartData;
  const [colors, setColors] = useState<string[]>([]);
  useEffect(() => {
    chartData.length &&
      chartData.forEach((entry, index) => {
        const color = pieColors[index];
        setColors((preColor) => {
          return [...(preColor ?? []), color];
        });
      });
  }, [chartData?.length]);


  const options = [
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "month", label: "This Year" },
  ];

  return (
    <div className="circle-chart">
      <div className="circle-chart__header">
        <p className="circle-chart__headline">{title.toUpperCase()}</p>
        {durantionChange && <div className="area-chart__head__select">
          <ReactSelect
            options={options}
            value={options.find((ele) => ele.value === durantionChange.duration)}
            onChange={(value: any) => {
              durantionChange.changePieDurationHandler(value.value);
            }}
            isSearchable={false}
            hideSelectedOptions={false}
            styles={selectSmallStyle}
            id="durationSelect"
            instanceId="durationSelect"
          />
        </div>}
      </div>
      <div className="circle-chart__inner">
        <div className="circle-chart__left">
          <PieChart width={192} height={192}>
            <Pie
              data={chartData}
              cx={90}
              cy={90}
              innerRadius={74}
              outerRadius={90}
              dataKey="doc_count"
            >
              {chartData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
          </PieChart>
          <div className="circle-chart__info">
            {showCount && <p className="circle-chart__info__count">{totalCount}</p>}
            <p className="circle-chart__info__label">{countText}</p>
          </div>
        </div>
        <div className="circle-chart__right">
          <ul className="circle-chart__list">
            {chartData.map((item, index) => {
              return (
                <li className="circle-chart__item" key={index}>
                  <span
                    className="circle-chart__point"
                    style={{ backgroundColor: colors[index] }}
                  ></span>
                  <p className="circle-chart__name">
                    {item.key + " "} 
                    {
                        showPecentage ?
                        <span>{totalCount ? Math.round((item.doc_count/totalCount) *100) : 0} %</span>
                        :
                        <span>{item.doc_count||0}</span>
                    }
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(CircleChart);
