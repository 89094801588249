import { useState, useEffect, useCallback } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import fetchData from "../hook/http";
import useSnackbar from "../hook/useSnackbar";
import { BackgroundCandidatesTableHeader, RoundType, ScreeningType } from "../enums";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import { AuthGuard, useHasPermission, TableCardViewComp, ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { store, useTypedSelector } from "../store";
import { getEnv } from "@urecruits/api";
import {
  setCurrentPage,
  setDateRangeFilter,
  setLimit,
  setOrderColumns,
  setSearchValue,
  setSortByFilter,
  setSortTypeFilter,
  setStatusFilter,
  setTotalCount,
  setVendorFilter,
  defaultFilters,
  setModal,
  setModalData,
} from "../store/reducers/backgroundCandidatesReducer";
import { CandidateFilters } from "../components/BackgroundScreening";
import { BGVStatus } from "../enums";
import { EditorModals } from "../components/Popups/editor-modals";
import { findActiveRound } from "../utils";

const starIcon = require("../image/icon/star_ic.svg");
const createIcon = require("../image/icon/create_order_ic.svg");
const eyeOnIcon = require("../image/icon/eye-on.svg");
const { API_RECRUITMENT } = getEnv();

type ICandidateHeaderState = {
  jobTitle: string;
  jobLocation: string;
};

const getTable = (store) => store.background_candidates;
const getTableFilters = (store) => store.background_candidates.filters;
const getTableFilterData = (store) => store.background_candidates.filterInfo;

export const BackgroundScreeningCandidates = () => {
  const { checkUserPermission } = useHasPermission();
  const navigate = useNavigate()
  const columns: Array<IColumns> = [
    {
      headerName: BackgroundCandidatesTableHeader.CANDIDATE_NAME,
      field: "candidate name",
      pinnable: true,
      renderCell: (cellValue, rowData) => {
        const candidateName =
          rowData.user.firstname + " " + rowData.user.lastname;
        return (
          <p className="background__candidates__table__text--link" onClick={()=>navigate(`/recruitment/candidate-profile/${rowData.user?.id}`)}>{candidateName}</p>
        );
      },
      mobileRenderCell: (cellValue, rowData) => {
        const candidateName =
          rowData.user.firstname + " " + rowData.user.lastname;
        return (
          <p className="background__candidates__table__text">{candidateName}</p>
        );
      },
      visibility: true,
      isMobileTitle: true,
      className: "",
    },
    {
      headerName: BackgroundCandidatesTableHeader.CANDIDATE_PHONE_NUMBER,
      field: "candidate phone number",
      renderCell: (cellValue, rowData) => {
        const phoneNumber = rowData.user.phone;
        return (
          <p className="background__candidates__table__text">{phoneNumber}</p>
        );
      },
      mobileRenderCell: (cellValue, rowData) => {
        const phoneNumber = rowData.user.phone;
        return (
          <p className="background__candidates__table__text">{phoneNumber}</p>
        );
      },
      visibility: true,
      className: "",
    },
    {
      headerName: BackgroundCandidatesTableHeader.THIRD_PARTY_VENDOR,
      field: "3rd party vendor",
      renderCell: (cellValue, rowData) => {
        const vendor = rowData.user.backgroundOrders?.vendor || "-";
        return <p className="background__candidates__table__text">{vendor}</p>;
      },
      mobileRenderCell: (cellValue, rowData) => {
        const vendor = rowData.user.backgroundOrders?.vendor || "-";
        return <p className="background__candidates__table__text">{vendor}</p>;
      },
      visibility: true,
      className: "",
    },
    {
      headerName: BackgroundCandidatesTableHeader.DATE_BGV_COMPLETED,
      field: "Date BGV Completed",
      renderCell: (cellValue, rowData) => {
        return (
          <p className="background__candidates__table__text">
            {"Dec 29,2020 12:00PM"}
          </p>
        );
      },
      mobileRenderCell: (cellValue, rowData) => {
        return (
          <p className="background__candidates__table__text">
            {"Dec 29,2020 12:00PM"}
          </p>
        );
      },
      visibility: true,
      className: "",
    },
    {
      headerName: BackgroundCandidatesTableHeader.RECRUITER_COMMENTS,
      field: "recruiter comments",
      renderCell: (cellValue, rowData) => {
        const comment = rowData.user.backgroundOrders?.recruiter_comment || "-";
        return <p className="background__candidates__table__text">{comment}</p>;
      },
      mobileRenderCell: (cellValue, rowData) => {
        const comment = rowData.user.backgroundOrders?.recruiter_comment || "-";
        return <p className="background__candidates__table__text">{comment}</p>;
      },
      visibility: true,
      className: "",
    },
    {
      headerName: BackgroundCandidatesTableHeader.CANDIDATE_SCORE_BOARD,
      field: "candidate scoreboard",
      renderCell: (cellValue, rowData) => {
        const to = `/recruitment/job/${rowData.jobId}/candidate-scoreboard/${rowData.userId}`;
        return (
          <ShowToolTipComponent text='View scoreboard'>
          <Link className="background__candidates__table__link" to={to}>
            <img src={starIcon} alt="start_icon" />
            <span>Candidate Scoreboard</span>
          </Link>
          </ShowToolTipComponent>
        );
      },
      mobileRenderCell: (cellValue, rowData) => {
        const to = `/recruitment/job/${rowData.jobId}/candidate-scoreboard/${rowData.userId}`;
        return (
          <Link className="background__candidates__table__link" to={to}>
            <img src={starIcon} alt="start_icon" />
            <span>Candidate Scoreboard</span>
          </Link>
        );
      },
      visibility: true,
      className: "",
    },
    {
      headerName: BackgroundCandidatesTableHeader.CANDIDATE_BGV_STATUS,
      field: "BGV status",
      renderCell: (cellValue, rowData) => {
        const status =
          rowData.user.backgroundOrders?.toReversed()[0]?.BGV_status ||
          BGVStatus.NOT_INITIATED;
        return <p className={`${getBGVStatusStyle(status)}`}>{status}</p>;
      },
      mobileRenderCell: (cellValue, rowData) => {
        const status =
          rowData.user.backgroundOrders?.toReversed()[0]?.BGV_status ||
          BGVStatus.NOT_INITIATED;
        return (
          <p
            className={`${getBGVStatusStyle(
              status
            )} background__candidates__table__status-block`}
          >
            {status}
          </p>
        );
      },
      visibility: true,
      className: "",
    },
    {
      ...(checkUserPermission('background', ['add','edit']) ? {
        headerName: "Actions",
        field: "actions",
        setActions: [
          {
            getAction: (row) => {
              const status = !!row.user?.backgroundOrders.length ? row.user?.backgroundOrders[0]?.BGV_status : BGVStatus.NOT_INITIATED
              const round = findActiveRound(row.round?.rounds || [])
              if (status !== BGVStatus.NOT_INITIATED || round?.title !== RoundType.BACKGROUND_SCREENING) return
              return {
                svg: <ShowToolTipComponent text='Initiate background screening'><img src={createIcon} alt="plus icon" /></ShowToolTipComponent>,
                title: "Initiate",
                onClick: (row, event) => {
                  store.dispatch(
                    setModalData({ userId: row.userId, jobId: row.jobId, screeningType: ScreeningType.BACKGROUND })
                  );
                  store.dispatch(setModal("screening"));
                },
                className: "",
              }
            },
          },
          (checkUserPermission('background',['edit','view']) ? {
            getAction:(row)=>{
              const status = !!row.user?.backgroundOrders.length ? row.user?.backgroundOrders[0]?.BGV_status : BGVStatus.NOT_INITIATED
              const round = findActiveRound(row.round?.rounds || [])
              if (!row.user?.backgroundOrders[0]?.id || (status !== BGVStatus.REVIEWED && status !== BGVStatus.VERIFIED)) return
              return {
                svg: <ShowToolTipComponent text={status === BGVStatus.REVIEWED ?'Review':'View'}><img src={eyeOnIcon} alt="View/Review icon" /></ShowToolTipComponent>,
                title: status === BGVStatus.REVIEWED? 'Review':status === BGVStatus.VERIFIED ? 'View':'',
                onClick: (row, event) => {
                  navigate(`/recruitment/background-screening/candidates/${row.jobId}/${row.user?.backgroundOrders[0].id}`)
                },
                className: "",
              }
            }
          } : null)
        ],
        visibility: true,
      } : null)
    }
  ].filter(element => !!Object.keys(element).length);

  const [jobTitle, setJobTitle] = useState("");
  const { jobId } = useParams();
  const { ErrorElement, APIExecutor } = useSnackbar();
  const location = useLocation();
  const state = location?.state as ICandidateHeaderState;
  const { jobTitle: routeJobTitle, jobLocation: routeJobLocation } =
    state ?? {};

  const [filterString, setFilterString] = useState<string>("");
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    const getJobTitle = async () => {
      const { title } = await APIExecutor(() =>
        fetchData(`${API_RECRUITMENT}/api/job/${jobId}`)
      );
      setJobTitle(title);
    };

    if (!routeJobTitle && checkUserPermission(['job-post', 'background'], 'view')) {
      getJobTitle();
    }
  }, [checkUserPermission(['job-post', 'background'], 'view')]);

  useEffect(() => {
    const filters = table.filters;
    if (isFilterSubmit && filters) {
      setFilterString(
        `${filters.vendor ? "&vendor=" + filters.vendor : ""}${filters.date.from
          ? "&dateFrom=" + encodeURIComponent(filters.date.from)
          : ""
        }${filters.date.to
          ? "&dateTo=" + encodeURIComponent(filters.date.to)
          : ""
        }${filters.status ? "&status=" + encodeURIComponent(filters.status) : ""
        }`
      );
    }
  }, [isFilterSubmit]);

  const table = useTypedSelector(getTable);

  const createAPIString = () => {
    const filters = table.filters;
    const pagination = table.pagination;
    return `${API_RECRUITMENT}/api/background/candidates/${jobId}?limit=${pagination.limit
      }&offset=${(pagination.currentPage - 1) * pagination.limit}${filters.searchValue ? "&search=" + filters.searchValue : ""
      }${filters.sortBy ? "&sortBy=" + filters.sortBy : ""}${filters.sortType ? "&sortType=" + filters.sortType : ""
      }${filterString}`;
  };

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValue(value));
    store.dispatch(setCurrentPage(1));
  }, []);

  const FilterItems = (state) => <CandidateFilters activeTab={state} />;

  const resetFilters = (pagination) => {
    store.dispatch(setSortByFilter(defaultFilters.sortBy));
    store.dispatch(setSortTypeFilter(defaultFilters.sortType));
    store.dispatch(setSearchValue(defaultFilters.searchValue));
    store.dispatch(setDateRangeFilter(defaultFilters.date));
    store.dispatch(setVendorFilter(defaultFilters.vendor));
    store.dispatch(setStatusFilter(defaultFilters.status));
    setIsFilterSubmit(true);
  };

  const setModalCallback = (value) => store.dispatch(setModal(value));

  return (
    <>
      <AuthGuard module='background' permission='view'>
        <ErrorElement />
        <div className="background">
          <div className="background__candidates__head">
            <ul className="background__candidates__head__breadcrumbs">
              <li className="background__candidates__head__breadcrumbs__item">
                <Link
                  to="/recruitment/background-screening/jobs"
                  className="background__candidates__head__breadcrumbs__link"
                >
                  Background screening
                </Link>
              </li>
              <li className="background__candidates__head__breadcrumbs__item">
                <p className="background__candidates__head__breadcrumbs__link">
                  {routeJobTitle ?? jobTitle}
                </p>
              </li>
            </ul>
            <p className="background__candidates__head__title">
              {routeJobTitle ?? jobTitle}{" "}
            </p>
            <p className="users-offers__head__location">
              {routeJobLocation || ""}
            </p>
          </div>

          <TableCardViewComp
            tableViewType={"table"}
            columns={columns}
            rowsData={{ api: createAPIString() }}
            disableRow={{
              text: "Not Available Now",
              getDisableStatus: (row) => {
                const round = findActiveRound(row.round?.rounds || [])
                return round?.title !== RoundType.BACKGROUND_SCREENING
              }
            }}
            searchField={{
              searchValue: table.filters.searchValue,
              setSearchValue: setSearchCallback,
              placeholder: "Enter Candidate Name",
            }}
            pagination={{
              limit: table.pagination.limit,
              currentPage: table.pagination.currentPage,
              totalCount: table.pagination.totalCount,
              setTotalCount: (count: number) => {
                store.dispatch(setTotalCount(count));
              },
              setCurrentPage: (value) => {
                store.dispatch(setCurrentPage(value));
              },
              setLimit: (value: any) => {
                store.dispatch(setLimit(value));
              },
              filters: table.filters,
            }}
            refetchApi={{ refetch, setRefetch }}
            rearrangeColumns={{ setColumnOrder: setOrderColumns }}
            storeName={"background_candidates"}
            store={store}
            useTypedSelector={useTypedSelector}
            filters={{
              filterData: useTypedSelector(getTableFilterData),
              filter: useTypedSelector(getTableFilters),
              resetFilters,
              filterItems: FilterItems,
              isFilterSubmit,
              setIsFilterSubmit: (value: boolean) => setIsFilterSubmit(value),
              isEmptyFilter,
              setIsEmptyFilter: (value: boolean) => setIsEmptyFilter(value),
            }}
          />
        </div>
        <EditorModals
          modal={table.modal}
          setModal={setModalCallback}
          modalData={table.modalData}
          refetchHandler={setRefetch}
        />
      </AuthGuard>
    </>
  );
};

const getBGVStatusStyle = (status) => {
  switch (status) {
    case BGVStatus.NOT_INITIATED:
      return "table__status-block table__status-block--blue";
    case BGVStatus.IN_PROGRESS:
      return "table__status-block table__status-block--yellow";
    case BGVStatus.REPORT_ON_HOLD:
      return "table__status-block table__status-block--orange";
    case BGVStatus.READY_FOR_REVIEW:
      return "table__status-block table__status-block--orange";
    case BGVStatus.REVIEWED:
      return "table__status-block table__status-block--orange";
    case BGVStatus.VERIFIED:
      return "table__status-block table__status-block--green";
    case BGVStatus.REJECTED:
      return "table__status-block table__status-block--red";
  }
};
