import { format } from 'date-fns';
import { default as ReactSelect } from "react-select";
import { memo } from "react";
import {
    AreaChart as RechartsAreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import { selectSmallStyle } from '../../styles/selectSmallStyle';

const getTickFormatter = (duration) => {
    switch (duration) {
        case 'week':
            return (tick) => format(new Date(tick), 'EEE');
        case 'month':
            return (tick) => format(new Date(tick), 'd');
        case 'year':
            return (tick) => format(new Date(tick), 'MMM');
        default:
            return (tick) => format(new Date(tick), 'P');
    }
};

export type AreaChartType = {
    data: {
        data:{
            doc_count: number,
            key: string,
            ket_as_string?: string,
        }[],
        duration?: string,
    },
    title: string,
    dataKey: string,
    areaChartOperations?: {
        buttonText: string,
        onChange: (value: string) => void,
        isActive: string,
        buttonValue: string,
    }[],
    changeAreaHandler: (value: string) => void,
    xAxisTickDataKey?: string,
}

const options = [
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "year", label: "This Year" },
];

const AreaChart = ({
    data,
    title,
    dataKey,
    areaChartOperations,
    changeAreaHandler,
    xAxisTickDataKey = 'key_as_string'
}:AreaChartType) => {
    return (
        <div className="area-chart">
            <div className="area-chart__head">
                {!!title ? <p className="area-chart__head__headline">{title}</p> : ""}
                <div className="table-screen-top__buttons-group">
                    {areaChartOperations.map((buttonDetails) => {
                        return (
                            <button
                                key={buttonDetails.buttonText}
                                className={`table-screen-top__buttons-group__item ${buttonDetails.isActive} `}
                                onClick={() => {
                                    buttonDetails.onChange(buttonDetails.buttonValue);
                                }}
                            >
                                {buttonDetails.buttonText}
                            </button>
                        );
                    })}
                </div>
                {data?.duration && <div className="area-chart__head__select">
                    <ReactSelect
                        options={options}
                        value={options.find((ele) => ele.value === data.duration)}
                        onChange={(value: any) => {
                            changeAreaHandler(value.value);
                        }}
                        isSearchable={false}
                        hideSelectedOptions={false}
                        styles={selectSmallStyle}
                        id="areaChartSelect"
                        instanceId="areaChartSelect"
                    />
                </div>}
            </div>
            <div
                className={`area-chart__body`}
            >
                <div className="area-chart__body__inner">
                    <ResponsiveContainer width="100%" height="100%">
                        <RechartsAreaChart
                            width={412}
                            height={198}
                            data={data.data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis
                                dataKey={xAxisTickDataKey||"name"}
                                tickFormatter={getTickFormatter(data.duration)}
                                style={{
                                    fontSize: "12px",
                                    textTransform: "uppercase",
                                    color: "#999EA5",
                                    lineHeight: "1.4",
                                }}
                            />
                            <YAxis
                                style={{
                                    fontSize: "12px",
                                    color: "#999EA5",
                                }}
                            />
                            <Area
                                type="monotone"
                                dataKey={dataKey}
                                stroke="#099C73"
                                fill="#099C7340"
                            />
                        </RechartsAreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default memo(AreaChart);
