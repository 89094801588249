import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../../store";
import { changeSuccessfullyPopup } from "../../../store/apply-action-creators";
import { Button } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const successImage = require("../../../image/icon/success_image.svg");

const ChangeSaved = ({ jobTitle = "React Developer", jobId = "1" }) => {
  const navigate = useNavigate()
  const onCancel = ()=>{
    store.dispatch(changeSuccessfullyPopup(false))
    navigate("/candidate/jobs")
  }
  return (
    <div className="popup">
      <div className="popup__step">
        <div className="popup__head">
          <p className="popup__head__headline"></p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="popup__head__close"
            onClick={onCancel}>
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="popup__body">
          <img src={successImage} alt="" className="popup__body__image" />
          <p className="popup__body__headline">
            {`You have applied to a ${jobTitle} job Successfully. Please keep updated with mail conversations.`}
          </p>
        </div>
      
    <div className="popup__bottom center">
    <Button label="Got it!"  className="popup__bottom__publish button--filled" onClick={onCancel}/>
           {/*    <Link
            to={`/candidate/meeting-appointment?jobId=${jobId}`}
            className="popup__bottom__publish button--filled"
            onClick={() =>{ store.dispatch(changeSuccessfullyPopup(false))}}>
            Choose
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default memo(ChangeSaved);
