import { memo, useState } from "react";
import { store, useTypedSelector } from "../../../store";
import {
	setChangeWorkflowPopupJobs,
} from "../../../store/reducers/jobsTableReducer";
import { patchData } from "../../../hook/http";
import { setJobsHandler } from "../../../screen/JobsScreen";
import { getEnv } from "@urecruits/api";
import { AuthGuard, Button } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const gettableInfo=state => state.jobs
const {API_RECRUITMENT} = getEnv()
const ChangeWorkflowPopup=()=>{
	const [errorNotification, setErrorNotification] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const table = useTypedSelector(gettableInfo)

	const onSubmit = async() => {
		setIsLoading(true)
		try {
			await	patchData(`${API_RECRUITMENT}/api/job`, {
				id: table.changeWorkflow.jobId,
				workflowId:table.changeWorkflow.id
			}).then((res) => {
					if(res==='') {
						setErrorNotification('Something went wrong, try again later')
						return
					}
					store.dispatch(setChangeWorkflowPopupJobs({open: false, title: '', id: -1,jobId:-1}))
					setJobsHandler(table.pagination.currentPage,table.pagination.limit,table.filters)
				}, error => {
					setErrorNotification(error)
				}
			)
		} catch (error) {
			console.error(error,"error");
			
		}
		finally{
			setIsLoading(false)
		}

	}
	return		<AuthGuard module='job-post' permission='edit'>
	<div className="popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
					Change onboard workflow?
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => store.dispatch(setChangeWorkflowPopupJobs({open: false, title: '', id: -1,jobId:-1}))}
					className="popup__head__close"
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
					      strokeLinecap="round"
					      strokeLinejoin="round"/>
				</svg>
			</div>
			<div className="popup__body">
				<p className="popup__body__text">
					Are you sure you want to change the workflow to:  <span>{table.changeWorkflow.title}</span> .  Please confirm your actions.
				</p>
			</div>
			<div className="popup__bottom end">
				<button
					className="popup__bottom__cancel button--empty"
					onClick={() => {
						store.dispatch(setChangeWorkflowPopupJobs({open: false, title: '', id: -1,jobId:-1}))
					}}
				>
					Cancel
				</button>
				<Button isLoading={isLoading} label="Confirm" onClick={onSubmit} className="popup__bottom__publish button--filled" />

			
				{
					errorNotification.length > 0 && (
						<p className="error-message">{errorNotification}</p>
					)
				}
			</div>
		</div>
	</div>
	</AuthGuard>
}

export default memo(ChangeWorkflowPopup)