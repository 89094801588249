import { useTypedSelector } from "../../../store";
import { useState } from "react";
import SingleItem from "../../Global/table/SingleMobileItem";
import { JobsOffersTableTabsEnums } from "../../../enums/jobsOffers/JobsOffersEnums";
import { useNavigate } from "react-router-dom";

const getOffers = (state) => state.offers_jobs.offers;

//TODO: nt add real data
const MobileTableOffers = () => {
  const offers = useTypedSelector(getOffers);
  return (
    <div className="table-mobile">
      {offers.map((item) => {
        return <MobileTableItem key={item.id} item={item} />;
      })}
    </div>
  );
};

export default MobileTableOffers;

const MobileTableItem = ({ item }) => {
  const [openItem, setOpenItem] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => {
          setOpenItem((prev) => !prev);
        }}
      >
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{`${item.jobTitle}`}</p>
        </div>
        <ArrowSvg />
      </div>

      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleItem
          title={JobsOffersTableTabsEnums.JOB_LOCATION + ":"}
          text={item.jobLocation}
          textStyle={"table-mobile__item__text"}
        />

        <SingleItem
          title={JobsOffersTableTabsEnums.OF_OPENINGS + ":"}
          text={item.jobOpening}
          textStyle={"table-mobile__item__text"}
        />

        <SingleItem
          title={JobsOffersTableTabsEnums.PENDING_CANDIDATE + ":"}
          text={item.pendingCandidates}
          textStyle={"table-mobile__item__text"}
        />

        <SingleItem
          title={JobsOffersTableTabsEnums.OF_OFFER_LETTER_ACCEPTED + ":"}
          text={item.offerAccepted}
          textStyle={"table-mobile__item__text"}
        />

        <SingleItem
          title={
            JobsOffersTableTabsEnums.OF_OFFER_LETTER_YET_TO_ACCEPT_BY_CANDIDATE +
            ":"
          }
          text={item.offerYetToAccept}
          textStyle={"table-mobile__item__text"}
        />

        <SingleItem
          title={JobsOffersTableTabsEnums.OF_OFFER_LETTER_YET_TO_SEND + ":"}
          text={item.offerYetToSend}
          textStyle={"table-mobile__item__text"}
        />

        <SingleItem
          title={
            JobsOffersTableTabsEnums.OF_REJECTED_THE_OFFER_LETTER_FROM_THE_CANDIDATE +
            ":"
          }
          text={item.rejectedOffer}
          textStyle={"table-mobile__item__text"}
        />

        <div className="table-mobile__item__wrap ">
          <button
            className="table-mobile__item__action-button table-mobile__item__action-button--green"
            onClick={() => {
              navigate(`/recruitment/offers/${item.id}`);
            }}
          >
            Go to Candidate List
          </button>
        </div>
      </div>
    </div>
  );
};

const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
