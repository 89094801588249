import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();

const UniversalCard = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isForcePassword, setIsForcePassword] = useState(false);

  useEffect(() => {
    const getUniversalCredentials = async () => {
      const response = await axios(
        `${API_RECRUITMENT}/api/universal/account/get`,
        getConfig()
      );

      if (response.status === 200 && response.data) {
        setIsConnected(true);
      }
    };

    if (!isConnected) getUniversalCredentials();
  }, []);

  
  const UniversalSchema = z.object({
    accountNumber: z.string().trim().min(1, { message: "This is required" }),
    userName: z.string().trim().min(1, { message: "This is required" }),
    password: z.string().optional(),
  });
  
  type UniversalSchemaType = z.infer<typeof UniversalSchema>;
  
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    ...form
  } = useForm<UniversalSchemaType>({
    resolver: zodResolver(UniversalSchema.required()),
    defaultValues: {
      password: "",
    },
  });
  
  useEffect(()=>{
   setValue("password","");     
  },[isForcePassword]);
  
  const onSubmit = async (data) => {
    const response = await axios.post(
      `${API_RECRUITMENT}/api/universal/account/integrate`,
      data,
      getConfig()
    );

    if (response.status === 201) {
      setIsConnected(true);
    }
  };

  const handleDisconnect = async () => {
    const response = await axios.delete(
      `${API_RECRUITMENT}/api/universal/account/remove`,
      getConfig()
    );

    if (response.status === 200) {
      setIsConnected(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isConnected && (
          <>
            <div className="integrations__card__row">
              <div className="integrations__card__item">
                <p className="integrations__card__item__label">
                  Account Number<span>*</span>
                </p>
                <input
                  type="text"
                  id="accountNumber"
                  placeholder="Enter Universal Account Number here"
                  {...register("accountNumber", { required: true })}
                  className="integrations__card__item__input"
                />
                {errors.accountNumber && (
                  <p className="integrations__card__item__error-message">
                    {errors.accountNumber.message}
                  </p>
                )}
              </div>

              <div className="integrations__card__item">
                <p className="integrations__card__item__label">
                  User Name<span>*</span>
                </p>
                <input
                  type="text"
                  id="userName"
                  placeholder="Enter Username here"
                  {...register("userName", { required: true })}
                  className="integrations__card__item__input"
                />
                {errors.userName && (
                  <p className="integrations__card__item__error-message">
                    {errors.userName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="integrations__card__row">
              <div className="integrations__card__item alignCenter">
                <div className="integrations__card__item__option">
                  <input
                    type="checkbox"
                    onChange={() => setIsForcePassword((prev) => !prev)}
                  />
                  <span>
                    Do you want to update default password forcefully?
                  </span>
                </div>
                <p className="integrations__card__item__note">
                  Note: By default We are taking password from Universal
                </p>
              </div>

              <div className="integrations__card__item">
                <p className="integrations__card__item__label">
                  Password<span>*</span>
                </p>
                <input
                  type="text"
                  id="password"
                  placeholder="Enter Updated Password here"
                  {...register("password", { required: true })}
                  className={`integrations__card__item__input ${
                    !isForcePassword ? "disable" : ""
                  }`}
                  disabled={!isForcePassword}
                />
                {errors.password && (
                  <p className="integrations__card__item__error-message">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
          </>
        )}

        <div className="integrations__card__integration-button">
          {!isConnected ? (
            <button
              type="submit"
              className="integrations__card__integration-button__button button--filled"
            >
              Connect
            </button>
          ) : (
            <button
              type="button"
              className="integrations__card__integration-button__button button--empty"
              onClick={() => {
                handleDisconnect();
              }}
            >
              Disconnect
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default UniversalCard;
