import React, { memo, useEffect, useState } from 'react'
import axios from 'axios'
import PermissionsTable from './PerimissionsTable/PermissionsTable'
import RolesTable from './RolesTable/RolesTable'
import AccessManagementHead from './AccessManagementHead'
import MembersTable from './MembersTable/MembersTable'
import { IPermissionData, IPermissionDataItem } from '../../../types/acess-tab/members-table'
import { getEnv } from "@urecruits/api"
import { useSelector, useDispatch } from "react-redux"
import { setPermissions } from '../../../store/rolesReducer'
import SmallLoader from '../../SmallLoader'
import { useHasPermission, AuthGuard, useSubscription, getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const packages={
  recruitment:['assessment'],
  assessment:['job-post','offer','drug','background']
}

//get current permission
const getCurrentPermission = (roleData: IPermissionData[], currentRole: string, filterQuery?: (va: any) => Array<IPermissionDataItem>): Array<IPermissionDataItem> => {
  const permissions = roleData.find(item => item.role === currentRole).permissions
  return filterQuery ? filterQuery(permissions) : permissions
}

//sort by asc/desc
const sortBy = (sortMethod, oldData, sortValue) => {
  return oldData.sort((a, b) => {
    const aValue = typeof a[sortValue] === 'string' ? a[sortValue].toLocaleLowerCase() : a[sortValue]
    const bValue = typeof b[sortValue] === 'string' ? b[sortValue].toLocaleLowerCase() : b[sortValue]

    if (sortMethod === 'asc') {
      return aValue > bValue ? 1 : -1
    }

    if (sortMethod === 'desc') {
      return aValue < bValue ? 1 : -1
    }

    return 0
  })
}

export const RolesContext = React.createContext<IPermissionData[]>([])

const AccessManagementTab = ({ setDisplayAccessPopup }) => {
  const { checkUserPermission } = useHasPermission()
  const {checkCurrentPackageType,subscriptionData} = useSubscription()
  const token: string = localStorage.getItem('token')
  const allUsersPermissionData = useSelector((state: any) => state.permissions.dbPermissions);
  const dispatch = useDispatch()
  const [dataRefetch, setDataRefetch] = useState('')
  const [loading, setLoading] = useState(false)

  const [activeRole, setActiveRole] = useState({ name: '', authId: '' })
  const [activeTab, setActiveTab] = useState('')
  const [sortedBy, setSortedBy] = useState({ method: 'asc', sortValue: '' })

  //data request
  useEffect(() => {
    (async () => {
      try {
        (checkUserPermission('company-admin') || checkUserPermission('team-members', 'view')) && prepareData()
      } catch (e) {
        console.error('Fetch error', e)
      }
    })()
  }, [dataRefetch])

  const { API_RECRUITMENT } = getEnv()
  //combine user pre-recruitment data/strapi user information
  const prepareData = async () => {
    setLoading(true)
    const response = await axios.get(`${API_RECRUITMENT}/api/company/iam`, 
      getConfig())
    const defaultCompanyRole = await axios.get(`${API_RECRUITMENT}/api/company/default/roles`, getConfig())
    const tmp = [];
    for (const item of defaultCompanyRole?.data) {
      const label = item.label?.split("_")[0]
      if (!label?.includes('Company Owner')){
        if (response.data?.length){
          const role =  response.data.find(i=>i.role.label?.includes(`${label}`))
          if(role){
            tmp.push({
              role: label,
              permissions: role.role.permissions,
              description: role.role.description,
              auth0_id: role.role.value,
              usersCount: role.count?.count,
              usersInfo: role.count?.rows,
              id: role.role.id
            })
          }else{
            tmp.push({
              role: label,
              permissions: [],
              description: item.description,
              auth0_id: item.value,
              usersCount: 0,
              usersInfo:null,
              id: item.id,
              isDefault:true
            })
          }
        }
      }
    }
    dispatch((setPermissions([...sortBy('asc', [...tmp], 'role')])))
    setLoading(false)
  }
  useEffect(() => {
    if (sortedBy.method) {
      dispatch(setPermissions([...sortBy(sortedBy.method, [...allUsersPermissionData], sortedBy.sortValue)]))
    }
  }, [sortedBy])

  const filteredQuery = (arr:any[])=>{
    if (checkCurrentPackageType('assessment')){
     return arr.filter(i => !packages['assessment'].includes(i.key))
    }else if(checkCurrentPackageType('recruitment')){
     return arr.filter(i => !packages['recruitment'].includes(i.key))
    }
    return arr
  }

  return (
    <AuthGuard module='team-members' permission={['view', 'edit']}>
      <div className="access-management">
        <AccessManagementHead
          activeTab={activeTab}
          activeRole={activeRole}
          setActiveRole={setActiveRole}
          setActiveTab={setActiveTab}
          setDisplayAccessPopup={setDisplayAccessPopup}
        />
        {loading &&
          <div className='access-management__loader'>
            <SmallLoader />
          </div>
        }
        {
          activeTab.length === 0 && !loading && checkUserPermission('team-members','view') && (
            <RolesTable
              allUsersPermissionData={allUsersPermissionData}
              setActiveRole={setActiveRole}
              activeRole={activeRole}
              setActiveTab={setActiveTab}
              setSortedBy={setSortedBy}
              sortedBy={sortedBy}
              setRefetch={setDataRefetch}
            />
          )
        }
        {
          !loading && activeTab === 'permissions' && (
            <PermissionsTable
              data={getCurrentPermission(allUsersPermissionData, activeRole.name, filteredQuery)}
              role={allUsersPermissionData.find(item => item.role === activeRole.name)?.auth0_id}
            />
          )
        }
        <RolesContext.Provider value={allUsersPermissionData}>
          {
            !loading && activeTab === 'members' && (
              <MembersTable
                activeRole={activeRole}
                setDataRefetch={setDataRefetch}
              />
            )
          }
        </RolesContext.Provider>
      </div>
    </AuthGuard>
  )
}

export default memo(AccessManagementTab)