import { useEffect, useState } from "react";
import * as monaco from "monaco-editor";
import { useSelector, useDispatch } from "react-redux";
//store
import { editorSelectors } from "../../store/editor/editor.selectors";
import { setEditor } from "../../store/editor/editor.actions";
//themes
import {
  lightTheme,
  darkTheme,
} from "../../components/code-editor/editorTheme";
import { footerSelectors } from "../../store/footer/footer.selectors";
import { languageMapping } from "./shared/get-languages";

const useInitEditor = () => {
  const [editorState, setEditorState] = useState(null);
  const dispatch = useDispatch();
  const { isReadOnly, settings } = useSelector(editorSelectors.getAllState);
  const { activeLanguage } = useSelector(footerSelectors.getAllState);

  let initEditor = (editorRef: any) => {
    const editor = monaco.editor.create(editorRef.current, {
    // fontFamily: "Roboto Mono",
    language: "java",
    theme: settings.colorScheme,
    fontSize: settings.fontSize.data,
    readOnly: false,
    quickSuggestions: settings.codeCompletion,
    wordBasedSuggestions: false,
    detectIndentation: false,
    minimap: {
      enabled: settings.minimap,
    },
    tabSize: settings.tabSpacing.data,
    autoClosingBrackets: settings.autoBrackers ? "always" : "never",
    scrollbar: {
      verticalScrollbarSize: 0,
    },
  });

  monaco.editor.defineTheme("light", lightTheme);
  monaco.editor.defineTheme("dark", darkTheme);

  monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: !settings.errorHighlightning,
    noSyntaxValidation: !settings.errorHighlightning,
  });

  dispatch(setEditor(editor));
  setEditorState(editor);
};

  useEffect(() => {
    editorState?.updateOptions({ readOnly: isReadOnly, theme : "dark"});
  }, [isReadOnly]);

  useEffect(() => {    
    if(activeLanguage !== null){
      return applyLanguageMappings(activeLanguage)
    }
  }, [activeLanguage]);


  /*
  * This is used for adding intelligence to the editor
  * As monaco editor supports by default intelligence for html css and js only
  * Here we added manual support for other languages
  */
  const applyLanguageMappings = (languages: Language) => {
      const monacoLanguage= languageMapping.find((lang)=>lang?.name ===languages?.name)
      if (editorState && monacoLanguage?.value) {
        monaco.editor.setModelLanguage(editorState.getModel(), monacoLanguage?.value);
        monaco.languages.registerCompletionItemProvider(monacoLanguage?.value, {
          provideCompletionItems: (model, position) => {
            var word = model.getWordUntilPosition(position);
            var range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn,
            };
            var suggestions = monacoLanguage?.suggestion?.map((data)=>{
             return {
                label: data?.label,
                kind: monaco.languages.CompletionItemKind.Method,
                insertText: data?.insertText,
                insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                range: range,
                documentation: data?.documentation,
              }
            })
            return { suggestions: suggestions && suggestions };
          },
        });
      }
  };

  return [initEditor];
};

export default useInitEditor;


interface Language {
  id: number;
  name: string;
}