import "../styles/main.scss";
import { store } from "../store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import ReportScreen from "../screen/ReportScreen";
import JobsReportScreen from "../screen/JobsReportScreen";
import MembersReportScreen from "../screen/MembersReportScreen";
import { permissionRoutesType } from "@ucrecruits/globalstyle/context/types";
import { NotFoundPageComponent, SmallLoaderComponent, UnAuthorizePageComponent, UpgradeSubscriptionComponent, useHasPermission, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";


const routes: Array<permissionRoutesType> = [
  {
    path: '/hr-analytics/reports',
    element: <ReportScreen/>,
    module:'dashboards-analytics-reporting',
    permissions:'view'
  },
  {
    path: '/hr-analytics/reports/job-report',
    element: <JobsReportScreen/>,
    module: 'dashboards-analytics-reporting',
    permissions: 'view'
  },
  {
    path: '/hr-analytics/reports/members-report',
    element: <MembersReportScreen />,
    module: 'dashboards-analytics-reporting',
    permissions: 'view'
  }
]
export default function Root() {
  const { onAuthorizedRoutes, companyId } = useHasPermission()
  const { isLoadingSubscription, hasCompanySubscriptionElement, checkCurrentPackageType,subscriptionData } = companyId && useSubscription();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {companyId &&
            routes.map(route => {
              const hasPackage = route.planPackages ? checkCurrentPackageType(route.planPackages) : true
              const isAuthorized = onAuthorizedRoutes(route.module, route.permissions, route?.option || "");
              const routeElement = !hasPackage ? <UpgradeSubscriptionComponent /> : isAuthorized ? route.element : <UnAuthorizePageComponent />;

              return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement(routeElement)} />;
            })}

          <Route path="/hr-analytics/*" element={companyId && isLoadingSubscription ? <SmallLoaderComponent /> : <NotFoundPageComponent />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}
