import React, { useState } from "react";
import { OfferLetterStatus } from "../../enums/usersOffers/UsersOffersEnums";
import { handleSendRequest } from "../../screen/OfferPreviewScreen";
import { setOpenOfferId } from "../../store/reducers/offerLetterReducer";
import { store, useTypedSelector } from "../../store";
const saveIcon = require("../../image/icon/save_ic.svg");
const getOpenOfferIdFunc = (state) => state.offer_letter.openOfferId;
const OfferAccordion = ({
  children,
  isOpen,
  offer,
  setModal,
  setSelectedOffer,
}) => {
  const openOfferId = useTypedSelector(getOpenOfferIdFunc);
  const isInActiveOffer =
    offer?.letterStatus === OfferLetterStatus.CLOSED ||
    offer?.letterStatus === OfferLetterStatus.REJECTED;

  const isActionDisplay = !(
    offer?.letterStatus === OfferLetterStatus.PENDING ||
    offer?.letterStatus === OfferLetterStatus.SENT ||
    offer?.letterStatus === OfferLetterStatus.CLOSED ||
    offer?.letterStatus === OfferLetterStatus.REJECTED
  );

  return (
    <div className="offer-accordion__item">
      <div
        className={`offer-accordion__top ${isOpen ? "active" : ""}`}
        onClick={() => {
          if (offer.id !== openOfferId) {
            store.dispatch(setOpenOfferId(offer.id));
          } else {
            store.dispatch(setOpenOfferId(null));
          }
        }}
      >
        <div className="offer-accordion__top__left">
          <p className="offer-accordion__item__title">
            Offer Letter: {offer.jobTitle}
            {isInActiveOffer && (
              <span className="offer-accordion__item__title__not-active">{` (not active)`}</span>
            )}
          </p>
        </div>

        <div className="offer-accordion__top__right">
          <div className="offer-accordion__item__actions">
            {isActionDisplay && (
              <button
                className="offer-accordion__item__actions__button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOffer(offer);
                  setModal("sendForApproval");
                }}
              >
                <SendForApprovalIcon />
                <p className="offer-accordion__item__actions__text">
                  Send For Approval
                </p>
              </button>
            )}
            {isActionDisplay && (
              <button
                className="offer-accordion__item__actions__button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOffer(offer);
                  setModal("saveAndSend");
                }}
              >
                <img src={saveIcon} alt="save" />
                <p className="offer-accordion__item__actions__text">
                  Save & Send
                </p>
              </button>
            )}

            {isInActiveOffer && (
              <button
                className="offer-accordion__item__actions__button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOffer(offer);
                  setModal("delete-offer");
                }}
              >
                <DeleteIcon />
                <p className="offer-accordion__item__actions__text">Delete</p>
              </button>
            )}
          </div>
          <ArrowSvg />
        </div>
      </div>

      <div className={`offer-accordion__item__body ${isOpen ? "open" : ""}`}>
        {children}
      </div>
    </div>
  );
};

const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OfferAccordion;

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5H4.16667H17.5"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66675 4.99996V3.33329C6.66675 2.89127 6.84234 2.46734 7.1549 2.15478C7.46746 1.84222 7.89139 1.66663 8.33342 1.66663H11.6667C12.1088 1.66663 12.5327 1.84222 12.8453 2.15478C13.1578 2.46734 13.3334 2.89127 13.3334 3.33329V4.99996M15.8334 4.99996V16.6666C15.8334 17.1087 15.6578 17.5326 15.3453 17.8451C15.0327 18.1577 14.6088 18.3333 14.1667 18.3333H5.83341C5.39139 18.3333 4.96746 18.1577 4.6549 17.8451C4.34234 17.5326 4.16675 17.1087 4.16675 16.6666V4.99996H15.8334Z"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33325 9.16663V14.1666"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 9.16663V14.1666"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SendForApprovalIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2668_39857)">
      <path
        d="M18.3327 1.6665L9.16602 10.8332"
        stroke="#099C73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3327 1.6665L12.4993 18.3332L9.16602 10.8332L1.66602 7.49984L18.3327 1.6665Z"
        stroke="#099C73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2668_39857">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
