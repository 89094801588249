import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import { IMembersDateOfJoiningItem, IMembersTable, IMembersTableItem } from "../../../types/redux/members";
import { MembersTab } from "../../../enums/members/membersEnums";
import { ITabsFilter } from "../../../types/global/global";

export const defaultFiltersMembers = {
	searchValue: '',
	sortBy: "recruiterId",
	sortType: 'asc',
	role: [],
	department: '',
	jobLocation: [],
	education: [],
	dateOfJoining: {
		from: null,
		to: null,
		duration: "All time" as IMembersDateOfJoiningItem['duration']
	},
	dateOfBirth: {
		from: null,
		to: null,
	}
}
const initialStateMTM: IMembersTable = {
	activeTab: 'ageProfile',
	isLoading:false,
	emptySearch: false,
	emptyTable: false,
	emptyFilter: false,
	filters: defaultFiltersMembers,
	tableItems: [],
	fixedTab: {
		id: 1,
		displayName: MembersTab.NAME,
		active: true,
		dbName: ''
	},
	tabFilter: [
		{
			id: 2,
			displayName: MembersTab.ID,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: MembersTab.AGE,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: MembersTab.DEPARTMENT,
			active: true,
			dbName: ''
		},
		{
			id: 5,
			displayName: MembersTab.Education,
			active: true,
			dbName: ''
		},
		{
			id: 6,
			displayName: MembersTab.ROLE,
			active: true,
			dbName: ''
		},
		{
			id: 7,
			displayName: MembersTab.EMAIL,
			active: false,
			dbName: ''
		},
		{
			id: 8,
			displayName: MembersTab.PHONE,
			active: false,
			dbName: ''
		},
		{
			id: 9,
			displayName: MembersTab.DATE_OF_JOINING,
			active: false,
			dbName: ''
		},
		{
			id: 10,
			displayName: MembersTab.DATE_OF_BIRTH,
			active: false,
			dbName: ''
		},
		{
			id: 12,
			displayName: MembersTab.STATUS,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
		afterKeyForNextPage: null
	},
	filterInfo: {
		roleList:[],
		tabs:
			[
				MembersTab.ROLE,
				MembersTab.DEPARTMENT,
				// MembersTab.Job_Location,
				MembersTab.Education,
				MembersTab.DATE_OF_JOINING,
				MembersTab.DATE_OF_BIRTH
			],
	}
}

export const members = createSlice({
	name: 'members',
	initialState: initialStateMTM,
	reducers: {
		setActiveTabMembers: (state, action: PayloadAction<string>) => {
			state.activeTab = action.payload
		},
		setIsLoadingMembers: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		},
		setTableItemsMembers: (state, action: PayloadAction<Array<IMembersTableItem>>) => {
			state.tableItems = action.payload
		},
		setSearchValueMembers: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderMembers: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setLimitMembers: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1
		},
		setSortByFilterMembers: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeFilterMembers: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setCurrentPageMembers: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setTotalCountMembers: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setEmptySearchMembers: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setEmptyTableMembers: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setEmptyFilterMembers: (state, action: PayloadAction<boolean>) => {
			state.emptyFilter = action.payload
		},
		setRoleFilterMembers: (state, action: PayloadAction<Array<number>>) => {
			state.filters.role = action.payload
		},
		setDepartmentFilterMembers: (state, action: PayloadAction<Array<any>>) => {
			state.filters.department = action.payload
		},
		setEducationFilterMembers: (state, action: PayloadAction<Array<any>>) => {
			state.filters.education = action.payload
		},
		setJobLocationFilterMembers: (state, action: PayloadAction<Array<any>>) => {
			state.filters.jobLocation = action.payload
		},
		setDateOfJoiningFilterMembers: (state, action: PayloadAction<IMembersDateOfJoiningItem>) => {
			state.filters.dateOfJoining = action.payload
		},
		setDateOfBirthFilterMembers: (state, action: PayloadAction<{ from: Date, to: Date }>) => {
			state.filters.dateOfBirth = action.payload
		},
		setRoleListFilterMembers: (state, action: PayloadAction<Array<any>>) => {
			state.filterInfo.roleList = action.payload
		},
		setAfterKeyForNextPageMembers: (state, action: PayloadAction<any>) => {
			state.pagination.afterKeyForNextPage = action.payload
		}
	},
})


export const {
	setActiveTabMembers,
	setEmptyTableMembers,
	setEmptySearchMembers,
	setTotalCountMembers,
	setCurrentPageMembers,
	setRoleFilterMembers,
	setSearchValueMembers,
	setOrderMembers,
	setLimitMembers,
	setSortTypeFilterMembers,
	setSortByFilterMembers,
	setTableItemsMembers,
	setDateOfBirthFilterMembers,
	setDateOfJoiningFilterMembers,
	setDepartmentFilterMembers,
	setEducationFilterMembers,
	setJobLocationFilterMembers,
	setEmptyFilterMembers,
	setRoleListFilterMembers,
	setIsLoadingMembers,
	setAfterKeyForNextPageMembers
} = members.actions

export default members.reducer