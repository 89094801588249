import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";
const fetchData = async (apiString) => {
    const res = await axios.get(apiString, getConfig())
    if (res && res.data) {
        return res.data
    }
}
export const postData=async (apiString,data)=>{
    const res = await axios.post(apiString,data, getConfig())
    if (res && res.data) {
        return res.data
    }
}
export const deleteData=async (apiString,data)=>{
    const res = await axios.delete(apiString, {...getConfig(),data:data})

    if (res && res.data) {
        return res.data
    }
}
export const patchData=async (apiString,data)=>{
    const res = await axios.patch(apiString, data,getConfig())
    if (res && res.data) {
        return res.data
    }
}
export default fetchData
