import { memo, useState } from "react";
import SingleItem from "../../../Global/table/SingleMobileItem";
import { CompanyJobsTableTabsEnums } from "../../../../enums/CompanyJobsTableEnums";
import { dayPublishConvert } from "../TBody/TbodyInnerJobs";
import applyIc from "../../../../image/icon/apply_ic.svg";
import axios from "axios";
import { store } from "../../../../store";
import { changeApplyPopupJobs, changeSuccessApplyPopupJobs } from "../../../../store/reducers/companyJobsReducer";
import { getEnv } from "@urecruits/api";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv();

const MobileTableItem = ({item}: { item: any }) => {
	const [saveStatus, setSaveStatus] = useState(item.subscribes ? !!item.subscribes.saveJob : false);
	const [openItem, setOpenItem] = useState(false);

	const onApplyHandler = (jobId: number) => {
		axios.post(`${API_RECRUITMENT}/api/subscribe`, {
			applyJob: true,
			jobId: jobId,
		}, getConfig()).then((res) => {
			if (res.status === 201) {
				store.dispatch(changeSuccessApplyPopupJobs(true));
			}
		});
	};

	const onSaveHandler = (jobId, status) => {
		axios.post(`${API_RECRUITMENT}/api/subscribe`, {
			saveJob: status,
			jobId: jobId,
		}, getConfig())
			.then(() => setSaveStatus(!saveStatus));
	};

	return (
		<div className="table-mobile__item">
			<div className={`table-mobile__top ${openItem ? "active" : ""}`} onClick={() => {
				setOpenItem(prev => !prev);
			}}>
				<div className="table-mobile__top__left">
					<p className="table-mobile__top__name">{item.title}</p>
				</div>
				<ArrowSvg/>
			</div>
			<div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
				<SingleItem title={CompanyJobsTableTabsEnums.JOB_LOCATION + ":"} text={item.locations.map((location, index) => {
					return ` ${location.state} - ${location.city}`.toString();
				}).toString()} textStyle={"table-mobile__item__text"}/>
				<SingleItem title={CompanyJobsTableTabsEnums.EXPERIENCE + ":"} text={`${item.experience[0]}-${item.experience[1]} years`} textStyle={"table-mobile__item__text"}/>
				<SingleItem title={CompanyJobsTableTabsEnums.SALARY_RANGE + ":"} text={`$${item.salaryMonth[0]} - $${item.salaryMonth[1]} PA`} textStyle={"table-mobile__item__text"}/>
				<SingleItem title={CompanyJobsTableTabsEnums.MAIN_SKILLS + ":"} text={item.skills.map((skill, index) => {
					return ` ${skill}`;
				}).toString()} textStyle={"table-mobile__item__text"}/>
				<SingleItem title={CompanyJobsTableTabsEnums.POSTED_ON + ":"} text={dayPublishConvert(item.postedOn) === 0 ? "today" : dayPublishConvert(item.postedOn) + " days ago"} textStyle={"table-mobile__item__text"}/>
				{/*//TODO: need to add save/apply logic*/}
				<button
					className={`table-mobile__item__action-button table-mobile__item__action-button--half ${saveStatus ? "active" : ""}`}
					onClick={() => onSaveHandler(item.id, !saveStatus)}
				>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.6663 18.3332L9.99967 13.7035L3.33301 18.3332V3.51836C3.33301 3.02721 3.53369 2.55619 3.8909 2.2089C4.24811 1.86161 4.7326 1.6665 5.23777 1.6665H14.7616C15.2668 1.6665 15.7512 1.86161 16.1084 2.2089C16.4657 2.55619 16.6663 3.02721 16.6663 3.51836V18.3332Z"
							stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					Save
				</button>
				{
					item.applicationForm[0] ? (
							<button
								className={"table-mobile__item__action-button table-mobile__item__action-button--half"}
								onClick={() => store.dispatch(changeApplyPopupJobs({
									visible: true,
									jobId: item.id,
									jobTitle: item.title,
								}))}
							>
								<img src={applyIc} alt="delete-icon"/>
								Apply
							</button>
						)
						: (
							<button
								className={"table-mobile__item__action-button table-mobile__item__action-button--half"}
								onClick={() => onApplyHandler(item.id)}
							>
								<img src={applyIc} alt="delete-icon"/>
								Apply
							</button>
						)
				}
			</div>
		</div>
	);
};
export default memo(MobileTableItem);

const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
			</clipPath>
		</defs>
	</svg>;
};