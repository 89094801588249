import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { existingDomainState } from "../store/existingDomain/existingDomain.selectors";
import {
  MoveQuestion,
  AddExistingQuestion,
  DeleteExistingQuestion,
  EditExistingQuestion,
} from "../store/existingDomain/existingDomain.actions";

import ExistingAnswer from "./ExistingAnswer";
import AddedQuestion from "./AddedQuestion";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const upIcon = require("./../images/dropDownSelected.svg");
const downIcon = require("./../images/dropDownIconCommon.svg");
const deleteIcon = require("./../images/deleteIcon.svg");
const editExistingQuestion = require("./../images/editExistingQuestion.svg");

const ExistingDomain = () => {
  const dispatch = useDispatch();
  const [openList, setOpenList] = useState([]);
  const [openAddedList, setOpenAddedList] = useState([]);
  const { data, addData } = useSelector(existingDomainState.getAllState);

  useEffect(() => {
    getAllId();
  }, [data]);

  const getAllId = () => {
    if (data && data.length) {
      const questionId = data.map((item) => {
        return {
          id: item.id,
          open: false,
        };
      });
      setOpenList(questionId);
    }

    if (addData && addData.length) {
      const questionId = addData.map((item) => {
        return {
          id: item.id,
          open: false,
        };
      });
      setOpenAddedList(questionId);
    }
  };

  const menuOpen = (id) => {
    setOpenList(
      openList.map((todo) =>
        todo.id === id ? { ...todo, open: !todo.open } : todo
      )
    );
  };

  const menuOpenList = (id) => {
    setOpenAddedList(
      openAddedList.map((todo) =>
        todo.id === id ? { ...todo, open: !todo.open } : todo
      )
    );
  };

  const addQuestion = (item) => {
    const updateList = item.questions.map((element) => ({
      ...element,
      isEditPoint: false,
    }));

    const objCopy = { ...item };
    objCopy.questions = updateList;
    objCopy.isEditQuestion = false;

    const data = [{ id: item.id }];
    const dataExistingQuestion = [{ item: objCopy }];

    dispatch(MoveQuestion(data));
    dispatch(AddExistingQuestion(dataExistingQuestion));
  };

  const handleDeleteQuestion = (id) => {
    const data = [{ id }];
    dispatch(DeleteExistingQuestion(data));
  };

  const handleEditQuestion = (indexAddData, id) => {
    const data = [{ indexAddData }];
    dispatch(EditExistingQuestion(data));
    menuOpenList(id);
  };

  return (
    <>
      {addData &&
        addData.map((item, indexAddData) => {
          const listId =
            openAddedList &&
            Array.isArray(openAddedList) &&
            openAddedList.find((list) => list.id === item.id);
          const isOpen = listId && listId.open;

          return (
            <Fragment key={item.id}>
              <div className="question__wrapper">
                <div
                  className="existing__wrapper"
                  onClick={() => menuOpenList(item.id)}
                >
                  {isOpen ? (
                    <img
                      className="question__icon selectors"
                      src={upIcon}
                      alt="down icon"
                    />
                  ) : (
                    <img
                      className="question__icon selectors"
                      src={downIcon}
                      alt="down icon"
                    />
                  )}
                  <span className="existing__text">{item.name}</span>
                </div>
                <div className="question">
                  <button
                    onClick={() => handleEditQuestion(indexAddData, item.id)}
                  >
                    <img
                      className="existing__add"
                      src={editExistingQuestion}
                      alt="edit question"
                    />
                  </button>
                  <button onClick={() => handleDeleteQuestion(item.id)}>
                    <img
                      className="existing__delete"
                      src={deleteIcon}
                      alt="delete question"
                    />
                  </button>
                </div>
              </div>
              <div className="radio__wrapper mgLeft ">
                {item.questions.map((list, index) => {
                  if (item.isEditQuestion) {
                    return (
                      <AddedQuestion
                        id={list.id}
                        indexAddData={indexAddData}
                        index={index}
                        key={index}
                        name={list.name}
                        score={list.score}
                        type={list.type}
                        isEditPoint={list.isEditPoint}
                        isOpen={isOpen}
                      />
                    );
                  } else {
                    return (
                      <ExistingAnswer
                        key={index}
                        item={list}
                        index={index}
                        isOpen={isOpen}
                      />
                    );
                  }
                })}
              </div>
            </Fragment>
          );
        })}
      <AuthGuard module="assessment" permission="view">
        {data && data?.length > 0 && (
          <h4 className="question__subtitle">Existing domain questions</h4>
        )}
        {data &&
          data.map((item) => {
            const listId =
              openList &&
              Array.isArray(openList) &&
              openList.find((list) => list.id === item.id);
            const isOpen = listId && listId.open;

            return (
              <Fragment key={item.id}>
                <div className="question__wrapper mg-bottom">
                  <div
                    className="existing__wrapper"
                    onClick={() => menuOpen(item.id)}
                  >
                    {isOpen ? (
                      <img
                        className="question__icon selectors"
                        src={upIcon}
                        alt="up icon"
                      />
                    ) : (
                      <img
                        className="question__icon selectors"
                        src={downIcon}
                        alt="down icon"
                      />
                    )}
                    <span className="existing__text">{item.name}</span>
                  </div>
                  <button
                    className="question__add"
                    onClick={() => addQuestion(item)}
                  >
                    Add
                  </button>
                </div>
                <div className="radio__wrapper mgLeft ">
                  {item.questions.map((list, index) => (
                    <ExistingAnswer
                      key={index}
                      item={list}
                      index={index}
                      isOpen={isOpen}
                    />
                  ))}
                </div>
              </Fragment>
            );
          })}
      </AuthGuard>
    </>
  );
};

export default ExistingDomain;
