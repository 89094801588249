export enum CodingAssessmentTableEnums{
	NAME_OF_THE_QUESTION='Name of the question',
	DESCRIPTION='Description',
	QUESTION_TYPE='Question Type',
	LANGUAGES='Languages',
	PACKAGES='Packages',
	UPDATED_ON='Last Updated on',
	CREATED_ON='Created on',
	STATUS='Status',
}
