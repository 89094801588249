import type { PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from "@reduxjs/toolkit";
import {
  IJobAreaArray,
  IJobDetailAreaView,
} from "../../types/redux/job-detail-view";

const initialStateMTM: IJobDetailAreaView = {
  jobStatus: "publish",
  jobsData: [],
  duration: "week",
};

export const jobsDetailAreaView = createSlice({
  name: "jobs_area",
  initialState: initialStateMTM,
  reducers: {
    setAreaJobs: (state, action: PayloadAction<IJobAreaArray>) => {
      state.jobsData = action.payload;
    },
    setAreaDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
    setAreaStatus: (state, action: PayloadAction<string>) => {
      state.jobStatus = action.payload;
    },
  },
});

export const { setAreaDuration, setAreaJobs, setAreaStatus } =
  jobsDetailAreaView.actions;

export default jobsDetailAreaView.reducer;
