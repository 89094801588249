import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CodeEditor from "../_elements/CodeEditor";
import firebase from "../../config/firebaseFirestore";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { fromMonaco, PlainTextOperation } from "@hackerrank/firepad";
import * as monaco from "monaco-editor";
import { SetCurrentUser, setMarker } from "../../store/editor/editor.actions";
import { useDispatch } from "react-redux";
import { setAllParticipants } from "../../store/video-meeting/assessment.actions";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

export const EditorPlayback = ({ editorContainerRef, isWidthChanged }) => {
  const [actionBTN, setActionBTN] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isPlayingRef = useRef(false);
  const firepadRef = useRef<ReturnType<typeof fromMonaco> | null>(null);
  const playbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const revisionsByTimestampRef = useRef({});
  const revisionByUserId = useRef({});
  const dispatch = useDispatch();

  const { collectionId } = useParams();
  
  const { editor, remoteCursorManager } = useSelector(editorSelectors.getAllState);
  const dbRef = firebase.database().ref(`${collectionId}`);

  useEffect(()=>{
    dbRef && dbRef.child('users').once("value")
    .then((snapshot) => {
      const userData=snapshot.val()
      const user=[]
      for (let key in userData) {
        user.push(userData[key]?.name)
      }
      userData && dispatch(setAllParticipants(user))
    })
  },[])

  const fetchRevisions = async (firebaseRef) => {
    return new Promise((resolve, reject) => {
      firebaseRef.child('history').once("value", function (snapshot) {
        const revisions = snapshot.val();
        const revisionsByTimestamp = {};
        let userId=[]
        for (let key in revisions) {          
          const revision = revisions[key];
          const operation = PlainTextOperation.fromJSON(revision.o);
          revisionsByTimestamp[revision.t] = operation;
          userId.push(revision?.a)
        }
        resolve({revisionsByTimestamp,userId});
      });
    });
  };

  const textForRevision = (revision, revisions) => {
    let document: any = new PlainTextOperation();
    const keys = Object.keys(revisions).sort();
    for (let key of keys) {
      const operation = revisions[key];
      document = document.compose(operation);
      if (key === revision) {
        break;
      }
    }
    return document._ops.length ? document.apply("") : null;
  };
  const calculateNewCursorPosition = (newText, currentPosition) => {
    const newPosition = currentPosition
      ? new monaco.Position(currentPosition.lineNumber, currentPosition.column + 1)
      : null;

    return newPosition;
  };
  const applyRevisionWithDelay = (index, revisions, userId) => {
    if (index < Object.keys(revisions).length && isPlayingRef.current) {
      const keys = Object.keys(revisions).sort();
      const timestamp = keys[index];
      const text = textForRevision(timestamp, revisions);
  
      dbRef.child('users').once("value")
        .then((snapshot) => {
          const userSnapshot = snapshot.val();
          if(userSnapshot){
            const currentUser=userSnapshot[userId[index]].name 
            dispatch(SetCurrentUser(currentUser))
          }
          const currentPosition = editor.getPosition();
          editor.setValue(text);
          const newPosition = calculateNewCursorPosition(text, currentPosition);
          if (newPosition) {
            editor.setPosition(newPosition);
            editor.revealPositionInCenter(newPosition);
            // editor.focus();
          }
  
          setCurrentIndex(index);
          playbackTimeoutRef.current = setTimeout(() => {
            applyRevisionWithDelay(index + 1, revisions, userId);
          }, 1);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          const currentPosition = editor.getPosition();
          editor.setValue(text);
          const newPosition = calculateNewCursorPosition(text, currentPosition);
          if (newPosition) {
            editor.setPosition(newPosition);
            editor.revealPositionInCenter(newPosition);
            editor.focus();
          }
  
          setCurrentIndex(index);
  
          playbackTimeoutRef.current = setTimeout(() => {
            applyRevisionWithDelay(index + 1, revisions, userId);
          }, 1);
        });
    } else {
      if(index === Object.keys(revisions).length) {
        setActionBTN("")
        stopPlayback();
        editor.setValue(null);
        setCurrentIndex(0);
      }
      isPlayingRef.current = false;
    }
  };
  

  const startPlayback = async () => {
    const revisionInfo:any = await fetchRevisions(dbRef);
    revisionsByTimestampRef.current = revisionInfo?.revisionsByTimestamp;
    revisionByUserId.current = revisionInfo?.userId;
    
    const keys = Object.keys(revisionInfo?.revisionsByTimestamp).sort();
    setIsStart(true);
    applyRevisionWithDelay(currentIndex, revisionInfo?.revisionsByTimestamp,revisionInfo?.userId);
  };

  const stopPlayback = () => {
    if (playbackTimeoutRef.current) {
      clearTimeout(playbackTimeoutRef.current);
    }
  };

  useEffect(() => {
    if (actionBTN === "start") {
      isPlayingRef.current = true;
      startPlayback();
    } else if (actionBTN === "pause") {
      if (playbackTimeoutRef.current) {
        clearTimeout(playbackTimeoutRef.current);
      }
      isPlayingRef.current = false;
    } else if (actionBTN === "play") {
      isPlayingRef.current = true;
      applyRevisionWithDelay(currentIndex, revisionsByTimestampRef.current,revisionByUserId?.current);
    } else if (actionBTN === "reset") {
      isPlayingRef.current = false;
      stopPlayback();
      editor.setValue(null);
      setCurrentIndex(0);
    } else if (actionBTN === "stop") {
      isPlayingRef.current = false;
      stopPlayback();
    }
  }, [actionBTN]);

  useEffect(() => {
    monaco?.editor?.onDidChangeMarkers(async ([uri]) => {
      const markers = monaco?.editor?.getModelMarkers({ resource: uri });
      dispatch(setMarker(markers));
    });
  }, [dispatch]);

  return (
    <AuthGuard module='assessment' permission='view'>
    <div className="editor">
      <div>
        <Buttons setActionBTN={setActionBTN} actionBTN={actionBTN} />
      </div>
      <CodeEditor editorRef={firepadRef} editorContainerRef={editorContainerRef} isWidthChanged={isWidthChanged} />
    </div>
    </AuthGuard>
  );
};

const Buttons = ({ setActionBTN, actionBTN }) => {
  const { currentUser } = useSelector(editorSelectors.getAllState);
  return (
    <div className="buttons__container">
      <button
        className="c-btn"
        onClick={() => {
          if (actionBTN === "") {
            setActionBTN("start");
          } else if (actionBTN === "start" || actionBTN === "play") {
            setActionBTN("pause");
          } else {
            setActionBTN("play");
          }
        }}
        disabled={actionBTN === "start" || actionBTN === "play"}
      >
        {actionBTN === "" ? "Start" : "Play"}
      </button>
      <button
        className="c-btn"
        onClick={() => {
          if (actionBTN === "stop") {
            setActionBTN("reset");
          } else {
            setActionBTN("stop");
          }
        }}
        disabled={actionBTN === ""}
      >
        {actionBTN === "stop" ? "Reset" : "Stop"}
      </button>
      { (actionBTN === "start" || actionBTN === "play") && currentUser && (
        <span className="typing">
          <span className="c-btn">{`${currentUser} is typing`}
          <span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span>
          </span>
         </span>
      )}
    </div>
  );
};

