import { store, useTypedSelector } from "../../../../store";
import { memo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { changeApplyPopupJobs, changeSuccessApplyPopupJobs } from "../../../../store/reducers/companyJobsReducer";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv();

const jobListFunc = state => state.jobs;

export const dayPublishConvert = (publishDay: string): number => {
	return Math.round((Date.now() - new Date(publishDay).valueOf()) / 86400000);
};

const token: string = localStorage.getItem("token");

const TbodyInnerJobs = ({horizontalScrollState}: { horizontalScrollState: boolean }) => {
	const table = useTypedSelector(jobListFunc);


	return (
		<tbody className="table__tbody">
		{
			table.companyJobs.map(item => {
				return <tr className="table__tr" key={item.id}>
					<td className={`table__td sticky ${horizontalScrollState ? "moved" : ""} companies__column__middle`}>
						<div className="companies__name">
							<Link to={`/job/${item.id}`} className="companies__name__text link">{item.title}</Link>
						</div>
					</td>
					{
						table.jobTabFilter.map(value => {
							if (value.id === 2) {
								return value.active &&
									<td className="table__td companies__column__default" key={value.displayName}>
										<p className="companies__text companies__text--gray5 with-dotted">{item.locations.map((location, index) => {
											return `${location.state}, ${location.city}${item.locations.length !== index + 1 ? ", " : ""}`;
										})}</p>
									</td>;
							}
						})
					}
					{
						table.jobTabFilter.map(value => {
							if (value.id === 3) {
								return value.active &&
									<td className="table__td companies__column__default" key={value.displayName}>
										<p className="companies__text companies__text--gray5">{`${item.experience[0]}-${item.experience[1]} years`}</p>
									</td>;
							}
						})
					}
					{
						table.jobTabFilter.map(value => {
							if (value.id === 4) {
								return value.active &&
									<td className="table__td companies__column__default" key={value.displayName}>
										<p className="companies__text companies__text--gray5">{`$${item.salaryMonth[0]} - $${item.salaryMonth[1]} PA`}</p>
									</td>;
							}
						})
					}
					{
						table.jobTabFilter.map(value => {
							if (value.id === 5) {
								return value.active &&
									<td className="table__td companies__column__default" key={value.displayName}>
										<p className="companies__text companies__text--gray5 with-dotted">{item.skills.map((skill, index) => {
											return `${skill}${item.skills.length !== index + 1 ? ", " : ""}`;
										})}</p>
									</td>;
							}
						})
					}
					{
						table.jobTabFilter.map(value => {
							if (value.id === 6) {
								return value.active &&
									<td className="table__td companies__column__default" key={value.displayName}>
										<p
											className="companies__text companies__text--gray5"> {dayPublishConvert(item.postedOn) === 0 ? "today" : dayPublishConvert(item.postedOn) + " days ago"}</p>
									</td>;
							}
						})
					}
					{
						table.jobTabFilter.map(value => {
							if (value.id === 7) {
								return value.active &&
									<td className="table__td companies__column__default" key={value.displayName}>
										<div className="companies__actions">
											<SaveAction item={item}/>
											<ApplyAction item={item}/>
										</div>
									</td>;
							}
						})
					}
				</tr>;
			})
		}
		</tbody>
	);
};

export default memo(TbodyInnerJobs);

const SaveAction = memo(({item}: { item: any }) => {
	const [saveStatus, setSaveStatus] = useState(item.subscribes ? !!item.subscribes.saveJob : false);

	const onSaveHandler = (jobId, status) => {
		axios.patch(`${API_RECRUITMENT}/api/subscribe/jobs/${jobId}`, {
			saveJob: status,
		}, getConfig())
			.then(() => setSaveStatus(!saveStatus));
	};

	return (
		<div className={`companies__actions__item save ${saveStatus ? "active" : ""}`}
		     onClick={() => onSaveHandler(item.id, !saveStatus)}>
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.6663 18.3332L9.99967 13.7035L3.33301 18.3332V3.51836C3.33301 3.02721 3.53369 2.55619 3.8909 2.2089C4.24811 1.86161 4.7326 1.6665 5.23777 1.6665H14.7616C15.2668 1.6665 15.7512 1.86161 16.1084 2.2089C16.4657 2.55619 16.6663 3.02721 16.6663 3.51836V18.3332Z"
				stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
		</div>
	);
});

const ApplyAction = memo(({item}: { item: any }) => {
	const [applyStatus, setApplyStatus] = useState(item.subscribes ? !!item.subscribes.applyJob : false);

	const onApplyHandler = (jobId: number) => {
		axios.post(`${API_RECRUITMENT}/api/subscribe`, {
			applyJob: true,
			jobId: jobId,
		}, getConfig()).then((res) => {
			if (res.status === 201) {
				store.dispatch(changeSuccessApplyPopupJobs(true));
				setApplyStatus(true);
			}
		});
	};

	return (
		<>
			{
				item.applicationForm[0] ? (
						<div
							className={`companies__actions__item  ${!applyStatus ? "" : "disabled"}`}
							onClick={() => store.dispatch(changeApplyPopupJobs({
								visible: true,
								jobId: item.id,
								jobTitle: item.title,
							}))}
						>
							<ApplyIcon/>
						</div>
					)
					: (
						<div
							className={`companies__actions__item  ${!applyStatus ? "" : "disabled"}`}
							onClick={() => onApplyHandler(item.id)}
						>
							<ApplyIcon/>
						</div>
					)
			}
		</>
	);
});

const ApplyIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3337 11V7.50114C18.3337 6.58066 17.5875 5.83447 16.667 5.83447H3.33366C2.41318 5.83447 1.66699 6.58066 1.66699 7.50114V15.8345C1.66699 16.7549 2.41318 17.5011 3.33366 17.5011H10.0003"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path
				d="M13.3337 8.5V4.16667C13.3337 3.72464 13.1581 3.30072 12.8455 2.98816C12.5329 2.67559 12.109 2.5 11.667 2.5H8.33366C7.89163 2.5 7.46771 2.67559 7.15515 2.98816C6.84259 3.30072 6.66699 3.72464 6.66699 4.16667V17.5"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<circle cx="14" cy="14" r="5.25" stroke="#099C73" strokeWidth="1.5"/>
			<path d="M14 12V16" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M16 14H12" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

