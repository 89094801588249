import { useCallback, useEffect, useState } from "react";
import DialogQuestion from "./tasks/DialogQuestion";
import { AuthGuard, ShowToolTipComponent, TableCardViewComp, useHasPermission, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import fetchData from "../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import { CodingAssessmentTableEnums } from "../enums/CodingAssessmentEnums";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCurrentPageCAT, setDeletePopupCAT, setIsLoadingCAT, setLanguageListFilterInfoCAT, setLanguagesFilterCAT, setLimitCAT, setQuestionTypeFilterCAT, setSearchValueCAT, setSortByFilterCAT, setSortTypeFilterCAT, setStatusFilterCAT, setTableItemsCAT, setTotalCountCAT } from "../store/coding-assessment/codingAssessmentsTableReducer";
import Dialog from "./Dialog";
import FiltersCodingAssessments from './CodingAssessmentsFilters';
import postData from "../hook/postData";
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv()
const DeleteSVG = require("./../images/delete.svg");
const EditSVG = require("./../images/pencil.svg");

const getLanguages = (languageSet, row) => {
  if (row?.assessmentType === 'live-task') {
    return languageSet.find(i => i.id === row.languageId)?.name
  } else if (row?.assessmentType === 'take-home') {
    if (!row?.questions?.length) return '';
    
    const languages = row.questions
      .map(que => languageSet.find(i => i.id === que.languageId)?.name)
      .filter(lan => !!lan);
    const uniqueLanguages = Array.from(new Set(languages));
    return uniqueLanguages.join(', ');
  }
}
const filterData = state => <FiltersCodingAssessments activeTab={state} />

export const CodingAssessments = (props) => {
  const { checkUserPermission } = useHasPermission();
  const [refetch, setRefetch] = useState("");
  const [packages, setPackages] = useState([])
  const [languageList, setLanguageList] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);
  const { checkCurrentPackageType } = useSubscription()
  const hasAssessmentViewPermission = checkUserPermission('assessment', 'view')
  const hasAssessmentPackage = checkCurrentPackageType(['assessment', 'fullcycle'])
  const table = useSelector(state => { return state["codingAssessmentTable"] })
  const dispatch = useDispatch()
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  const [value, setValue] = useState();
  const [dialog, setDialog] = useState({
    isLoading: false,
    liveTask: false,
  });

  const filterArray = [
    table.filters.searchValue,
    table.filters.sortBy,
    table.filters.sortType,
    table.pagination.limit,
  ];

  useEffect(() => {
    setAssessmentHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
  }, [...filterArray, table.pagination.currentPage, refetch])


  const handleDialogType = () => {
    setDialog(
      Object.assign({}, dialog, {
        isLoading: true,
      })
    );
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onDialog = (e) => {
    setDialog(
      Object.assign({}, dialog, {
        isLoading: false,
      })
    );
  };
  useEffect(() => {
    localStorage.setItem("prevRoute", "")
  }, [])

  useEffect(() => {
    fetchData(
      `${API_ASSESSMENT}/api/live-coding/packages`,
      setPackages
    ).then(() => {
    })
      .catch(error => {
        console.log("Error getting packages", error)
      })
  }, []);

  useEffect(() => {
    if (!languageList.length) {
      fetchData(`${API_ASSESSMENT}/api/languages`, setLanguageList)
        .then((res) => {
          return res.data
        })
        .then((res) => {
          setLanguageList(res);
          dispatch(setLanguageListFilterInfoCAT(res))
        })
        .catch(error => {
          console.log("Error getting languages", error)
        })
    }
  }, [languageList?.length]);

  // const handleBulkDelete = () => {

  //   if (dialog.id && choose === true) {
  //     const data = allData
  //       .map((item) => {
  //         return item.taskId === dialog.id
  //           ? {
  //             id: item.id,
  //             type: item.type === "Home Task" ? "take-home" : "live-task",
  //             status: item.status === "ACTIVE" ? "ACTIVE" : "DRAFT",
  //           }
  //           : null;
  //       })
  //       .filter((r) => r);
  //     postData(
  //       `${API_ASSESSMENT}/api/coding-assessment/delete`,
  //       data
  //     ).then(() => {
  //       setRefetch(new Date().toString());
  //       postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'decrease' }).then(data => { })
  //       const endOffset = +value + itemOffset;
  //       return setCurrentItems(allData.slice(itemOffset, endOffset));
  //     });
  //   } else if (choose === false) {
  //     return setDialog({ isLoading: false });
  //   }
  //   const endOffset = +value + itemOffset;
  //   return setCurrentItems(allData.slice(itemOffset, endOffset));
  // };

  const columns: Array<IColumns> = [
    {
      field: 'checkbox',
      headerName: "",
      visibility: true,
      pinnable: true,
      setActions: {
        handleClick: (rowIds) => {
          setSelectedRows(rowIds)
        },
        actions: [
          {
            getAction: (row) => {
              return {
                svg: <img src={DeleteSVG} alt="Delete Icon" />,
                title: 'Delete',
                onClick: () => {
                  dispatch(setDeletePopupCAT(true))
                  setRefetch("refetch")
                },
              }
            },

          },
        ]
      }
    },
    {
      field: 'name',
      headerName: CodingAssessmentTableEnums.NAME_OF_THE_QUESTION,
      visibility: true,
      pinnable: true,
      isMobileTitle: true,
      renderCell: (cellValue, rowData) => {
        return <p className="coding-assessment-table__item__text--green bold">{cellValue}</p>
      },
      mobileRenderCell: (cellValue, rowData) => {
        return <p className="coding-assessment-table__item__text--green">{cellValue}</p>
      }
    },
    {
      field: 'description',
      headerName: CodingAssessmentTableEnums.DESCRIPTION,
      visibility: true,
      renderCell: (cellValue, rowData) => {
        return <p className="coding-assessment-table__item__text">{cellValue}</p>
      },

    },
    {
      field: 'assessmentType',
      headerName: CodingAssessmentTableEnums.QUESTION_TYPE,
      visibility: true,
      renderCell: (cellValue, rowData) => {
        return cellValue == 'live-task' ? 'Live Task' : 'Take-Home';
      },
      mobileRenderCell: (cellValue, rowData) => {
        return cellValue == 'live-task' ? 'Live Task' : 'Take-Home';
      }
    },
    {
      field: 'languageId',
      headerName: CodingAssessmentTableEnums.LANGUAGES,
      visibility: true,
      renderCell: (cellValue, rowData) => {
        const language = getLanguages(languageList, rowData)
        return `${language}`;
      },
      mobileRenderCell: (cellValue, rowData) => {
        const language = getLanguages(languageList, rowData)
        return `${language}`;
      },
    },
    {
      field: 'updatedAt',
      headerName: CodingAssessmentTableEnums.UPDATED_ON,
      visibility: true,
      renderCell: (cellValue, rowData) => {
        return transformDate(cellValue);
      },
      mobileRenderCell: (cellValue, rowData) => {
        return transformDate(cellValue);
      }
    },
    {
      field: 'status',
      headerName: CodingAssessmentTableEnums.STATUS,
      visibility: true,
      renderCell: (cellValue, rowData) => {
        return <p
          className={`${cellValue === 'DRAFT' ?
            'table__status-block table__status-block--red manage-team__status' :
            'table__status-block table__status-block--green manage-team__status'}`}
        >{cellValue}</p>
      },
      mobileRenderCell: (cellValue, rowData) => {
        return <p
          className={`${cellValue === 'DRAFT' ?
            'table__status-block table__status-block--red manage-team__status' :
            'table__status-block table__status-block--green manage-team__status'}`}
        >{cellValue}</p>
      }
    },
    {
      field: 'actions',
      headerName: "Actions",
      visibility: true,
      setActions: [
        {
          getAction: (row) => {
            return {
              svg: <ShowToolTipComponent text='Edit' position="bottom"><img src={EditSVG} alt="Edit Icon" /></ShowToolTipComponent>,
              title: 'Edit',
              onClick: () => {
                if (row.assessmentType === 'live-task') {
                  window.location.href = `/coding-assessments/live-task/${row.taskId}`
                } else if (row.assessmentType === 'take-home') {
                  window.location.href = `/coding-assessments/take-home-task/${row.taskId}`
                }
              }
            }
          }
        },
        {
          getAction: (row) => {
            return {
              svg: <ShowToolTipComponent text='Delete' position="bottom"><img src={DeleteSVG} alt="Delete Icon" /></ShowToolTipComponent>,
              title: 'Delete',
              onClick: () => {
                setSelectedRows([row.id])
                dispatch(setDeletePopupCAT(true))
              }
            }
          }
        }
      ]
    }


  ]

  const resetFilters = (limit) => {
    dispatch(setSearchValueCAT(''))
    dispatch(setSortByFilterCAT("id"))
    dispatch(setSortTypeFilterCAT('asc'))
    dispatch(setQuestionTypeFilterCAT(""))
    dispatch(setStatusFilterCAT([]))
    dispatch(setLanguagesFilterCAT([]))
    setAssessmentHandler(1, limit, table.filters)
  }

  const emptyFilterCallback = useCallback(() => {
    return resetFilters(table.pagination.limit);
  }, []);

  const setAssessmentHandler = useCallback((page, limit, filters) => {
    dispatch(setCurrentPageCAT(page));
    async function getAssessments() {

      try {
        dispatch(setIsLoadingCAT(true))
        const assessments = await fetchData(`${API_ASSESSMENT}/api/coding-assessment/search?` +
          `type=${filters.questionType || ''}` +
          `&status=${filters.status?.length === 0 || filters.status?.length === 2 ? '' : filters.status[0]}` +
          `&sort=${filters.sortBy}&sortType=${filters.sortType}` +
          `&language=${filters.languages?.length ? filters.languages.join(',') : ''}` +
          `${filters.searchValue && "&search=" + filters.searchValue}`, () => { })
        if (assessments?.data?.length) {
          const data = assessments.data?.map((i, index) => ({ ...i, rowId: i.id, id: index + 1 }))
          const offset = (page - 1) * table.pagination.limit
          dispatch(setTotalCountCAT(data.length));
          dispatch(setTableItemsCAT([]));
          dispatch(setTableItemsCAT(data.slice(offset, offset + limit)));
          dispatch(setCurrentPageCAT(page));
        }
        dispatch(setIsLoadingCAT(false))

      } catch (err) {
        console.log("Error getting assessments", err)
        dispatch(setIsLoadingCAT(false))
      }
    }
    getAssessments().then()
  }, [...filterArray])

  const handleDelete = () => {
    if (selectedRows.length) {
      for (let i of selectedRows) {
        const row = table.tableItems.find(data => data.id === i)
        if (row) {
          const deleteData = {
            id: row.rowId,
            type: row.assessmentType,
            status: row.status,
          }
          postData(
            `${API_ASSESSMENT}/api/coding-assessment/delete`,
            deleteData
          ).then((data) => {
            setRefetch(new Date().toString());
            postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'decrease' }).then(data => { })
          })
          .catch(error => {
            console.log("Error deleting assessment", error)
          })
        }
      }
      setSelectedRows([])
      dispatch(setDeletePopupCAT(false))
    }
  }


  return (
    <>
      <AuthGuard module={'assessment'} permission='view'>
        <div className="table-screen-top">
          <div className="table-screen-top__wrapper">
            <h2>Coding Assessments</h2>
          </div>
          <AuthGuard module='assessment' permission='add'>
            <button className="button--filled table-screen-top__button" onClick={handleDialogType} >Add New</button>
          </AuthGuard>
        </div>
        <TableCardViewComp
          tableViewType={"table"}
          isLoading={table.isLoading}
          columns={columns}
          rowsData={{ rowData: table.tableItems }}
          pagination={{
            currentPage: table.pagination.currentPage,
            totalCount: table.pagination.totalCount,
            limit: table.pagination.limit,
            setCurrentPage: (page) => setAssessmentHandler(page, table.pagination.limit, table.filters),
            setLimit: (limit) => dispatch(setLimitCAT(limit)),
            setTotalCount: (count) => dispatch(setTotalCountCAT(count))
          }}
          searchField={{
            placeholder: "Search by name",
            searchValue: table.filters.searchValue,
            setSearchValue: (value) => dispatch(setSearchValueCAT(value))
          }}
          filters={{
            filter: table.filters,
            filterData: table.filterInfo,
            filterItems: filterData,
            resetFilters: emptyFilterCallback,
            isFilterSubmit: isFilterSubmit,
            setIsFilterSubmit: (value) => setIsFilterSubmit(value),
            isEmptyFilter: isEmptyFilter,
            setIsEmptyFilter: (value) => setIsEmptyFilter(value),
          }}
        />
        {dialog.isLoading && (
          <AuthGuard module='assessment' permission='add'>
            <DialogQuestion onChange={onChange} onDialog={onDialog} value={value} />
          </AuthGuard>
        )}
        {
          table.deletePopup && (
            <AuthGuard module='assessment' permission='delete'>
              <Dialog
                nameProduct={''}
                onCancel={() => dispatch(setDeletePopupCAT(false))}
                onDelete={() => handleDelete()}
                message={'Are you sure you want to delete selected coding assessment(s)?'}
              />
            </AuthGuard>
          )
        }
      </AuthGuard>
    </>
  );
};



export const transformDate = (date: string) => {
  if (!date) return ''
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString('en-US', { month: '2-digit' }) + '.' +
    dateObj.toLocaleDateString('en-US', { day: '2-digit' }) + '.' +
    dateObj.toLocaleDateString('en-US', { year: 'numeric' })

}