import { memo, useEffect, useState } from "react";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { selectCustomBottomStyle } from "../../../styles/selectCustomBottomStyle";
import { components, default as ReactSelect } from "react-select";
import DatePicker from "react-datepicker";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import plusIc from "../../../image/icon/plus_ic.svg";
import { validateEmail } from "../../../hook/validateEmail";
import { ICheckBoxList } from "../../../types/global/global";
import fetchData, { patchData, postData } from "../../../hook/http";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { educationOption } from "../../../utils/constants";
import { store, useTypedSelector } from "../../../store";
import { setUsersHandlerMTM } from "../../../screen/ManageTeamMembers";
import {
  getEnv,
  selectSearchFuncHRPosition,
  selectSearchFuncDepartment, selectSearchFuncLocationLimit
} from "@urecruits/api";
import AsyncSelect from "react-select/async";
import {
  AuthGuard,
  FilterWithLimitComponent,
  getConfig,
  GlobalConstants,
  ShowToolTipComponent,
  useSubscription,
  WarningBoxComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import {
  setCreateMemberPopupMTM,
  setEditMemberPopupMTM,
} from "../../../store/reducers/manageTeamMembersReducer";

const { phoneValidationLength } = GlobalConstants();

const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const getMTMTable = (state) => state.team_members;

const ManageTeamPopup = ({ title, ...props }) => {
  //subscription information
  const { subscriptionData } = useSubscription();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectionUrl = queryParams.get('redirection-url');
  
    
  //selectInfo
  const [departmentList, setDepartmentList] = useState<Array<ICheckBoxList>>(
    []
  );
  const [roleList, setRoleList] = useState<Array<ICheckBoxList>>([]);
  const [isRoleLoading, setIsRoleLoading] = useState(false);
  const [stateList, setStateList] = useState<Array<ICheckBoxList>>([]);
  const [countryList, setCountryList] = useState<Array<ICheckBoxList>>([]);
  const [managerList, setManagerList] = useState<Array<ICheckBoxList>>([]);
  const navigate = useNavigate();

  //values states
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [officeEmail, setOfficeEmail] = useState<string>("");
  const [officePhone, setOfficePhone] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<any>("");
  const [department, setDepartment] = useState<any>(null);
  const [jobLocation, setJobLocation] = useState<any>(null);
  const [education, setEducation] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<any>(null);
  const [birthday, setBirthday] = useState<any>(null);
  const [role, setRole] = useState<Array<ICheckBoxList>>([]);
  const [street, setStreet] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [manager, setManager] = useState<string>("");
  const [imageLink, setImageLink] = useState<string>("");

  //effects states
  const [scrollActive, setScrollActive] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [successSend, setSuccessSend] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState("");
  //errors states
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(undefined);
  const [jobTitleError, setJobTitleError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [jobLocationError, setJobLocationError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);

  const mtm_table = useTypedSelector(getMTMTable);

  const validation = () => {
    let result = true;
    if (firstName === "") {
      setFirstNameError(true);
      result = false;
    }
    if (lastName === "") {
      setLastNameError(true);
      result = false;
    }
    if (!validateEmail(officeEmail)) {
      setEmailError(true);
      result = false;
    }
    if (!officePhone) {
      setPhoneError('This filed is required');
      result = false;
    }else  if (!isValidPhoneNumber(officePhone)){
      setPhoneError('Please enter valid phone number');
      result = false;
    }
    if (!jobTitle) {
      setJobTitleError(true);
      result = false;
    }
    if (!department || department?.label === "") {
      setDepartmentError(true);
      result = false;
    }
    if (!jobLocation || jobLocation?.label === "") {
      setJobLocationError(true);
      result = false;
    }
    if (role.length === 0) {
      setRoleError(true);
      result = false;
    }
    return result;
  };
  const returnDefault = () => {
    setSomethingWentWrong("");
    setFirstName(props.initData.initialFirstName);
    setLastName(props.initData.initialLastName);
    setOfficeEmail(props.initData.initialOfficeEmail);
    setOfficePhone(props.initData.initialOfficePhone);
    setJobTitle(props.initData.initialJobTitle);
    setDepartment({
      label: props.initData.initialDepartment,
      value: props.initData.initialDepartment,
    });
    setJobLocation(props.initData.initialJobLocation);
    setEducation(props.initData.initialEducational);
    setDateOfJoining(
      props.initData.initialDateOfJoining
        ? new Date(props.initData.initialDateOfJoining)
        : null
    );
    setBirthday(
      props.initData.initialBirthday
        ? new Date(props.initData.initialBirthday)
        : null
    );
    setRole(props.initData.initialRole);
    setStreet(props.initData.initialStreet);
    setCity(props.initData.initialCity);
    setState(props.initData.initialState);
    setCountry(props.initData.initialCountry);
    setZip(props.initData.initialZip);
    setManager(props.initData.initialManager);
    setImageLink(props.initData.initialImageLink || "");
  };

  const handleRedirectionOnClose=()=>{
    if( redirectionUrl ||localStorage.getItem('prevRoute')){
      navigate(redirectionUrl || localStorage.getItem('prevRoute'))
      localStorage.removeItem('prevRoute')
    }else{
      navigate("/recruitment/manage-team-members")
    }
  }
  const submitHandler = async () => {
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setJobTitleError(false);
    setDepartmentError(false);
    setJobLocationError(false);
    setRoleError(false);

    if (validation()) {
      setIsLoading(true);
      try {
        if (title === "Edit Member") {
          await patchData(`${API_RECRUITMENT}/api/company/edit-member`, {
            firstname: firstName,
            lastname: lastName,
            email: officeEmail,
            officePhone: officePhone,
            positionId: jobTitle.id,
            department: department.label,
            locationId: jobLocation.value,
            degree: education,
            dateJoining: dateOfJoining,
            birthday: birthday,
            roles: role,
            currentStreet: street,
            currentCity: city,
            currentState: state,
            currentCountry: country,
            currentZip: zip,
            reportingId: parseInt(manager),
            avatar: imageLink,
            userId: props.initData.userId,
            recruiterId: props.initData.recruiterId,
          }).then(
            () => {
              if (somethingWentWrong !== "") setSomethingWentWrong("");
              setSuccessSend("Member information successfully changed");
              setUsersHandlerMTM(
                1,
                mtm_table.pagination.limit,
                mtm_table.filters
              );
              setTimeout(() => {
                if (props?.create) {
                  store.dispatch(setCreateMemberPopupMTM(false));
                }
                if (props?.edit) {
                  store.dispatch(
                    setEditMemberPopupMTM({ open: false, id: null })
                  );
                }
                handleRedirectionOnClose()
                setSuccessSend("");
              }, 1500);
            },
            (err) => {
              console.error(err);
              setSomethingWentWrong("Something went wrong");
            }
          );
        } else {
          await postData(`${API_RECRUITMENT}/api/company/add-member`, {
            firstname: firstName,
            lastname: lastName,
            email: officeEmail,
            phone: officePhone,
            positionId: jobTitle.id,
            department: department.label,
            locationId: jobLocation.value,
            degree: education,
            dateJoining: dateOfJoining,
            birthday: birthday,
            roles: role,
            currentStreet: street,
            currentCity: city,
            currentState: state,
            currentCountry: country,
            currentZip: zip,
            reportingId: parseInt(manager),
            avatar: imageLink,
          }).then(
            () => {
              postData(`${API_RECRUITMENT}/api/subscription/add-ons`, {
                addonName: "user",
                type: "increase",
              }).then((data) => {
                if (somethingWentWrong !== "") setSomethingWentWrong("");
                setSuccessSend("Member successfully added");
                setUsersHandlerMTM(
                  1,
                  mtm_table.pagination.limit,
                  mtm_table.filters
                );
                setTimeout(() => {
                  if (props?.create) {
                    store.dispatch(setCreateMemberPopupMTM(false));
                  }
                  if (props?.edit) {
                    store.dispatch(
                      setEditMemberPopupMTM({ open: false, id: null })
                    );
                  }
                  handleRedirectionOnClose()
                  setSuccessSend("");
                }, 1500);
              });
            },
            (err) => {
              console.error(err);
              setSomethingWentWrong(
                "Something went wrong or user already in team"
              );
            }
          );
        }
      } catch (error) {
        console.error(error, "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchCompanyRolesListData = async () => {
    try {
      return await fetchData(`${API_RECRUITMENT}/api/company/roles`);
    } catch (error) {
      console.error("Error fetching companyRolesListData:", error);
      return [];
    }
  };

  const addCompanyRole = async (roleName) => {
    setIsRoleLoading(true);
    try {
      await axios.post(
        `${API_RECRUITMENT}/api/company/role/create`,
        { roleName },
        getConfig()
      );

      const updatedRoleList = await fetchCompanyRolesListData();

      // Update the role list based on new data
      setRoleList((prevRoles) =>
        prevRoles.map((prevRole) => {
          const matchingUpdatedRole = updatedRoleList.find(
            (updatedRole) =>
              updatedRole.label?.split("_")?.[0] === prevRole.label
          );
          return matchingUpdatedRole
            ? {
                ...matchingUpdatedRole,
                label: matchingUpdatedRole.label?.split("_")?.[0],
                isDisabled: false,
              }
            : prevRole;
        })
      );
    } catch (error) {
      console.error("Error adding role to company:", error);
    } finally {
      setIsRoleLoading(false);
    }
  };

  useEffect(() => {
    const fetchAndProcessRoles = async () => {
      const companyRolesListData = await fetchCompanyRolesListData();
      const defaultRolesListData = await fetchData(
        `${API_RECRUITMENT}/api/company/default/roles`
      );

      // Combine data from both APIs
      const combinedRolesListData = defaultRolesListData
        .map((role) =>
          companyRolesListData.some(
            ({ label }) => label.split("_")?.[0] === role.label
          )
            ? { ...role, isDisabled: false }
            : { ...role, isDisabled: true, addCompanyRole }
        )
        .filter((item) => item.label !== "Company Owner");

      setRoleList(combinedRolesListData);
    };
    fetchAndProcessRoles();
  }, []);

  useEffect(() => {
    const getLists = async () => {
      const positionIndustryListData = await axios.get(
        "https://cms-dev.urecruits.com/industries-and-positions"
      );
      const usStateData = await axios.get(
        "https://urecruits-fonts.s3.amazonaws.com/states_titlecase.json"
      );
      const countryListData = await axios.get(
        "https://urecruits-fonts.s3.amazonaws.com/country_list.json"
      );
      const managersList = await fetchData(
        `${API_RECRUITMENT}/api/company/get-lead-users`
      );
      setManagerList(
        managersList.map((item) => {
          return {
            label: `${item.firstname} ${item.lastname}`,
            value: item.recruiter?.id,
            id: item.recruiter?.id,
          };
        })
      );

      setStateList(usStateData.data);
      setCountryList(countryListData.data);
      setDepartmentList(positionIndustryListData.data.Industries);
    };
    getLists();
  }, []);
  useEffect(() => {
    //setActiveButton
    if (
      firstName !== "" &&
      lastName !== "" &&
      officeEmail !== "" &&
      officePhone !== "" &&
      jobTitle !== "" &&
      department &&
      department?.label !== "" &&
      jobLocation &&
      jobLocation?.label !== "" &&
      role.length > 0
    ) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  }, [
    firstName,
    lastName,
    officeEmail,
    officePhone,
    jobTitle,
    department,
    jobLocation,
    role,
  ]);

  useEffect(() => {
    if (title === "Edit Member") returnDefault();
  }, [props.initData]);

  const fileValidate = (value) => {
    let result = true;
    if (value.size / 1024 > 5000) {
      setFileSizeError(true);
      result = false;
    }
    if (!(value.type === "image/jpeg" || value.type === "image/png")) {
      setFileTypeError(true);
      result = false;
    }
    return result;
  };
  const downloadImage = async (e) => {
    setFileSizeError(false);
    setFileTypeError(false);
    if (fileValidate(e.target.files[0])) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("upload", file);
      const { data } = await axios.post(
        `${API_RECRUITMENT}/api/file/public`,
        formData,
        getConfig()
      );
      setImageLink(data);
    }
  };

  return (
    (title !== "Edit Member" || props.initData) && (
      <AuthGuard module="team-members" permission={["view"]}>
        <AuthGuard module="team-members" permission={["add", "edit"]}>
          <div className="manage-team-popup">
            <div
              className="manage-team-popup__inner"
              onScroll={(e: any) => {
                if (e.target.scrollTop > 5 && !scrollActive) {
                  setScrollActive(true);
                } else if (e.target.scrollTop <= 5 && scrollActive) {
                  setScrollActive(false);
                }
              }}
            >
              <div
                className={
                  scrollActive
                    ? "manage-team-popup__top manage-team-popup__top--active"
                    : "manage-team-popup__top"
                }
              >
                <h5 className="manage-team-popup__top__title">{title}</h5>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="manage-team-popup__top__close-button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (props?.create) {
                      store.dispatch(setCreateMemberPopupMTM(false));
                    }
                    if (props?.edit) {
                      store.dispatch(
                        setEditMemberPopupMTM({ open: false, id: null })
                      );
                    }

                    if(title === "Edit Member"|| title === "Add New Member"){
                      handleRedirectionOnClose()
                    }
                    
                  }}
                >
                  <path
                    d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                    stroke="#C1C5CB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <form
                className="manage-team-popup__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitHandler();
                }}
              >
                <div
                  className="manage-team-popup__form__inner"
                  onScroll={(e: any) => {
                    if (e.target.scrollTop > 5 && !scrollActive) {
                      setScrollActive(true);
                    } else if (e.target.scrollTop <= 5 && scrollActive) {
                      setScrollActive(false);
                    }
                  }}
                >
                  <div className="manage-team-popup__form__item manage-team-popup__form__item--small">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="firstName"
                    >
                      First Name<span> *</span>
                    </label>
                    <input
                      className="manage-team-popup__form__input"
                      id="firstName"
                      autoComplete="do-not-autofill"
                      value={firstName}
                      placeholder="Enter first name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameError(false);
                      }}
                    />
                    {firstNameError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item manage-team-popup__form__item--small">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="lastName"
                    >
                      Last Name<span> *</span>
                    </label>
                    <input
                      className="manage-team-popup__form__input"
                      id="lastName"
                      autoComplete="do-not-autofill"
                      value={lastName}
                      placeholder="Enter last name"
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameError(false);
                      }}
                    />
                    {lastNameError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    {imageLink === "" ? (
                      <>
                        <label
                          className="manage-team-popup__form__more"
                          htmlFor="fileUpload"
                        >
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="fileUpload"
                            accept="image/*"
                            onChange={(e) => {
                              e.preventDefault();
                              downloadImage(e);
                            }}
                          />
                          <img
                            src={plusIc}
                            alt="plust icon"
                            className="manage-team-popup__form__more--icon"
                          />
                          <p className="manage-team-popup__form__more--text">
                            Upload a profile photo (optional)
                          </p>
                        </label>
                        <p className="manage-team-popup__form__error-message">
                          {fileSizeError
                            ? "File size must be less than 5 MB."
                            : ""}{" "}
                          {fileTypeError ? " Incorrect file type." : ""}
                        </p>
                      </>
                    ) : (
                      <div className="manage-team-popup__form__image-loaded">
                        <div className="manage-team-popup__form__image-wrap">
                          <img
                            src={imageLink}
                            className="manage-team-popup__form__image"
                            alt="avatar"
                          />
                          <p className="manage-team-popup__form__image-title">
                            {imageLink.slice(
                              imageLink.lastIndexOf("/") + 1,
                              imageLink.length
                            )}
                          </p>
                        </div>
                        <button
                          className="manage-team-popup__form__remove-image-button"
                          onClick={(e) => {
                            e.preventDefault();
                            setImageLink("");
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="email"
                    >
                      Office Email<span> *</span>
                    </label>
                    <input
                      className="manage-team-popup__form__input"
                      id="email"
                      autoComplete="do-not-autofill"
                      value={officeEmail}
                      placeholder="Enter email"
                      onChange={(e) => {
                        setOfficeEmail(e.target.value);
                        setEmailError(false);
                      }}
                    />
                    {emailError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="phone"
                    >
                      Office Phone<span> *</span>
                    </label>
                    <PhoneInput
                      international
                      defaultCountry="US"
                      id={"phone"}
                      value={officePhone}
                      className={"default"}
                      onChange={(value: any) => {
                        if (value) {
                          setOfficePhone(value);
                          !isValidPhoneNumber(value) 
                            ? setPhoneError("Please enter valid phone number")
                            : setPhoneError(undefined);
                        } else {
                          setOfficePhone("");
                          setPhoneError(true);
                        }
                      }}
                    />
                    {phoneError && (
                      <p className="manage-team-popup__form__error-message">
                       {phoneError}
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="jobTitleSelect"
                    >
                      HR Position<span> *</span>
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={(inputValue) =>
                        inputValue.length > 0
                          ? selectSearchFuncHRPosition(inputValue)
                          : selectSearchFuncHRPosition("")
                      }
                      hideSelectedOptions={false}
                      defaultOptions
                      value={jobTitle}
                      onChange={(option: any) => {
                        setJobTitle(option);
                        setJobTitleError(false);
                      }}
                      closeMenuOnSelect={true}
                      placeholder="Select HR Position"
                      styles={selectCustomStyle}
                      id="jobTitleSelect"
                      instanceId="jobTitleSelect"
                    />
                    {jobTitleError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="departmentSelect"
                    >
                      Department<span> *</span>
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={(inputValue) =>
                        inputValue.length > 0
                          ? selectSearchFuncDepartment(inputValue)
                          : selectSearchFuncDepartment("")
                      }
                      defaultOptions
                      value={department}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(option: any) => {
                        setDepartment(option);
                        setDepartmentError(false);
                      }}
                      placeholder="Select department"
                      styles={selectCustomStyle}
                      id="departmentSelect"
                      instanceId="departmentSelect"
                    />
                    {departmentError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="jobLocation"
                    >
                      Job Location<span> *</span>
                    </label>
                    <FilterWithLimitComponent
                      filter={{
                        value: jobLocation,
                        onChangeValue: (option: any) => {
                          setJobLocation(option);
                          setJobLocationError(false);
                        },
                      }}
                      isMulti={false}
                      placeHolder="Enter job location"
                      setLimitSearchfunction={selectSearchFuncLocationLimit}
                    />
                    {/* <AsyncSelect
							cacheOptions
							loadOptions={(inputValue) => inputValue.length > 0 ? selectSearchFuncLocation(inputValue) : selectSearchFuncLocation("")}
							defaultOptions
							value={jobLocation}
							onChange={(option: any) => {
								setJobLocation(option);
								setJobLocationError(false);
							}}
							placeholder="Enter job location"
							styles={selectCustomStyle}
						/> */}
                    {jobLocationError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="educational"
                    >
                      Education
                    </label>
                    <ReactSelect
                      options={educationOption}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(currentValue: any) => {
                        setEducation(currentValue.value);
                      }}
                      value={
                        educationOption.find((x) => x.value === education) || ""
                      }
                      placeholder={`Select education`}
                      styles={selectCustomStyle}
                      id="education"
                      instanceId="education"
                    />
                  </div>
                  <div className="manage-team-popup__form__item manage-team-popup__form__item--small">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="joined"
                    >
                      Date of Joining
                    </label>
                    <div className="manage-team-popup__form__date manage-team-popup__form__date--first">
                      <DatePicker
                        selected={dateOfJoining}
                        showYearDropdown={true}
                        scrollableYearDropdown={true}
                        yearDropdownItemNumber={70}
                        onChange={(date: any) => {
                          setDateOfJoining(date);
                        }}
                        dateFormat="MMMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Enter date of joining"
                      />
                    </div>
                  </div>
                  <div className="manage-team-popup__form__item manage-team-popup__form__item--small">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="birthday"
                    >
                      Birthday
                    </label>
                    <div className="manage-team-popup__form__date">
                      <DatePicker
                        selected={birthday}
                        showYearDropdown={true}
                        scrollableYearDropdown={true}
                        yearDropdownItemNumber={70}
                        onChange={(date: any) => {
                          setBirthday(date);
                        }}
                        dateFormat="MMMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Enter date of birth"
                      />
                    </div>
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="roleSelect"
                    >
                      Role<span> *</span>
                    </label>
                    <ReactSelect
                      options={roleList}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(item: any) => {
                        setRole(item);
                        setRoleError(false);
                      }}
                      isLoading={isRoleLoading}
                      getOptionLabel={(option) => option.label.split("_")[0]}
                      value={role}
                      isMulti
                      placeholder="Select role"
                      styles={selectCustomStyle}
                      id="roleSelect"
                      instanceId="roleSelect"
                      components={{
                        Option,
                      }}
                    />
                    {roleError && (
                      <p className="manage-team-popup__form__error-message">
                        This is required field
                      </p>
                    )}
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="street"
                    >
                      Street
                    </label>
                    <input
                      className="manage-team-popup__form__input"
                      id="street"
                      autoComplete="do-not-autofill"
                      value={street}
                      placeholder="Enter street"
                      onChange={(e) => setStreet(e.target.value)}
                    />
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="city"
                    >
                      City
                    </label>
                    <input
                      className="manage-team-popup__form__input"
                      id="city"
                      autoComplete="do-not-autofill"
                      value={city}
                      placeholder="Enter city"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="stateSelect"
                    >
                      State
                    </label>
                    <ReactSelect
                      options={stateList}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(item: any) => {
                        setState(item.value);
                      }}
                      value={stateList.find((x) => x.value === state)}
                      placeholder="Select state"
                      styles={selectCustomBottomStyle}
                      id="stateSelect"
                      instanceId="stateSelect"
                    />
                  </div>
                  <div className="manage-team-popup__form__item manage-team-popup__form__item--middle">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="countrySelect"
                    >
                      Country
                    </label>
                    <ReactSelect
                      options={countryList}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(item: any) => {
                        setCountry(item.value);
                      }}
                      value={countryList.find((x) => x.value === country)}
                      placeholder="Select country"
                      styles={selectCustomBottomStyle}
                      id="countrySelect"
                      instanceId="countrySelect"
                    />
                  </div>
                  <div className="manage-team-popup__form__item manage-team-popup__form__item--extra-small">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="zip"
                    >
                      Zip/Postcode
                    </label>
                    <input
                      className="manage-team-popup__form__input"
                      id="zip"
                      autoComplete="do-not-autofill"
                      value={zip}
                      placeholder="Ex: 123456"
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                  <div className="manage-team-popup__form__item">
                    <label
                      className="manage-team-popup__form__label"
                      htmlFor="manager"
                    >
                      Reporting Manager
                    </label>
                    <ReactSelect
                      options={managerList}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(item: any) => {
                        setManager(item.value);
                      }}
                      value={managerList.find((x) => x.value === manager)}
                      placeholder="Select reporting manager"
                      styles={selectCustomBottomStyle}
                      id="manager"
                      instanceId="manager"
                    />
                  </div>
                  {subscriptionData?.status !== "trialing" && (
                    <WarningBoxComponent
                      message="Alert: Exceeding the user creation limit will incur add-on charges based on your package."
                      className="manage-team-popup__warning"
                    />
                  )}
                  <div className="manage-team-popup__form__buttons">
                    {successSend !== "" && (
                      <p className="manage-team-popup__form__success-message">
                        {successSend}
                      </p>
                    )}
                    {somethingWentWrong !== "" && (
                      <p className="manage-team-popup__form__success-message manage-team-popup__form__success-message--error">
                        {somethingWentWrong}
                      </p>
                    )}
                    {(firstNameError ||
                      lastNameError ||
                      emailError ||
                      phoneError ||
                      jobTitleError ||
                      departmentError ||
                      jobLocationError ||
                      roleError) && (
                      <p className="manage-team-popup__form__success-message manage-team-popup__form__success-message--error">
                        You have some validation errors
                      </p>
                    )}
                    <button
                      disabled={isLoading}
                      className="manage-team-popup__form__cancel button--empty"
                      onClick={(e) => {
                        e.preventDefault();
                        if (props?.create) {
                          store.dispatch(setCreateMemberPopupMTM(false));
                        }
                        if (props?.edit) {
                          store.dispatch(
                            setEditMemberPopupMTM({ open: false, id: null })
                          );
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      className={
                        activeButton
                          ? "manage-team-popup__form__submit button--filled"
                          : "manage-team-popup__form__submit button--filled button--filled-disable"
                      }
                      type="submit"
                    >
                      {props?.create ? "Add" : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </AuthGuard>
      </AuthGuard>
    )
  );
};

const Option = (props: any) => {
  const { data, value, isSelected } = props;

  return (
    <div className="manage-team-popup__roleItem">
      <components.Option
        {...props}
        className="manage-team-popup__form__checkbox"
      >
        <input
          id={value}
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
        />
        <label htmlFor={value}>
          <span />
        </label>
        <p>{data.label}</p>
      </components.Option>
      {Boolean(data.isDisabled) && (
        <ShowToolTipComponent text="Include this role">
          <p
            className="manage-team-popup__add-role-link"
            onClick={(e) => {
              e.stopPropagation();
              data?.addCompanyRole(data.label);
            }}
          >
            Add
          </p>
        </ShowToolTipComponent>
      )}
    </div>
  );
};

export default memo(ManageTeamPopup);
