import { getEnv } from "@urecruits/api";
import { memo } from "react";
import { AssignmentCandidateFilterTabs } from "../../../enums/assignment-candidate/assignmentCandidate";
import { store, useTypedSelector } from "../../../store";
import Option from "../../Global/SelectOptions";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { setCandidateIdsAssignmentCandidate } from "../../../store/reducers/assignmentCandidateReducer";
import { useParams } from "react-router-dom";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv();

const getFiltersFunc = (state) => state.assignment_candidate.filters;
const FilterPopupAssignmentCandidate = ({ activeTab }) => {
  const filter = useTypedSelector(getFiltersFunc);
  const params = useParams()
  const getCandidates = async (inputValue) => {
    try {
      const response = await axios(`${API_RECRUITMENT}/api/subscribe/scoreboard/${params.jobId}?applyJob=true`, {
        ...getConfig(),
        params: {
          search: inputValue,
        },
      });

      const jobOptions = response?.data?.scoreboard?.rows?.map((candidate) => {
        return {
          value: candidate?.user?.id,
          label: `${candidate?.user?.firstname} ${candidate?.user?.lastname}`,
        }
      });

      return jobOptions || [];
    } catch (error) {
      console.error("Error fetching jobs:", error);
      return [];
    }
  };

  return (
    <>
      {activeTab === AssignmentCandidateFilterTabs.name && (
        <div className="table-filter-popup__right__content">
          <AsyncSelect
            isMulti
            components={{
              Option,
            }}
            cacheOptions
            loadOptions={(inputValue) =>
                getCandidates(inputValue).then((options) => options)
            }
            defaultOptions
            value={filter.candidateId}
            onChange={(option: any) => {
              store.dispatch(setCandidateIdsAssignmentCandidate(option));
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            placeholder="Search by candidate.."
            styles={selectCustomStyle}
            id="locationSelect"
            instanceId="locationSelect"
          />
        </div>
      )}
    </>
  );
};
export default memo(FilterPopupAssignmentCandidate);
