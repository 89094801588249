import { memo, useEffect, useRef, useState } from 'react';
import ExperienceDetailsTabItem from './ExperienceDetailsTabItem';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import patchData from "../../../hook/patchData";
import { getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const editIc = require('../../../image/icon/edit_ic.svg')
const plusIc = require('../../../image/icon/plus_ic.svg')

const ExperienceDetailsTab = ({ data, setRefetch, countryList, positionIndustryList,setData }) => {
  const token: string = localStorage.getItem('token')
  const [validateStatus, setValidateStatus] = useState(false)
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [addedMoreClicked, setAddedMoreClicked] = useState(false)
  const addedMoreButtonRef = useRef(null)
  const submitButtonRef = useRef(null)
  const {API_RECRUITMENT}=getEnv()
  //form state
  const [experienceDetails, setExperienceDetails] = useState([])

	useEffect(() => {
		if (data) {
			setExperienceDetails(data.experiences);
			setAddedMoreClicked(() => false);
      const isEditMode = data.experiences.some(item => item?.isResumeParsed);
      setEditMode(editMode ? editMode : isEditMode)
      setDisplayButton(editMode? editMode : isEditMode)
		}
	}, [data]);

  const onFormChange = () => {
    setDisplayButton(true)
  }
  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)

    setExperienceDetails(data.experiences)

    const experiences = data.experiences.filter(item => !item.isResumeParsed);
    setExperienceDetails(experiences);
    setData(oldData => {
      return {...oldData,experiences}
    })
  }

  function setDataOnSuccess(res, itemId) {
    if (res.status === 200 || res.status === 201) {
      setSuccess(true)
      setData(oldData => {
        let experiences = [...oldData?.experiences];
        oldData.experiences = experiences?.map(i => i.id === itemId ? { ...res.data } : i)
        return { ...oldData }
      });
    } else {
      setSuccess(false)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidateStatus(false)

    if (validateStatus) {
      setSuccess(true)
      //Need to added post
      for (const itemMain of experienceDetails) {
        const item = {...itemMain};
        if(!item?.isResumeParsed) {
          await patchData(`${API_RECRUITMENT}/api/candidate/experience`, item).then((res) => {
            setDataOnSuccess(res,item.id)
          })
        } else {
          const itemId = item.id;
          delete item.id;
          await axios.post(`${API_RECRUITMENT}/api/candidate/experience`, item, getConfig()).then((res) => {
            setDataOnSuccess(res,itemId)
          }).catch(err => {
            console.log(err)
          })
        }
      }

      setTimeout(() => {
        setSuccess(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }

  const onAddEducationHandler = async () => {

    if (editMode) {
      // empty object
      const obg = {
        companyName: "",
        position: "",
        location: "",
        start: null,
        end: null,
        candidateId: data.id,
        jobDescription: "",
        skills: [],
        present: false,
      }

      setValidateStatus(false)

      if (validateStatus && !addedMoreClicked || !experienceDetails?.length) {
        for (const item of experienceDetails) {
          await patchData(`${API_RECRUITMENT}/api/candidate/experience`, item).then((res) => {
          })
        }
        await axios.post(`${API_RECRUITMENT}/api/candidate/experience`, obg, getConfig()).then((res) => {
          setRefetch(new Date())

        }).catch(err => {
          console.log(err)
        })
      }
    }
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Experience Details {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        {
          experienceDetails.map((item, index) => {
            return (
              <ExperienceDetailsTabItem
                experienceDetails={experienceDetails}
                item={item}
                setDisplayButton={setDisplayButton}
                setExperienceDetails={setExperienceDetails}
                editMode={editMode}
                setData={setData}
                key={item.id}
                validateStatus={validateStatus}
                setValidateStatus={setValidateStatus}
                displayButton={displayButton}
                addedMoreButtonRef={addedMoreButtonRef}
                submitButtonRef={submitButtonRef}
                index={index}
                positionOption={positionIndustryList.Positions}
                countryList={countryList}
                setRefetch={setRefetch}
              />
            )
          })
        }
        {
          editMode ?
            <div className="profile__form__group ">
              <div
                className={`profile__form__more`}
                ref={addedMoreButtonRef}
                onClick={() => onAddEducationHandler()}
              >
                <img src={plusIc} alt="plust icon" className="profile__form__more--icon"/>
                <p className="profile__form__more--text">Add one more experience</p>
              </div>
            </div>
            : null
        }
        {
          displayButton && editMode ?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
                </button>
                <button className="profile__form__submit button--filled" ref={submitButtonRef}>Save</button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default memo(ExperienceDetailsTab)