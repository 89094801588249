import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import {
	getConfig,
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { useTypedSelector } from "../store";
import { store } from "../store/index";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import axios from "axios";
import MobileTable from "../components/Companies/Table/MobileTable/MobileTable";
import { getEnv } from "@urecruits/api";
import { sortingFunc } from "../utils/sortingFuncForTables";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import {
	defaultFiltersCompanies,
	setCompanies,
	setCurrentPageCompanies, setLimitCompanies, setNoCompaniesSearch,
	setSearchValueCompanies,
	setSortByFilterCompanies,
	setSortTypeFilterCompanies, setTableView, setTotalCountCompanies,
} from "../store/reducers/companiesReducer";
import { ICompanyUser } from "../types/redux/companies";
import TBodyInnerCompanies from "../components/Companies/Table/TBody/TBodyInnerCompanies";
import TableList from "../components/Companies/List/TableList";

import TableSVG from "../components/SVG/TableSVG";
import ListSVG from "../components/SVG/ListSVG";

const {API_RECRUITMENT} = getEnv();

const getTableCompanies = store => store.companies;

const Companies = () => {
	const table = useTypedSelector(getTableCompanies);

	const [horizontalScrollState, setHorizontalScrollState] = useState(false);

	const tableRef = useRef(null);

	useLayoutEffect(() => {
		setCompaniesHandler(table.pagination.currentPage, table.pagination.limit, table.filters);
	}, []);


	useTableClickAndDragScroll(tableRef);

	const depend = [table.filters.searchValue,  table.filters.sortType, table.filters.sortBy, table.pagination, table.limit, table.currentPage ]
	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeFilterCompanies, setSortByFilterCompanies, setCompaniesHandler), depend);

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueCompanies(value));
		setCompaniesHandler(1, table.pagination.limit, {...table.filters, searchValue: value});
	}, depend);

	return (
		<section className="companies">
			<div className="table-screen-top companies-top">
				<div className="table-screen-top__left">
					<h2>Companies</h2>
					<div className="table-screen-top__actions mobile">
						<div
							className={`table-screen-top__actions__item ${table.tableView  === 'table'? 'active': ''}`}
							onClick={() => store.dispatch(setTableView('table'))}
						>
							<TableSVG/>
						</div>
						<div
							className={`table-screen-top__actions__item ${table.tableView  === 'list'? 'active': ''}`}
							onClick={() => store.dispatch(setTableView('list'))}
						>
							<ListSVG/>
						</div>
					</div>
					{
						table.tableView === "list" && (
							<div className="table-screen-top__left__search">
								<SearchFieldComponent
									searchValue={table.filters.searchValue}
									setSearchValue={setSearchCallback}
									placeholder={"Search Companies"}/>
							</div>
						)
					}
				</div>
						<div className="table-screen-top__right">
							<div className="table-screen-top__actions">
								<div
									className={`table-screen-top__actions__item ${table.tableView  === 'table'? 'active': ''}`}
									onClick={() => store.dispatch(setTableView('table'))}
								>
									<TableSVG/>
								</div>
								<div
									className={`table-screen-top__actions__item ${table.tableView  === 'list'? 'active': ''}`}
									onClick={() => store.dispatch(setTableView('list'))}
								>
									<ListSVG/>
								</div>
							</div>
						</div>
			</div>
			{
				table.tableView === "table" && (
					<div className="table__wrapper">
						<div className="table__top">
							<div className="companies__top__left">
								<SearchFieldComponent
									searchValue={table.filters.searchValue}
									setSearchValue={setSearchCallback}
									placeholder={"Search Companies"}/>
							</div>
						</div>
						{
							table.noUsersSearch ?
								<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters}/>
								:
								<>
									<table className="table" ref={tableRef} onScroll={(e: any) => {
										if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true);
										else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false);
									}}>
										<thead className="table__thead">
										<td
											className={`table__td sticky ${horizontalScrollState ? "moved" : ""} table__td--thead companies__column__middle`}>
											<TheadItemComponent title={table.nameTab.displayName} dbName={"name"} handler={tdOrderCallback} />
										</td>
										{
											table.companiesTabsFilter.map((item) => {
												return item.active &&
													<td
														className={`table__td  table__td--thead companies__column__large`}
														key={item.id}>
														<TheadItemComponent
															title={item.displayName}
															handler={item.dbName ? tdOrderCallback : null}
															dbName={item.dbName}
														/>
													</td>;
											})
										}
										</thead>
										<TBodyInnerCompanies
											horizontalScrollState={horizontalScrollState}
										/>
									</table>
									<MobileTable/>
									<PaginationComponent
										limit={table.pagination.limit}
										currentPage={table.pagination.currentPage}
										totalCount={table.pagination.totalCount}
										setCurrentPage={setCompaniesHandler}
										setLimit={setLimitHandler}
										filters={table.filters}
									/>
								</>
						}
					</div>
				)
			}
			{
				table.tableView === "list" && (
					<>
						{
							table.noUsersSearch ?
								<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters}/>
								:
								<>
									<TableList/>
									<PaginationComponent
										limit={table.pagination.limit}
										currentPage={table.pagination.currentPage}
										totalCount={table.pagination.totalCount}
										setCurrentPage={setCompaniesHandler}
										setLimit={setLimitHandler}
										filters={table.filters}
									/>
								</>
						}
					</>
				)
			}
		</section>
	);
};

export default memo(Companies);

export const setCompaniesHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageCompanies(page));

	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/company/list-table?limit=${limit}&offset=${(page - 1) * limit}
		${filters.searchValue ? "&search=" + filters.searchValue : ""}
		${filters.sortBy ? "&sortBy=" + filters.sortBy : ""}
		${filters.sortType ? "&sortType=" + filters.sortType : ""}
		`, getConfig())
			.then((res) => {
				store.dispatch(setTotalCountCompanies(res.data.count));
				const companies = transformArrayToTableData(res.data.rows);
				store.dispatch(setCompanies(companies));
				store.dispatch(setNoCompaniesSearch(companies.length === 0));
			});
	};
	getData().then();
};

const setLimitHandler = (value: any) => {
	store.dispatch(setLimitCompanies(value));
};

const transformArrayToTableData = (array: any): Array<ICompanyUser> => {
	return array.map(item => {
		return {
			id: item.id,
			name: item.name,
			avatar: item.avatar,
			addresses: item.addresses || [{id: 0, country: '', city: ''}],
			industry: item.industry || {id: 0, label: '', value: ''},
			jobCount: item.jobCount,
			tenantId: item.tenantId,
			about: item.about
		};
	});
};

const resetFilters = (pagination) => {
	store.dispatch(setSortByFilterCompanies("id"));
	store.dispatch(setSortTypeFilterCompanies("ASC"));
	store.dispatch(setSearchValueCompanies(""));
	setCompaniesHandler(1, pagination, defaultFiltersCompanies);
};