import { ITableView } from "../../../types/table-types";
import TableView from "./TableView";
import TableCardList from "./TableCardList";
import { useTableCardView } from "../../../hook/useTableCardView";
import { memo } from "react";

const TableCardView = ({ tableViewType = 'table', columns, sort, refetchApi, emptyTable, store, useTypedSelector, storeName, searchField, pagination, disableRow, rearrangeColumns, tableListCard, filters ,rowsData,isLoading}: ITableView) => {
    const { rowData, emptyTableObj, loading } = useTableCardView({  refetchApi, pagination , searchField, filters, emptyTable,rowsData,isLoading});
    return (<>
        {
            tableViewType === "table" ? (
                <TableView loading={loading} rowData={rowData} columns={columns} sort={sort} emptyTable={emptyTableObj} disableRow={disableRow} store={store} useTypedSelector={useTypedSelector} storeName={storeName} searchField={searchField} pagination={pagination} rearrangeColumns={rearrangeColumns} filters={filters} />
            ) : ''
        }
        {
            tableViewType === "list" && tableListCard ? (
                <TableCardList loading={loading} rowData={rowData} columns={columns} emptyTable={emptyTableObj} noUsersSearch={false} disableRow={disableRow} searchField={searchField} pagination={pagination} tableListCard={tableListCard} filters={filters} />
            ) : ''
        }
    </>
    )
}
export default memo(TableCardView)
