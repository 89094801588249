import { combineReducers } from "redux";
import assessmentTaskReducer from "./assessmentTask/assessmentTask.reducer";
import liveTaskReducer from "./liveTask/liveTask.reducer";
import filterReducer from "./filter/filter.reducer";
import codingAssessmentTableReducer from './coding-assessment/codingAssessmentsTableReducer';
import * as types from "../types";
export interface RootState {
  assessmentTask: types.assessmentTask.State;
  filter: types.filter.State;
  liveTask: types.liveTask.State;
  codingAssessmentTable:types.codingAssessmentTable.ICodingAssessmentTable
}

export interface RootAction {
  type: "RESET_APP";
}

const appReducer = combineReducers<RootState>({
  assessmentTask: assessmentTaskReducer,
  filter: filterReducer,
  liveTask: liveTaskReducer,
  codingAssessmentTable: codingAssessmentTableReducer
});

const rootReducer = (
  state: RootState | undefined,
  action: RootAction
): RootState => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
