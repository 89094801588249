import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  AnalyticsCircleChartComponent,
  getConfig, TableCardViewComp,
  useHasPermission
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
// import useClickOutside from "../hook/useClickOutside";
import { store, useTypedSelector } from "../store";
import FiltersMembersAnalytics from '../components/Report/Members/Filters/FiltersMembersAnalytics';
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { setMembersAgePie } from "../store/reducers/members/MembersAgeBreakdownReducerPie";
import { defaultFiltersMembers, setActiveTabMembers, setCurrentPageMembers, setDateOfBirthFilterMembers, setDateOfJoiningFilterMembers, setDepartmentFilterMembers, setEducationFilterMembers, setEmptyFilterMembers, setEmptySearchMembers, setIsLoadingMembers, setJobLocationFilterMembers, setLimitMembers, setRoleFilterMembers, setRoleListFilterMembers, setSearchValueMembers, setSortByFilterMembers, setSortTypeFilterMembers, setTableItemsMembers, setTotalCountMembers } from "../store/reducers/members/membersTableReducer";
import { transformDate } from "../utils/transformDate";
import { MembersTab } from "../enums/members/membersEnums";
import fetchData from "../hook/fetchData";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import defaultAvatar from "../image/icon/avatar.svg";

const { API_RECRUITMENT, API_HR_ANALYTICS } = getEnv();

const TabColumns = [
  {
    activeTab: 'ageProfile',
    columns: [
      MembersTab.NAME,
      MembersTab.ID,
      MembersTab.AGE,
      MembersTab.DEPARTMENT,
      MembersTab.Education,
      MembersTab.ROLE,
      MembersTab.STATUS
    ]
  },
  {
    activeTab: 'birthdays',
    columns: [
      MembersTab.NAME,
      MembersTab.ID,
      MembersTab.EMAIL,
      MembersTab.PHONE,
      MembersTab.DATE_OF_BIRTH,
      MembersTab.Education,
    ]
  },
  {
    activeTab: 'memberStatus',
    columns: [
      MembersTab.NAME,
      MembersTab.ID,
      MembersTab.DEPARTMENT,
      MembersTab.Education,
      MembersTab.DATE_OF_JOINING,
      MembersTab.ROLE,
      MembersTab.STATUS
    ]
  }
]

const tableFunc = (state) => state.members;
const membersAgePieFunc = (state) => state.members_age_pie;
const FilterItems = (state) => <FiltersMembersAnalytics activeTab={state} />;

const columns: Array<IColumns> = [
  {
    field: "name",
    headerName: MembersTab.NAME,
    visibility: true,
    isMobileTitle: true,
    pinnable: true,
    renderCell: (cellValue, rowData) => {
      return <div className='manage-team__name'>
        <img src={rowData.avatar || defaultAvatar} alt='avatar' className='manage-team__name__avatar' />
        <p className='manage-team__name__text'>{cellValue || ''}</p>
      </div>
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <div className='manage-team__name'>
        <img src={rowData.avatar || defaultAvatar} alt='avatar' className='manage-team__name__avatar' />
        <p className='manage-team__name__text'>{cellValue || ''}</p>
      </div>
    }
  },
  {
    field: 'id',
    headerName: MembersTab.ID,
    visibility: true,
  },
  {
    field: 'age',
    headerName: MembersTab.AGE,
    visibility: true,
  },
  {
    field: 'email',
    headerName: MembersTab.EMAIL,
    visibility: false,
  },
  {
    field: 'phone',
    headerName: MembersTab.PHONE,
    visibility: false,
  },
  {
    field: 'department',
    headerName: MembersTab.DEPARTMENT,
    visibility: true,
  },
  {
    field: 'education',
    headerName: MembersTab.Education,
    visibility: true,
  },
  {
    field: 'dateOfBirth',
    headerName: MembersTab.DATE_OF_BIRTH,
    visibility: false,
  },
  {
    field: 'dateOfJoining',
    headerName: MembersTab.DATE_OF_JOINING,
    visibility: false,
  },
  
  {
    field: 'role',
    headerName: MembersTab.ROLE,
    visibility: true,
  },
  {
    field: 'status',
    headerName: MembersTab.STATUS,
    visibility: true,
    renderCell: (cellValue, rowsData) => {
      return <p
        className={`${cellValue === 'Pending' ?
          'table__status-block table__status-block--yellow manage-team__status' :
          'table__status-block table__status-block--green manage-team__status'}`}
      >{cellValue}</p>
    },
    mobileRenderCell: (cellValue, rowsData) => {
      return <p
        className={`${cellValue === 'Pending' ?
          'table__status-block table__status-block--yellow manage-team__status' :
          'table__status-block table__status-block--green manage-team__status'}`}
      >{cellValue}</p>
    }
  }
]
const MembersReportScreen = () => {
  const navigate = useNavigate();
  const {checkUserPermission} = useHasPermission()
  const [columnsArr,setColumnsArr]=useState(columns)
	const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  const tableRef = useRef(null);

  const table = useTypedSelector(tableFunc);
  const pie = useTypedSelector(membersAgePieFunc);


  const filterArray = [
    table.filters.searchValue,
    table.filters.sortBy,
    table.filters.sortType,
    table.pagination.limit,
  ];
  useLayoutEffect(() => {
    setMembersHandler(
      table.pagination.currentPage,
      table.pagination.limit,
      table.filters
    );
    setMembersAgePieHandler();
    setCompanyRolesList();
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeFunc);

    return () =>
      document.removeEventListener("visibilitychange", visibilityChangeFunc);
  }, [...filterArray, table.pagination.currentPage]);

  useEffect(() => {
    switch (table.activeTab) {
      case 'ageProfile':
        const newAgeProfileColumns = columnsArr.map(i => TabColumns[0].columns.includes(i.headerName as MembersTab) ? { ...i, visibility: true } : { ...i, visibility: false })
        setColumnsArr(newAgeProfileColumns)
        break;
      case 'birthdays':
        const newBirthdayColumns = columnsArr.map(i => TabColumns[1].columns.includes(i.headerName as MembersTab) ? { ...i, visibility: true } : { ...i, visibility: false })
        setColumnsArr(newBirthdayColumns)
        break;
      case 'memberStatus':
        const newMemberStatusColumns = columnsArr.map(i => TabColumns[2].columns.includes(i.headerName as MembersTab) ? { ...i, visibility: true } : { ...i, visibility: false })
        setColumnsArr(newMemberStatusColumns)
        break;
    }
  }, [table.activeTab])

  const visibilityChangeFunc = () => {
    if (document.visibilityState === "visible") {
      setMembersHandler(
        table.pagination.currentPage,
        table.pagination.limit,
        table.filters
      );
    }
  };

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValueMembers(value));
    setMembersHandler(1, table.pagination.limit, {
      ...table.filters,
      searchValue: value,
    });
  }, filterArray);

  const emptyFilterCallback = useCallback(() => {
    return resetFunc(table.pagination.limit);
  }, []);
  const emptyTableCallback = useCallback(() => {
    if(checkUserPermission('team-members','add')){
      navigate('/recruitment/manage-team-members/add-new')
      localStorage.setItem('prevRoute','/hr-analytics/reports/members-report')
    }else{
      navigate('/hr-analytics/reports')
    }
  }, []);

  useTableClickAndDragScroll(tableRef);

  return (
    <section className="members-report">
      <Outlet />

      <div className="hr-analytics__table-screen-top">
        <ul className="hr-analytics__table-screen-top__breadcrumbs">
          <li className="hr-analytics__table-screen-top__breadcrumbs__item">
            <Link
              to="/hr-analytics/reports"
              className="hr-analytics__table-screen-top__breadcrumbs__link"
            >
              Reports Center
            </Link>
          </li>
          <li className="hr-analytics__table-screen-top__breadcrumbs__item">
            <p className="hr-analytics__table-screen-top__breadcrumbs__link disabled">
              Members Report
            </p>
          </li>
        </ul>
        <div className="hr-analytics__table-screen-top__wrapper">
          <h2 className="hr-analytics__table-screen-top__title">Members Report</h2>
          <div className="members-report__buttons">
            <button className={`members-report__buttons__item ${table.activeTab == 'ageProfile' ? "active" : ""}`} onClick={() => store.dispatch(setActiveTabMembers('ageProfile'))} >
              Age Profile
            </button>
            <button className={`members-report__buttons__item ${table.activeTab == 'birthdays' ? "active" : ""}`} onClick={() => store.dispatch(setActiveTabMembers('birthdays'))} >
              Birthdays
            </button>
            <button className={`members-report__buttons__item ${table.activeTab == 'memberStatus' ? "active" : ""}`} onClick={() => store.dispatch(setActiveTabMembers('memberStatus'))} >
              Team Members status
            </button>
          </div>
        </div>
      </div>

      {table.activeTab == "ageProfile" && <div className="graph-wrapper">
        <div className="graph-member-summary-circle">
          <AnalyticsCircleChartComponent
            title={"Age Profile"}
            pieChartData={{
              data: pie.membersData,
              totalCount: pie.totalMembers
            }}
            countText={"Age Breakdown"}
            pieColors={['#029CA5', '#021CA5', '#737980', '#E9C715', '#FF8541', '#7D97F4', '#FE4672']}
            showPecentage
          />
        </div>
      </div>}

      <TableCardViewComp
        columns={columnsArr}
        rowsData={{ rowData: table.tableItems }}
        isLoading={table.isLoading}
        emptyTable={{
          buttonText:checkUserPermission('team-members','add')?'Add Member':'Go to report center',
          handler:emptyTableCallback,
          desc: 'Members table is empty'+`${checkUserPermission('team-members','add')?', Add your first member!':'.'}`,
          title: 'Table is empty'
        }}
        searchField={{
          placeholder: "Search team members",
          searchValue: table.filters.searchValue,
          setSearchValue: setSearchCallback,
        }}
        pagination={{
          currentPage: table.pagination.currentPage,
          limit: table.pagination.limit,
          totalCount: table.pagination.totalCount,
          setCurrentPage: (page) => setMembersHandler(page, table.pagination.limit, table.filters),
          setLimit: setLimitHandler,
          setTotalCount(count) {
            store.dispatch(setTotalCountMembers(count));
          },
        }}
        filters={{
          filter: table.filters,
          filterData: table.filterInfo,
          filterItems: FilterItems,
          isEmptyFilter: isEmptyFilter,
          setIsEmptyFilter: setIsEmptyFilter,
          resetFilters: emptyFilterCallback,
          isFilterSubmit: isFilterSubmit,
          setIsFilterSubmit: setIsFilterSubmit,
        }}

      />
    </section>
  );
};
export default memo(MembersReportScreen);

export const setMembersHandler = (page: number, limit: number, filters: any) => {
  store.dispatch(setCurrentPageMembers(page));
  const getData = async () => {
    const pagination = store.getState().members.pagination;
    store.dispatch(setIsLoadingMembers(true));
    try {
      const url = `${API_HR_ANALYTICS}/api/analytics/users?` +
        `limit=${encodeURIComponent(pagination.limit || 10)}` +
        `&offset=${encodeURIComponent((page - 1) * pagination.limit || 0)}` +
        `&search=${encodeURIComponent(filters.searchValue || '')}` +
        `${filters.department?.length ? `&department=${encodeURIComponent(filters.department?.map(i => i.label).join(','))}` : ''}` +
        `${filters.jobLocation?.length ? `&jobLocation=${encodeURIComponent(filters.jobLocation?.map(i => i.value).join(','))}` : ''}` +
        `${filters.dateOfBirth?.from ? `&dateOfBirthFrom=${filters.dateOfBirth.from}` : ''}` +
        `${filters.dateOfBirth?.to ? `&dateOfBirthTo=${filters.dateOfBirth.to}` : ''}` +
        `${filters.dateOfJoining?.from ? `&dateOfJoiningFrom=${filters.dateOfJoining.from}` : ''}` +
        `${filters.dateOfJoining?.to ? `&dateOfJoiningTo=${filters.dateOfJoining.to}` : ''}` +
        `${filters.dateOfJoining?.duration ? `&dateOfJoiningDuration=${filters.dateOfJoining.duration}` : ''}` +
        `${filters.education?.length ? `&education=${filters.education?.map(i => i.label).join(',')}` : ''}` +
        `${filters.role?.length ? `&roles=${filters.role.join(',')}` : ''}` +
        `&sortType=${encodeURIComponent(filters.sortType || 'asc')}` +
        `&sortBy=${encodeURIComponent(filters.sortBy || 'recruiterId')}`;
      await axios(url, getConfig())
        .then((res) => {
          const items = transformArrayToTableData(res.data.data.buckets);
          const offset= (page - 1) * pagination.limit
          store.dispatch(setTotalCountMembers(res.data.totalMembers?.value));
          store.dispatch(setTableItemsMembers(items.slice(offset,offset+pagination.limit)));
          store.dispatch(setCurrentPageMembers(page));
        });
      store.dispatch(setIsLoadingMembers(false));
    } catch (error) {
      console.log("Error fetching members details", error)
      store.dispatch(setIsLoadingMembers(false));
    }
  };
  getData().then();
};

export const setMembersAgePieHandler = () => {
  const getData = async () => {
    try {
      await axios(
        `${API_HR_ANALYTICS}/api/analytics/users/pie`,
        getConfig()
      ).then((res) => {
        const buckets = res.data?.age_ranges?.buckets;
        const count = buckets?.reduce((acc, item) => acc + item.distinct_user_count?.value, 0);
        const data = buckets?.map((item) => ({
          key: item.key,
          doc_count: item.distinct_user_count?.value,
        }))
        if (count === 0) {
          data.push({ key: "No data", doc_count: 1 })
        }

        store.dispatch(
          setMembersAgePie({
            membersData: data,
            totalMembers: count,
          })
        );
      });
    } catch (error) {
      console.log("Error getting member's pie data", error);
    }
  };
  getData().then();
};

export function toJSONLocalPlusDay(date: any) {
  let local = new Date(date);
  local.setDate(local.getDate() + 1);

  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

const setLimitHandler = (value: any) => {
  store.dispatch(setLimitMembers(value));
};
const transformArrayToTableData = (array: any) => {
  //TODO add missed fields
  return array.map((itemData) => {
    const member = itemData.latest_record?.hits?.hits[0]?._source;
    if (member === undefined) return;
    return {
      id: member.recruiterId,
      name: member.name ? member.name?.firstname + " " + member.name.lastName : "",
      avatar: member.avatar,
      age: member.birthDate ? new Date().getFullYear() - new Date(member.birthDate).getFullYear() : "",
      department: member.department,
      role: member.role?.length ? member.role?.map((role) => role.name?.split('_')[0]).join(", ")||"" : "",
      education: member?.degree,
      email: member.email,
      phone: member.phone,
      dateOfJoining: transformDate(member.dateJoining),
      dateOfBirth: transformDate(member.birthDate),
      status: member.profileStatus,
      createdAt: transformDate(itemData.createdAt),
    };
  }).filter((item) => item !== undefined);
};
const setCompanyRolesList = () => {
  const getFiltersData = async () => {
    const rolesListData = await fetchData(`${API_RECRUITMENT}/api/company/roles`)
    if (rolesListData.length !== 0) {

      store.dispatch(setRoleListFilterMembers(rolesListData.map(item => {
        return {
          role: item.label.split("_")?.[0],
          id: item.id
        }
      }).filter(x => x.role !== 'Company Owner')))
    }
  }
  getFiltersData().then()
}
const resetFunc = (pagination) => {
  store.dispatch(setSearchValueMembers(""));
  store.dispatch(setEmptyFilterMembers(false));
  store.dispatch(setEmptySearchMembers(false));
  store.dispatch(setRoleFilterMembers([]))
  store.dispatch(setSortTypeFilterMembers("asc"));
  store.dispatch(setSortByFilterMembers("recruiterId"));
  store.dispatch(setDateOfBirthFilterMembers({ from: null, to: null }));
  store.dispatch(setDateOfJoiningFilterMembers({ from: null, to: null, duration: 'All time' }));
  store.dispatch(setDepartmentFilterMembers([]))
  store.dispatch(setEducationFilterMembers([]))
  store.dispatch(setJobLocationFilterMembers([]))
  setMembersHandler(1, pagination, defaultFiltersMembers);
};
