import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import { ICodingAssessmentTable } from '../../types/coding-assessment-table-types';
import { CodingAssessmentTableEnums } from '../../enums/CodingAssessmentEnums';
export const defaultFiltersCAT = {
	searchValue: '',
	sortBy: "id",
	sortType: 'asc',
	languages: [],
	questionType:'',
	createdOn: {
		from: null,
		to: null,
	},
	status:[]
}
const initialStateCAT: ICodingAssessmentTable = {
	isLoading:false,
	filters: defaultFiltersCAT,
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
	tableItems:[],
	filterInfo: {
		languageList:[],
		tabs:
			[
				CodingAssessmentTableEnums.STATUS,
				CodingAssessmentTableEnums.QUESTION_TYPE,
				CodingAssessmentTableEnums.LANGUAGES,
				// CodingAssessmentTableEnums.UPDATED_ON
			],
	},
	deletePopup:false
}

export const codingAssessments = createSlice({
	name: 'coding-assessments',
	initialState: initialStateCAT,
	reducers: {
		setSearchValueCAT: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setIsLoadingCAT: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		},
		setDeletePopupCAT: (state, action: PayloadAction<boolean>) => {
			state.deletePopup = action.payload
		},
		setLimitCAT: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload;
				state.pagination.currentPage = 1
		},
		setSortByFilterCAT: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeFilterCAT: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setCurrentPageCAT: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setTotalCountCAT: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setQuestionTypeFilterCAT: (state, action: PayloadAction<string>) => {
			state.filters.questionType = action.payload
		},
		setLanguagesFilterCAT: (state, action: PayloadAction<Array<number>>) => {
			state.filters.languages = action.payload
		},
		setCreatedOnFilterCAT: (state, action: PayloadAction<{from:Date|null,to:Date|null}>) => {
			state.filters.createdOn = action.payload
		},
		setStatusFilterCAT: (state, action: PayloadAction<Array<string>>) => {
			state.filters.status = action.payload
		},
		setLanguageListFilterInfoCAT: (state, action: PayloadAction<Array<any>>) => {
			state.filterInfo.languageList = action.payload
		},
		setTableItemsCAT: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
	},
})


export const {
	setTotalCountCAT,
	setCurrentPageCAT,
	setLimitCAT,
	setSortTypeFilterCAT,
	setSortByFilterCAT,
	setCreatedOnFilterCAT,
	setLanguagesFilterCAT,
	setQuestionTypeFilterCAT,
	setSearchValueCAT,
	setDeletePopupCAT,
	setTableItemsCAT,
	setIsLoadingCAT,
	setStatusFilterCAT,
	setLanguageListFilterInfoCAT
} = codingAssessments.actions

export default codingAssessments.reducer