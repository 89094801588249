import type { PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from "@reduxjs/toolkit";
import { IJobDetailPieView } from "../../types/redux/job-detail-view";

const initialStateMTM: IJobDetailPieView = {
  totalJobs: 0,
  jobsData: [],
  duration: "week",
};

export const jobsDetailPieView = createSlice({
  name: "jobs_pie",
  initialState: initialStateMTM,
  reducers: {
    setPieJobs: (
      state,
      action: PayloadAction<Omit<IJobDetailPieView, "duration">>
    ) => {
      state.totalJobs = action.payload.totalJobs;
      state.jobsData = action.payload.jobsData;
    },
    setPieDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
  },
});

export const { setPieDuration, setPieJobs } = jobsDetailPieView.actions;

export default jobsDetailPieView.reducer;
