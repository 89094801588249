import { useState, useEffect } from "react";
import CodingSelect from "../_elements/CodingSelect";
import { setModal } from "../../store/editor/editor.actions";
import { encode } from "base-64";
import {
  setLanguage,
  setPackage,
  setConsoleWarningOpen,
  setConsoleWarning,
  runCodeState,
  isTerminalLoading,
  setError,
  setSubmissionResults
} from "../../store/footer/footer.actions";
import { CodingModalsEnum } from "../../types/editor";
import { CodingConsoleEnun } from "../../types/footer";
import { useDispatch, useSelector } from "react-redux";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import { footerSelectors } from "../../store/footer/footer.selectors";
import { editorSelectors } from "../../store/editor/editor.selectors";
import Warnings from "../_elements/Warnings";
import axios from "axios";
import fetchData from "../../hooks/api/fetchData";
import { getEnv } from "@urecruits/api";
import { CodeExecutor } from "../../hooks/editor/code_executor";
import { postData } from "../../hooks/api/postData";
import { useRunTestCases } from "../../hooks/editor/use-run-testcases";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT } = getEnv();

const consoleIcon = require("../../assets/icons/consoleIcon.svg");
const settingsIcon = require("../../assets/icons/settingsIcon.svg");
const reloadIcon = require("../../assets/icons/reloadIcon.svg");

//icon error monaco
const consoleErrorIcon = require("../../assets/icons/consoleErrorIcon.svg");
const consoleWarnBlueIcon = require("../../assets/icons/consoleWarnBlueIcon.svg");
const consoleWarnGrayIcon = require("../../assets/icons/consoleWarnGrayIcon.svg");
const consoleWarnYellowIcon = require("../../assets/icons/consoleWarnYellowIcon.svg");

//icon arrow monaco
const consoleArrowRed = require("../../assets/icons/consoleArrowRed.svg");
const consoleArrowBlue = require("../../assets/icons/consoleArrowBlue.svg");
const consoleArrowGray = require("../../assets/icons/consoleArrowGray.svg");
const consoleArrowYellow = require("../../assets/icons/consoleArrowYellow.svg");

const packageOptions = [
  { id: 1, name: "sqlite-jdbc-3.27.2.1", label: "SQLite" },
];

const consoleWarningOptions = [
  { id: 1, name: "All" },
  { id: 3, name: "Errors" },
  { id: 2, name: "Warning" },
];

export const EditorFooter = ({onSubmit}:{onSubmit?:()=>void}) => {
  const dispatch = useDispatch();
  const { activePackage, activeLanguage } = useSelector(
    footerSelectors.getAllState
  );

  const [languagesData, setLanguagesData] = useState([]);

  const { editor } = useSelector(editorSelectors.getAllState);
  const { markers } = useSelector(editorSelectors.getAllState);

  const { consoleWarning } = useSelector(footerSelectors.getAllState);

  const {
    activeTask,
    isOnline,
    isStartedSolving,
    assessmentTask,
    testCaseStatuses,
  } = useSelector(assessmentSelectors.getAllState);

  const [isActive, setIsActive] = useState(false);
  const editorValue = editor?.getValue();
  const { isReadOnly } = useSelector(editorSelectors.getAllState);
  const { userRole } = useSelector(editorSelectors.getAllState);
  const [sqlScript, setSqlScript] = useState(null);
  const { onRunTestCases } = useRunTestCases();

  const testcaseList = activeTask?.data?.testcases;
  const testCaseInputs = JSON.parse(activeTask?.data?.testCaseInputs || "[]");
  const outputType = activeTask?.data?.outputType;

  const handleClick = async (e) => {
    if (activePackage !== null) {
      //multi-language
      await invokeMultipleSubmission();
    } else {
      //single submission
      await invokeSubmission();
    }

    async function invokeSubmission() {
      if (Array.isArray(testcaseList) && testcaseList.length > 0)
        onRunTestCases(testcaseList, testCaseInputs, outputType);
      else {
        if (!activeLanguage?.id) alert("Please select the language");
        dispatch(isTerminalLoading(true));
        const editorData = {
          language_id: activeLanguage?.id,
          source_code: encode(editor?.getValue())?.toString("base64"),
        };
        const { data } = await axios.post(
          `${API_ASSESSMENT}/api/coding-area/run`,
          editorData,
          getConfig()
        );
        handleRunCode(data);
      }
    }

    async function invokeMultipleSubmission() {
      if (!activeLanguage?.id) alert("Please select the language");
      console.log("execution happened")
      dispatch(isTerminalLoading(true));
      dispatch(runCodeState({ stdout: "", compile_output: "" }));
      dispatch(setError({ message: "", stderr: "" }));
      const editorData = {
        content: encode(editor?.getValue())?.toString("base64"),
        package: activePackage?.name,
        sql: [sqlScript !== null && sqlScript?.script],
        language: activeLanguage?.id,
      };
      dispatch(isTerminalLoading(true));
      const { data } = await axios.post(
        `${API_ASSESSMENT}/api/coding-area/run-multiple-sqlite`,
        editorData,
        getConfig()
      );
      handleRunCode(data);
    }
  };

  const getDatabaseScript = async (dbId) => {
    await fetchData(
      `${API_ASSESSMENT}/api/assesment-database/getById/${dbId}`,
      setSqlScript
    );
  };

  useEffect(() => {
    if (isOnline && assessmentTask?.databaseId) {
      getDatabaseScript(assessmentTask?.databaseId);
    }
  }, [assessmentTask]);

  const handleRunCode = async (data) => {
    dispatch(isTerminalLoading(true));
    const result: any = await CodeExecutor(data);
    dispatch(setSubmissionResults([{...result}]));
    dispatch(isTerminalLoading(false));
    saveExecution(result);
  };

  useEffect(() => {
    fetchData(`${API_ASSESSMENT}/api/languages`, setLanguagesData);
  }, []);

  useEffect(() => {
    if (activeTask) {
      if (activeTask.data.languageId) {
        const data = languagesData.find(
          (lang) => lang?.id === activeTask.data.languageId
        );
        dispatch(setLanguage(data));
      } else {
        dispatch(setLanguage(null));
      }
      activeTask.data.package
        ? dispatch(
            setPackage({
              id: activeTask.data.id,
              label: activeTask.data.package,
            })
          )
        : dispatch(setPackage(null));
    }
  }, [languagesData, activeTask, dispatch]);

  const getConsoleName = (e) => {
    return dispatch(setConsoleWarning(e));
  };

  const handleConsoleActive = () => {
    setIsActive(!isActive);
    if (!isActive) {
      dispatch(setConsoleWarningOpen(CodingConsoleEnun.consoleWarningOpen));
    } else {
      dispatch(setConsoleWarningOpen(null));
    }
  };

  return (
    <div className="footer-editor">
      <div className="footer-editor__content">
        <div className="footer-editor__main-panel">
          <div className="footer-editor__icon-bar">
            <div role="button" tabIndex={0} onClick={handleConsoleActive}>
              <img src={consoleIcon} alt="console" />
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => {
                dispatch(setModal(CodingModalsEnum.settings));
              }}
            >
              <img src={settingsIcon} alt="console" />
            </div>
            <div>
              <img src={reloadIcon} alt="console" />
            </div>
          </div>
          <div className="footer-editor__select-bar">
            {isOnline && userRole === "recruiter" && (
              <button
                className="c-btn c-btn--gray"
                onClick={() => {
                  dispatch(setModal(CodingModalsEnum.listOfQuestions));
                }}
                disabled={isReadOnly}
              >
                Questions
              </button>
            )}
            <CodingSelect
              placeholder="Language"
              options={languagesData ? languagesData : []}
              selected={activeLanguage}
              setSelected={(activeLanguage) =>
                dispatch(setLanguage(activeLanguage))
              }
            />

            {packageOptions.map((item, id) => (
              <div style={{ marginTop: "5px", marginBottom: "5px" }} key={id}>
                <input
                  type="checkbox"
                  value={item?.id}
                  onClick={() => {
                    dispatch(setPackage(item));
                  }}
                />
                <span style={{ paddingLeft: "15px", fontSize: "14px" }}>
                  {item?.label}
                </span>
              </div>
            ))}
          </div>
          <div className="footer-editor__buttons-bar">
            {!isReadOnly && (
              <>
                <button
                  onClick={handleClick}
                  className="c-btn c-btn--greenBorder"
                >
                  Run
                </button>
                {!isOnline && (
                  <button
                    className="c-btn c-btn--green"
                    onClick={() => {
                      isStartedSolving && onSubmit && onSubmit()
                      dispatch(setModal(CodingModalsEnum.congratulations));
                    }}
                  >
                    Submit
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {isActive && (
          <div className="footer-console">
            <div className="footer-editor__console-navigation">
              <p>{consoleWarning ? consoleWarning?.name : "All"}:</p>
              <CodingSelect
                placeholder="All"
                options={consoleWarningOptions}
                selected={consoleWarning}
                setSelected={(consoleWarning) => getConsoleName(consoleWarning)}
              />
            </div>
            <div className="errors-container">
              {markers
                ? markers?.map((marker, id) => {
                    let markerCode = Number(marker?.code);
                    if (
                      markerCode >= 0 &&
                      markerCode < 3000 &&
                      ["Errors", "All"].includes(consoleWarning?.name)
                    ) {
                      return (
                        <Warnings
                          marker={marker}
                          editor={editorValue}
                          key={id}
                          color={"#FE4672"}
                          lightColor={"#ffdbe4"}
                          consoleIcon={consoleErrorIcon}
                          consoleArrow={consoleArrowRed}
                        />
                      );
                    } else if (
                      markerCode >= 3001 &&
                      markerCode < 6000 &&
                      ["Warning", "All"].includes(consoleWarning?.name)
                    ) {
                      return (
                        <Warnings
                          marker={marker}
                          editor={editorValue}
                          key={id}
                          color={"#E9C715"}
                          lightColor={"rgba(233, 199, 21, 0.2)"}
                          consoleIcon={consoleWarnYellowIcon}
                          consoleArrow={consoleArrowYellow}
                        />
                      );
                    } else if (
                      markerCode >= 6001 &&
                      markerCode < 8000 &&
                      ["Warning", "All"].includes(consoleWarning?.name)
                    ) {
                      return (
                        <Warnings
                          marker={marker}
                          editor={editorValue}
                          key={id}
                          color={"#999EA5"}
                          lightColor={"rgba(153, 158, 165, 0.2)"}
                          consoleIcon={consoleWarnGrayIcon}
                          consoleArrow={consoleArrowGray}
                        />
                      );
                    } else if (
                      markerCode >= 8001 &&
                      ["Warning", "All"].includes(consoleWarning?.name)
                    ) {
                      return (
                        <Warnings
                          marker={marker}
                          editor={editorValue}
                          key={id}
                          color={"#029CE2"}
                          lightColor={"rgba(2, 156, 226, 0.2)"}
                          consoleIcon={consoleWarnBlueIcon}
                          consoleArrow={consoleArrowBlue}
                        />
                      );
                    }
                  })
                : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function saveExecution(result: any) {
  const obj = {
    "language_id": result?.language_id,
    "judge0_id": result?.token,
    "judge0_created_at": result?.created_at,
    "judge0_finished_at": result?.finished_at,
    "judge0_status_id": result?.status?.id
  };

  // postData(`https://wtt-dev.urecruits.com/api/coding-execution/create`, obj);
  postData(`${API_ASSESSMENT}/api/coding-execution/create`, obj);
}

