import { useEffect, useState } from "react";
import { IPagination, ISearch, ITableEmpty, ITableFilter } from "../types/table-types";
import axios from "axios";
import { getConfigForAuthorization } from "../utils/getConfigForAuthorization";

type RowInput = { api: string; rowData?: never } | { rowData: any[]; api?: never };
export const useTableCardView = ({ refetchApi, pagination, searchField, filters, emptyTable, rowsData, isLoading }: { listAPI?: string, refetchApi?: { refetch: boolean, setRefetch: any }; pagination?: IPagination, searchField?: ISearch, filters?: ITableFilter, emptyTable?: Omit<ITableEmpty, 'type'>, rowsData: RowInput, isLoading?: boolean }) => {

	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		setLoading(isLoading)
	}, [isLoading])

	const [rowData, setRowData] = useState([]);
	useEffect(() => {
		if (rowsData.rowData) {
			handleRowData(rowsData.rowData)
		} else if (rowsData.api) {
			setCandidateJobsHandler({});
		}
	}, [rowsData?.api, rowsData?.rowData?.length])

	useEffect(() => {
		refetchApi && refetchApi.refetch && setCandidateJobsHandler({ needLoader: false });
	}, [refetchApi ? refetchApi.refetch : '']);

	const handleRowData = (data) => {
		setRowData(data);
		filters && filters && filters.isFilterSubmit && !!data && !filters.isEmptyFilter && filters.setIsEmptyFilter(true)
		filters && filters && filters.isFilterSubmit && data && filters.isEmptyFilter && filters.setIsEmptyFilter(false)
		setEmptyTable(data);
		if (!!data) {
			filters && filters.setIsFilterSubmit && filters.setIsFilterSubmit(false);
			refetchApi && refetchApi.refetch && refetchApi.setRefetch(false);
		}
	}
	const setCandidateJobsHandler = ({ needLoader = true }: { needLoader?: boolean }) => {

		const getData = async () => {
			needLoader && setLoading(true);
			try {
				await axios(rowsData.api, getConfigForAuthorization())
					.then((res) => {
						needLoader && setLoading(false);
						setRowData(res.data.rows);
						pagination && pagination.setTotalCount && pagination.setTotalCount(res.data.count);
						filters && filters && filters.isFilterSubmit && !!res.data.rows && !filters.isEmptyFilter && filters.setIsEmptyFilter(true)
						filters && filters && filters.isFilterSubmit && res.data.rows && filters.isEmptyFilter && filters.setIsEmptyFilter(false)

						setEmptyTable(res.data.rows);
						if (!!res.data.rows) {
							filters && filters.setIsFilterSubmit && filters.setIsFilterSubmit(false);
							refetchApi && refetchApi.refetch && refetchApi.setRefetch(false);
						}

					});
			} catch (err) {
				setEmptyTableObj({
					type: "emptyTable",
					handler: emptyTable && emptyTable.handler,
					buttonText: (emptyTable && emptyTable.buttonText),
					title: emptyTable && emptyTable.title,
					desc: emptyTable && emptyTable.desc,
				})
				setLoading(false);
			}

		};
		getData().then();
	};

	const [emptyTableObj, setEmptyTableObj] = useState<ITableEmpty>();

	const setEmptyTable = (data) => {
		if (!!data.length) {
			setEmptyTableObj(undefined);
		}
		else {
			if (searchField && searchField.searchValue) {
				const emptySearchObj = searchField.emptySearch;
				setEmptyTableObj({
					type: "emptySearch",
					handler: () => { searchField.setSearchValue && searchField.setSearchValue('') },
					buttonText: (emptySearchObj && emptySearchObj.buttonText) ?? "Reset Search",
					title: emptySearchObj && emptySearchObj.title,
					desc: emptySearchObj && emptySearchObj.desc,
				})
			} else if (filters && (filters.isFilterSubmit || filters.isEmptyFilter)) {
				const emptySearchObj = filters.emptyFilter;
				setEmptyTableObj({
					type: "emptyFilter",
					handler: filters && filters.resetFilters,
					buttonText: (emptySearchObj && emptySearchObj.buttonText) ?? "Reset Filter",
					title: emptySearchObj && emptySearchObj.title,
					desc: emptySearchObj && emptySearchObj.desc,
				})
			} else {
				setEmptyTableObj({
					type: "emptyTable",
					handler: emptyTable && emptyTable.handler,
					buttonText: (emptyTable && emptyTable.buttonText),
					title: emptyTable && emptyTable.title,
					desc: emptyTable && emptyTable.desc,
				})
			}
		}
	}

	return {
		rowData,
		emptyTableObj,
		loading,
	}
};

