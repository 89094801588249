import {
  OfferKeys,
  OfferLetterStatus,
} from "../../enums/usersOffers/UsersOffersEnums";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();
export const CloseOfferModal = ({ setModal, data }) => {
  const navigate = useNavigate();
  const handleCloseOffer = () => {
      const { userId, jobId, offerId, roundId, subscribeId } = data;
    const patchData = axios.patch(
      `${API_RECRUITMENT}/api/offer/${offerId}`,
      { [OfferKeys.LETTERSTATUS]: OfferLetterStatus.CLOSED },
      getConfig()
    );

    patchData
      .then(() =>
        navigate(
          `/recruitment/offers/${jobId}/${userId}/${roundId}/${subscribeId}`
        )
      )
      .catch((e) => console.log(e))
      .finally(() => setModal(false));
  };
  return (
    <>
      <div className="modal__body__close-offer">
        <p>To Resend the offer, previous offer has to be closed first.</p>
      </div>

      <div className="modal__actions">
        <button
          className="modal__actions__cancel__outlined"
          onClick={() => setModal(false)}
        >
          Cancel
        </button>
        <button className="modal__actions__send" onClick={handleCloseOffer}>
          Close Offer
        </button>
      </div>
    </>
  );
};
