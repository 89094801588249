import { useState, useEffect } from "react";
import { selectSearchFuncLocationLimit } from "@urecruits/api";
export const useScrollLocations = ()=>{
    const [hasMoreLocation, setHasMoreLocation] = useState(false);
	const [offsetLocation, setOffsetLocation] = useState(30);
	const [searchValueLocation, setSearchValueLocation] = useState(null);
	const [optionsLocation, setOptionsLocation] = useState<string[]>([]);

	useEffect(() => {
		if (hasMoreLocation || optionsLocation?.length === 0) {
			loadOptionsLocation(searchValueLocation !== null ? searchValueLocation : "");
		}
	}, [hasMoreLocation, optionsLocation?.length === 0]);

    const loadOptionsLocation = async (inputValue) => {
		setSearchValueLocation(inputValue);
		
		if (hasMoreLocation) {
			try {
				const newOptions = await selectSearchFuncLocationLimit(`find=${inputValue && inputValue}&offset=${offsetLocation}`);

				if (newOptions.length > 0) {
					setOffsetLocation(offsetLocation + 30);
					const data = [...optionsLocation, ...newOptions];
					setOptionsLocation(data)
					setHasMoreLocation(false);
					return data 
				} else {
					setHasMoreLocation(false);
					return optionsLocation
				}
			} catch (error) {
				console.error('Error loading more options:', error);
			}
		} else {
			const data = await await selectSearchFuncLocationLimit(`find=${inputValue && inputValue}`);
			setOptionsLocation(data);
			return (data);
		}
	};

	return {
		loadOptionsLocation,setOffsetLocation,hasMoreLocation,setHasMoreLocation,optionsLocation,searchValueLocation
	}
}
