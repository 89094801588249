import { memo, useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import { selectDisableStyle } from "../../styles/selectDisableStyle";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import patchData from "../../hook/patchData";
import { getEnv } from "@urecruits/api";
import AsyncSelect from "react-select/async";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const editIc = require("../../image/icon/edit_ic.svg");

const jobTypeOption = [
	{value: "Full-Time Employees", label: "Full-Time Employees"},
	{value: "Part-Time Employees", label: "Part-Time Employees"},
	{value: "Temporary Employees", label: "Temporary Employees"},
	{value: "Seasonal Employees", label: "Seasonal Employees"},
	{value: "Independent Contractors", label: "Independent Contractors"},
	{value: "Freelancers", label: "Freelancers"},
	{value: "Temporary workers", label: "Temporary workers"},
	{value: "Consultants", label: "Consultants"},
];

const preferableShiftOption = [
	{ value: "General Shift", label: "General Shift" },
	{ value: "Morning Shift", label: "Morning Shift" },
	{ value: "Afternoon Shift", label: "Afternoon Shift" },
	{ value: "Evening Shift", label: "Evening Shift" },
	{ value: "Night Shift", label: "Night Shift" },
	{ value: "Flexible Shift", label: "Flexible Shift" },
];

const {API_RECRUITMENT} = getEnv();

const callbackFunc = async (searchValue) => {
	const token: string = localStorage.getItem("token");

	const req = await fetch(
		`${API_RECRUITMENT}/api/location/${searchValue}`,
		{
			method: "GET",
			...getConfig()
		},
	).then((response) => response.json());
	return req.map(item => {
		return {
			value: `${item.city}, ${item.state}`,
			label: `${item.city}, ${item.state}`,
			id: item.id,
		};
	});
};

const CareerPreferencesTab = ({countryList, data, positionIndustryList, setRefetch}) => {
	const [success, setSuccess] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [displayButton, setDisplayButton] = useState(false);

	//form state
	const [role, setRole] = useState("");
	const [functionalArea, setFunctionalArea] = useState("");
	const [jobType, setJobType] = useState("");
	const [preferableShift, setPreferableShift] = useState("Preferable Shift");
	const [preferredJobLocation, setPreferredJobLocation] = useState(null);
	const [expectedCtc, setExpectedCtc] = useState("");
	const [ableToJoin, setAbleToJoin] = useState("");

	const onFormChange = () => {
		setDisplayButton(true);
	};

	const setStates = () => {
		setRole(data.preferencesRole);
		setFunctionalArea(data.preferencesFunctionalArea);
		setJobType(data.preferencesJobType);
		setPreferableShift(data.preferencesPreferableShift);
		setPreferredJobLocation({value: data.preferencesJobLocation, label: data.preferencesJobLocation});
		setExpectedCtc(data.preferencesExpectedCTC);
		setAbleToJoin(data.preferencesAbleJoin);
	};
	useEffect(() => {
		if (data) {
			setStates();
		}
	}, [data]);
	const {API_RECRUITMENT} = getEnv();
	const onFormSubmit = async (e) => {
		e.preventDefault();
		const data = {
			preferencesRole: role,
			preferencesFunctionalArea: functionalArea,
			preferencesJobType: jobType,
			preferencesPreferableShift: preferableShift,
			preferencesJobLocation: preferredJobLocation.label,
			preferencesExpectedCTC: expectedCtc,
			preferencesAbleJoin: ableToJoin,
		};
		const res = await patchData(`${API_RECRUITMENT}/api/candidate`, data);
		//need to send logic
		res.status === 200 && setSuccess(true);
		setRefetch(new Date());
		setTimeout(() => {
			setSuccess(false);
			setEditMode(false)
      setDisplayButton(false)
		}, 2000);
	};
	const onClearHandler = (e) => {
		e.preventDefault();
		setEditMode(false);
		setDisplayButton(false);
		setStates();

	};

	return (
		<div className="profile__right__inner">
			<div className="profile__head">
				<div className="profile__head__inner">
					<p className="profile__head__title">
						Career Preferences {editMode && (<span> - Editing Mode</span>)}
					</p>
					{
						!editMode && (
							<p className="profile__head__edit" onClick={() => setEditMode(true)}>
								<span>Edit</span>
								<img src={editIc} alt="edit icon"/>
							</p>
						)
					}
				</div>
			</div>
			<form
				className={`profile__form ${editMode ? "" : "readonly"}`}
				onSubmit={(e) => onFormSubmit(e)}
				onChange={() => onFormChange()}
			>
				<div className="profile__form__group">
					<div className="profile__form__item">
						<label className="profile__form__label">Position</label>
						<ReactSelect
							options={positionIndustryList.Positions}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setRole(currentValue.label);
								setDisplayButton(true);
							}}
							value={positionIndustryList && role ? positionIndustryList.Positions.find((x) => x.label === role) : null}
							isDisabled={!editMode}
							placeholder={`${editMode ? "Select your position" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="positionSelect"
							instanceId="positionSelect"
						/>
					</div>
					<div className="profile__form__item">
						<label className="profile__form__label">Functional Area</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter functional area" : "Not indicated"}`}
							readOnly={!editMode}
							value={functionalArea ? functionalArea : ""}
							onChange={(e) => setFunctionalArea(e.target.value)}
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className="profile__form__item">
						<label className="profile__form__label">Job Type</label>
						<ReactSelect
							options={jobTypeOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setJobType(currentValue.label);
								setDisplayButton(true);
							}}
							value={jobType ? jobTypeOption.find(x => x.label === jobType) : null}
							isDisabled={!editMode}
							placeholder={`${editMode ? "Select job type" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="jobTypeSelect"
							instanceId="jobTypeSelect"
						/>
					</div>
					<div className="profile__form__item">
						<label className="profile__form__label">Preferable Shift</label>
						<ReactSelect
							options={preferableShiftOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setPreferableShift(currentValue.label);
								setDisplayButton(true);
							}}
							value={preferableShift ? preferableShiftOption.find(x => x.label === preferableShift) : null}
							isDisabled={!editMode}
							placeholder={`${editMode ? "Select preferable shift" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="preferableShift"
							instanceId="preferableShift"
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className="profile__form__item w67">
						<label className="profile__form__label">Preferred Job Locations</label>
						<AsyncSelect
							cacheOptions
							loadOptions={(inputValue) => inputValue.length > 0 ? callbackFunc(inputValue) : callbackFunc("")}
							defaultOptions
							value={preferredJobLocation}
							onChange={(option: any) => {
								setPreferredJobLocation(option);
								setDisplayButton(true);
							}}
							isDisabled={!editMode}
							placeholder={`${editMode ? "Select preferred job locations" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
						/>
					</div>
					<div className="profile__form__item short">
						<label className="profile__form__label">Expected CTC</label>
						<input
							type="number"
							className="profile__form__input number"
							placeholder={`${editMode ? "Enter expected CTC" : "Not indicated"}`}
							readOnly={!editMode}
							value={expectedCtc ? expectedCtc : ""}
							onChange={(e) => setExpectedCtc(e.target.value)}
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className="profile__form__item long">
						<label className="profile__form__label">Able to Join</label>
						<textarea
							className="profile__form__textarea"
							placeholder="Write smth"
							value={ableToJoin ? ableToJoin : ""}
							readOnly={!editMode}
							onChange={(e) => {
								setAbleToJoin(e.target.value);
							}}
						/>
					</div>
				</div>
				{
					displayButton && editMode ?
						<div className="profile__form__group">
							<div className="profile__form__item buttons">
								<button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel</button>
								<button className="profile__form__submit button--filled" type="submit">Save</button>
							</div>
							{success ? <p className="success-message">All changes made</p> : null}
						</div>
						: null
				}
			</form>
		</div>
	);
};

export default memo(CareerPreferencesTab);