import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EditorModals } from "../components/Popups/editor-modals";
import { store, useTypedSelector } from "../store";
import {
  setUserOffers,
  setOpenOfferId,
} from "../store/reducers/offerLetterReducer";
import PageLoader from "../components/Global/PageLoader";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import OfferPreviewHeader from "../components/OfferPreview/OfferPreviewHeader";
import OfferPreview from "../components/OfferPreview/OfferPreview";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../hook/useSnackbar";
import {
  AuthGuard,
  getConfig,
  useHasPermission,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CandidateOfferStatus, OfferKeys, OfferLetterStatus } from "../enums/usersOffers/UsersOffersEnums";

const { API_RECRUITMENT } = getEnv();
const getOffersFunc = (state) => state.offer_letter.userOffers;
const getOpenOfferIdFunc = (state) => state.offer_letter.openOfferId;
const OfferPreviewScreen = () => {
  const { ErrorElement, APIExecutor } = useSnackbar();
  const [modal, setModal] = useState<boolean | string>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { jobId, userId } = useParams();
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [refetch, setRefetch] = useState(null);
  const navigate = useNavigate();
  const offers = useTypedSelector(getOffersFunc);
  const openOfferId = useTypedSelector(getOpenOfferIdFunc);
  const resent = offers?.length > 1;
  const { checkUserPermission } = useHasPermission();

  useEffect(() => {
    setIsLoading(true);
    checkUserPermission("offer", "view") &&
      APIExecutor(() =>
        setOffersPreviewHandler(jobId, userId).finally(() =>
          setIsLoading(false)
        )
      );
  }, [jobId, userId, checkUserPermission("offer", "view"),refetch]);

  return (
    <>
      <AuthGuard module="offer" permission="view">
        <ErrorElement />
        {!isLoading ? (
          <>
            <OfferPreviewHeader
              offers={offers}
              setModal={setModal}
              setSelectedOffer={setSelectedOffer}
            />

            {offers.map((offer) => (
              <OfferPreview
                offer={offer}
                key={offer.id}
                resent={resent}
                isOpen={offer.id === openOfferId}
                setModal={setModal}
                setSelectedOffer={setSelectedOffer}
              />
            ))}
            <EditorModals
              modal={modal}
              setModal={setModal}
              offerId={selectedOffer?.id}
              formData={selectedOffer}
              singleOfferDelete
              refetchHandler={setRefetch}
            />
          </>
        ) : (
          <PageLoader />
        )}
      </AuthGuard>
    </>
  );
};

export default OfferPreviewScreen;

export const handleSendRequest = async (offerId, setModal, jobId, userId) => {
  const sendSignatureRequest = async () => {
    try {
      const { data } = await axios.get(
        `${API_RECRUITMENT}/api/offer/hellosign/send-signature-request/${offerId}`,
        getConfig()
      );

      await axios.patch(
        `${API_RECRUITMENT}/api/offer/${offerId}`,
        {
          [OfferKeys.LETTERSTATUS]: OfferLetterStatus.SENT,
          [OfferKeys.CANDIDATESTATUS]: CandidateOfferStatus.PENDING,
          [OfferKeys.HELLOSIGNSIGNATUREREQUEST_ID]:
            data.signature_request.signature_request_id,
        },
        getConfig()
      );
    } catch (error) {
      console.error("Error in sending signature request:", error);
    }
  };

  try {
    await sendSignatureRequest();
    await setOffersPreviewHandler(jobId, userId); // Call this after sendSignatureRequest completes
    return true;
  } catch (error) {
    console.error("Error in handleSendRequest:", error);
  } finally {
      setModal("success");
  }
};

export const setOffersPreviewHandler = async (jobId, userId) => {
  await axios(
    `${API_RECRUITMENT}/api/offer/offers/${jobId}/${userId}`,
    getConfig()
  ).then(({ data }) => {
    const offers = data.rows;
    store.dispatch(setUserOffers(offers));
    store.dispatch(setOpenOfferId(offers.toReversed()[0].id));
  });
};
