import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import members from "./reducers/members/membersTableReducer";
import membersAgeBreakdownPie from "./reducers/members/MembersAgeBreakdownReducerPie";
import jobs from "./reducers/jobsTableReducer";
import jobsDetailView from "./reducers/jobDetailViewReducerMatched";
import jobsDetailAreaView from "./reducers/jobDetailViewReducerArea";
import jobsDetailPieView from "./reducers/jobDetailViewReducerPie";

export const store = configureStore({
	reducer: {
		jobs: jobs,
		jobs_matched: jobsDetailView,
		jobs_pie: jobsDetailPieView,
		jobs_area: jobsDetailAreaView,
		members: members,
		members_age_pie: membersAgeBreakdownPie,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useTypedSelector:TypedUseSelectorHook<RootState>=useSelector