import AsyncSelect from 'react-select/async';
import { MembersTab } from '../../../../enums/members/membersEnums';
import { store, useTypedSelector } from "../../../../store";
import { setDateOfBirthFilterMembers, setDateOfJoiningFilterMembers, setDepartmentFilterMembers, setEducationFilterMembers, setRoleFilterMembers } from '../../../../store/reducers/members/membersTableReducer';
import { memo } from "react";
import Option from "../../../Global/SelectOptions";
import { selectSearchFuncDepartment } from '@urecruits/api';
import { selectCustomStyle } from '@ucrecruits/globalstyle/styles/selectCustomStyle';
import DatePicker from 'react-datepicker';
import { IMembersDateOfJoiningItem } from '../../../../types/redux/members';
import { educationOption } from '../../../../utils/constants';


const dateJoiningRanges: Array<IMembersDateOfJoiningItem["duration"]> = ["Last 30 Days", "Last 90 Days", "Last 3 months", "Last 6 months", "Last 12 months", "This year", "Last year", "All time"];
const getFiltersFunc = state => state.members.filters
const getFilterDataFunc = state => state.members.filterInfo
const FiltersMembersAnalytics = ({ activeTab }) => {
    const filter = useTypedSelector(getFiltersFunc)
    const filterData = useTypedSelector(getFilterDataFunc)
    return <>
        {
            activeTab === MembersTab.ROLE &&
            <div className='table-filter-popup__right__content'>
                {
                    <ul className='filters-popup-mtm-buttons-list'>
                        {
                            filterData.roleList?.length ? 
                            <>
                            {filterData.roleList?.map(item => {
                                return <li
                                    className={`${filter.role.find(x => x === item.id) ? 'filters-popup-mtm-buttons-list__item active' : 'filters-popup-mtm-buttons-list__item'}`}
                                    key={item.id}
                                    onClick={() => store.dispatch(setRoleFilterMembers(filter.role.find(x => x === item.id) ? filter.role.filter(x => x !== item.id) : [...filter.role, item.id]))}
                                >
                                    {item.role}
                                </li>
                            })}
                            </>:
                            <>
                            <li className='filters-popup-mtm-buttons-list__item'>
                                No Roles Found.
                                <br>Add roles in your company to filter bu them.</br>
                            </li>
                            </>
                        }
                    </ul>
                }
            </div>
        }
        {
            activeTab === MembersTab.DEPARTMENT &&
            <div className='table-filter-popup__right__content'>
                <AsyncSelect
                    components={{
                        Option,
                    }}
                    isMulti
                    cacheOptions
                    loadOptions={(inputValue) =>
                        inputValue.length > 0 ? selectSearchFuncDepartment(inputValue) : selectSearchFuncDepartment('')
                    }
                    hideSelectedOptions={false}
                    defaultOptions
                    value={filter.department}
                    onChange={(option: any) => {
                        store.dispatch(setDepartmentFilterMembers(option))
                    }}
                    closeMenuOnSelect={false}
                    placeholder='Search by department'
                    styles={selectCustomStyle}
                    id="department"
                    instanceId="department"
                />
            </div>
        }
        {
            activeTab === MembersTab.Education &&
            <div className='table-filter-popup__right__content'>
                {
                    <ul className='filters-popup-mtm-buttons-list'>
                        {
                            educationOption.map(item => {
                                return <li
                                    className={`filters-popup-mtm-buttons-list__item ${filter.education.find(i => i.value == item.value) ? 'active' : ''}`}
                                    key={item.value}
                                    onClick={() => store.dispatch(setEducationFilterMembers(filter.education.find(i => i.value == item.value) ? filter.education.filter(i => i.value !== item.value) : [...filter.education, item]))}
                                >
                                    {item.label}
                                </li>
                            })
                        }
                    </ul>
                }
            </div>
        }
        {/* {
			activeTab === MembersTab.Job_Location &&
      <div className='table-filter-popup__right__content'>
        <FilterWithLimitComponent filter={{value:filter.location,onChangeValue:(option:any)=>{store.dispatch(setJobLocationFilterMembers(option))}}} placeHolder="Select Location" setLimitSearchfunction={selectSearchFuncLocationLimit}/>
      </div>
		} */}
        {
            activeTab === MembersTab.DATE_OF_BIRTH &&
            <div className='table-filter-popup__right__content'>
                <div className='filters-popup-mtm-datepicker'>
                    <div className='filters-popup-mtm-datepicker__item'>
                        <label className="filters-popup-mtm-datepicker__label">From</label>
                        <div className='filters-popup-mtm-datepicker__date'>
                            <DatePicker
                                selected={filter.dateOfBirth.from}
                                showYearDropdown={true}
                                scrollableYearDropdown={true}
                                yearDropdownItemNumber={70}
                                onChange={(date: any) => {
                                    store.dispatch(setDateOfBirthFilterMembers({ from: date, to: filter.dateOfBirth.to }))
                                }}
                                dateFormat="MMMM d, yyyy"
                                maxDate={new Date()}
                                placeholderText="From"
                                id={'birthFrom'}
                            />
                        </div>
                    </div>
                    <div className='filters-popup-mtm-datepicker__item'>
                        <label className="filters-popup-mtm-datepicker__label">To</label>
                        <div
                            className={`${filter.dateOfBirth.from ? 'filters-popup-mtm-datepicker__date' : 'filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly'}`}>
                            <DatePicker
                                selected={filter.dateOfBirth.to}
                                showYearDropdown={true}
                                scrollableYearDropdown={true}
                                yearDropdownItemNumber={70}
                                onChange={(date: any) => {
                                    store.dispatch(setDateOfBirthFilterMembers({ to: date, from: filter.dateOfBirth.from }))
                                }}
                                readOnly={!filter.dateOfBirth.from}
                                dateFormat="MMMM d, yyyy"
                                maxDate={new Date()}
                                minDate={filter.dateOfBirth.from}
                                placeholderText="To"
                            />
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            activeTab === MembersTab.DATE_OF_JOINING &&
            <div className='table-filter-popup__right__content'>
                <div className='filters-popup-mtm-datepicker'>
                    <div className='filters-popup-mtm-datepicker__item'>
                        <label className="filters-popup-mtm-datepicker__label">From</label>
                        <div className='filters-popup-mtm-datepicker__date'>
                            <DatePicker
                                selected={filter.dateOfJoining.from}
                                showYearDropdown={true}
                                scrollableYearDropdown={true}
                                yearDropdownItemNumber={70}
                                onChange={(date: any) => {
                                    store.dispatch(setDateOfJoiningFilterMembers({ from: date, to: filter.dateOfJoining.to, duration: filter.dateOfJoining.duration }))
                                }}
                                dateFormat="MMMM d, yyyy"
                                maxDate={new Date()}
                                placeholderText="From"
                                id={'birthFrom'}
                            />
                        </div>
                    </div>
                    <div className='filters-popup-mtm-datepicker__item'>
                        <label className="filters-popup-mtm-datepicker__label">To</label>
                        <div
                            className={`${filter.dateOfJoining.from ? 'filters-popup-mtm-datepicker__date' : 'filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly'}`}>
                            <DatePicker
                                selected={filter.dateOfJoining.to}
                                showYearDropdown={true}
                                scrollableYearDropdown={true}
                                yearDropdownItemNumber={70}
                                onChange={(date: any) => {
                                    store.dispatch(setDateOfJoiningFilterMembers({ from: filter.dateOfJoining.from, to: date, duration: filter.dateOfJoining.duration }))
                                }}
                                readOnly={!filter.dateOfJoining.from}
                                dateFormat="MMMM d, yyyy"
                                maxDate={new Date()}
                                minDate={filter.dateOfJoining.from}
                                placeholderText="To"
                            />
                        </div>
                    </div>
                </div>
                <div className='table-filter-popup__right__content'>
                    {
                        <ul className='filters-popup-mtm-buttons-list'>
                            {
                                dateJoiningRanges.map(item => {
                                    return <li
                                        className={`filters-popup-mtm-buttons-list__item ${filter.dateOfJoining.duration == item ? 'active' : ''}`}
                                        key={item}
                                        onClick={() => store.dispatch(setDateOfJoiningFilterMembers({ from: filter.dateOfJoining.from, to: filter.dateOfJoining.to, duration: item }))}
                                    >
                                        {item}
                                    </li>
                                })
                            }
                        </ul>
                    }
                </div>
            </div>
        }
    </>
}

export default memo(FiltersMembersAnalytics)