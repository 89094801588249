import { memo, useEffect, useState } from "react";
import PopupPictureUpload from "../component/candidate/PopupPictureUpload";
import { OptionProps, default as ReactSelect, ValueContainerProps } from "react-select";
import { selectMobileMenuStyle } from "../styles/selectMobileMenuStyle";
import QuickViewTab from "../component/candidate/QuickViewTab";
import PersonalDetailsTabAndCv from "../component/candidate/PersonalDetailsTabAndCv";
import CareerPreferencesTab from "../component/candidate/CareerPreferencesTab";
import ManagePasswordTab from "../component/candidate/ManagePasswordTab";
import VisaInformationTab from "../component/candidate/VisaInformationTab";
import ExperienceDetailsTab from "../component/candidate/experience/ExperienceDetailsTab";
import EducationsDetailsTab from "../component/candidate/education/EducationsDetailsTab";
import SkillsDetailsTab from "../component/candidate/skills/SkillsDetailsTab";
import ProjectsDetailsTab from "../component/candidate/projects/ProjectsDetailsTab";
import CertificatesDetailsTab from "../component/candidate/certificates/CertificatesDetailsTab";
import AwardsDetailsTab from "../component/candidate/awards/AwardsDetailsTab";
import fetchData from "../hook/fetchData";
import { getEnv } from "@urecruits/api";
import Integrations from '../component/company/integrations/Integrations';
import { Box, Stack, Typography } from "@mui/material";
import { components } from 'react-select';
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const red_warning = require('../image/icon/red_warning.svg')

const Option = (props: OptionProps<any>) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Box>
        <components.Option {...props} />
      </Box>
      {props.data.error && <Typography color={"red"} variant="caption">(Unsaved)</Typography>}
    </Stack>
  );
};

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<any>) => {
  return (
    <components.ValueContainer {...props}>
      {props.selectProps.value.error &&
        <Box sx={{ width: 18, height: 18, mr: 1 }}><img src={red_warning} alt="social icon" /></Box>
      }
      {children}
      {props.selectProps.value.error &&
        <Typography color={"red"} variant="caption">(Unsaved)</Typography>}
    </components.ValueContainer>
  )
};

const { API_RECRUITMENT } = getEnv();

const CandidateProfileScreen = ({
  visaTypes,
  usState,
  countryList,
  nationalityList,
  languagesList,
  positionIndustryList,
}) => {

  const { checkUserPermission } = useHasPermission()

  const [activeTabName, setActiveTabName] = useState("quickView");
  const [displayUploadPopup, setDisplayUploadPopup] = useState(false);
  const [editStatusForPopup, setEditStatusForPopup] = useState(false);

  const [refetch, setRefetch] = useState("");
  const [data, setData] = useState(null);

  const [resumeParsingData, setResumeParsingData] = useState()

  const processData = (apiData) => {
    const _apiData = { ...apiData }
    _apiData.birthday = { value: _apiData.birthday, isResumeParsed: false, oldValue: _apiData.birthday }
    _apiData.gender = { value: _apiData.gender, isResumeParsed: false, oldValue: _apiData.gender }
    _apiData.maritalStatus = { value: _apiData.maritalStatus, isResumeParsed: false, oldValue: _apiData.maritalStatus }
    _apiData.languages = { value: _apiData.languages, isResumeParsed: false, oldValue: _apiData.languages }

    _apiData.linkedin = { value: _apiData.linkedin, isResumeParsed: false, oldValue: _apiData.linkedin }
    _apiData.facebook = { value: _apiData.facebook, isResumeParsed: false, oldValue: _apiData.facebook }
    _apiData.twitter = { value: _apiData.twitter, isResumeParsed: false, oldValue: _apiData.twitter }
    _apiData.instagram = { value: _apiData.instagram, isResumeParsed: false, oldValue: _apiData.instagram }

    _apiData.currentStreet = { value: _apiData.currentStreet, isResumeParsed: false, oldValue: _apiData.currentStreet }
    _apiData.currentHouseNumber = { value: _apiData.currentHouseNumber, isResumeParsed: false, oldValue: _apiData.currentHouseNumber }
    _apiData.currentCity = { value: _apiData.currentCity, isResumeParsed: false, oldValue: _apiData.currentCity }
    _apiData.currentCountry = { value: _apiData.currentCountry, isResumeParsed: false, oldValue: _apiData.currentCountry }
    _apiData.currentState = { value: _apiData.currentState, isResumeParsed: false, oldValue: _apiData.currentState }
    _apiData.currentZip = { value: _apiData.currentZip, isResumeParsed: false, oldValue: _apiData.currentZip }

    _apiData.permanentStreet = { value: _apiData.permanentStreet, isResumeParsed: false, oldValue: _apiData.permanentStreet }
    _apiData.permanentHouseNumber = { value: _apiData.permanentHouseNumber, isResumeParsed: false, oldValue: _apiData.permanentHouseNumber }
    _apiData.permanentCity = { value: _apiData.permanentCity, isResumeParsed: false, oldValue: _apiData.permanentCity }
    _apiData.permanentCountry = { value: _apiData.permanentCountry, isResumeParsed: false, oldValue: _apiData.permanentCountry }
    _apiData.permanentState = { value: _apiData.permanentState, isResumeParsed: false, oldValue: _apiData.permanentState }
    _apiData.permanentZip = { value: _apiData.permanentZip, isResumeParsed: false, oldValue: _apiData.permanentZip }

    _apiData.onlineProfile = { value: _apiData.onlineProfile, isResumeParsed: false, oldValue: _apiData.onlineProfile }

    _apiData.cvKey = { value: _apiData.cvKey, isResumeParsed: false, oldValue: _apiData.cvKey }
    _apiData.cvName = { value: _apiData.cvName, isResumeParsed: false, oldValue: _apiData.cvName }




    const userData = {
      ..._apiData.user,
      "phone": { value: _apiData.user.phone, isResumeParsed: false, oldValue: _apiData.user.phone },
    }
    _apiData.user = userData;

    setData(_apiData);
  }

  useEffect(() => {
    checkUserPermission('candidate') && fetchData(`${API_RECRUITMENT}/api/candidate`, processData).then();
  }, [refetch]);

  const isPersonalDetailsChanged = data?.gender?.isResumeParsed || data?.maritalStatus?.isResumeParsed || data?.currentStreet?.isResumeParsed || data?.currentHouseNumber?.isResumeParsed || data?.currentCity?.isResumeParsed || data?.currentCountry?.isResumeParsed || data?.currentState?.isResumeParsed || data?.currentZip?.isResumeParsed || data?.birthday?.isResumeParsed
  const isQuickViewChanged = data?.user?.phone.isResumeParsed
  const isSkillChanged = data?.skills?.some(item => item?.isResumeParsed);
  const isEducationsChanged = data?.educations?.some(item => item?.isResumeParsed);
  const isExperienceChanged = data?.experiences?.some(item => item?.isResumeParsed);
  const isCertificatesChanged = data?.certificates?.some(item => item?.isResumeParsed);
  const isAwardsChanged = data?.awards?.some(item => item?.isResumeParsed);
  const isProjectsChanged = data?.projects?.some(item => item?.isResumeParsed);

  const menuOption: any = [
    { value: "quickView", label: "Quick View", error: isQuickViewChanged },
    { value: "personalDetails", label: "Personal Details & CV", error: isPersonalDetailsChanged },
    { value: "experienceDetails", label: "Experience Details", error: isExperienceChanged },
    { value: "educationsDetails", label: "Educations Details", error: isEducationsChanged },
    { value: "skills", label: "Skills", error: isSkillChanged },
    { value: "certificates", label: "Certificates", error: isCertificatesChanged },
    { value: "awards", label: "Awards", error: isAwardsChanged },
    { value: "projects", label: "Projects", error: isProjectsChanged },
    { value: "visaInformation", label: "Visa Information", error: false },
    { value: "careerPreferences", label: "Career Preferences", error: false },
    { value: "managePassword", label: "Manage Password", error: false },
  ];

  const isUnsavedData = isPersonalDetailsChanged || isQuickViewChanged || isSkillChanged || isEducationsChanged || isExperienceChanged || isCertificatesChanged || isAwardsChanged || isProjectsChanged

  const handleBeforeUnload = (event) => {
    if (isUnsavedData) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUnsavedData]);

  return (
    <AuthGuard module='candidate'>
      <section className="profile">
        <div className="profile__inner">
          <h2 className="profile__headline">Candidate Profile</h2>
          <div className="profile__left">
            <ul className="profile__left__menu">
              <li
                className={`profile__left__item ${activeTabName === "quickView" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("quickView")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography>Quick View</Typography>
                  {isQuickViewChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "personalDetails" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("personalDetails")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography>Personal Details & CV</Typography>
                  {isPersonalDetailsChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "experienceDetails" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("experienceDetails")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography>Experience Details</Typography>
                  {isExperienceChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "educationsDetails" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("educationsDetails")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography>Educations Details</Typography>
                  {isEducationsChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "skills" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("skills")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                  <Typography>Skills</Typography>
                  {isSkillChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "certificates" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("certificates")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                  <Typography>Certificates</Typography>
                  {isCertificatesChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "awards" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("awards")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                  <Typography>Awards</Typography>
                  {isAwardsChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "projects" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("projects")}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                  <Typography>Projects</Typography>
                  {isProjectsChanged && <Box sx={{ width: 22, height: 22 }}><img src={red_warning} alt="social icon" /></Box>}
                </Stack>
              </li>
              <li
                className={`profile__left__item ${activeTabName === "visaInformation" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("visaInformation")}>
                Visa Information
              </li>
              <li
                className={`profile__left__item ${activeTabName === "careerPreferences" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("careerPreferences")}>
                Career Preferences
              </li>
              <li
                className={`profile__left__item ${activeTabName === "integrations" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("integrations")}>
                Integrations
              </li>
              <li
                className={`profile__left__item ${activeTabName === "managePassword" ? "active" : ""
                  }`}
                onClick={() => setActiveTabName("managePassword")}>
                Manage Password
              </li>
            </ul>
            <div className="profile__left__mobile-menu">
              {isUnsavedData && <Typography color={"red"} variant="caption">(There is some unsaved details of candidate)*</Typography>}
              <ReactSelect
                options={menuOption}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setActiveTabName(item.value);
                }}
                defaultValue={{ label: "Quick View", value: "quickView", error: isQuickViewChanged }}
                styles={selectMobileMenuStyle}
                id="mobileMenu"
                instanceId="mobileMenu"
                components={{ Option, ValueContainer: ValueContainer }}
              />
            </div>
          </div>
          <div
            className={`profile__right ${activeTabName === "managePassword" ? "password" : ""
              }`}>
            {activeTabName === "quickView" && (
              <QuickViewTab
                setDisplayUploadPopup={setDisplayUploadPopup}
                data={data}
                setData={setData}
                setRefetch={setRefetch}
                positionIndustryList={positionIndustryList}
                setEditStatusForPopup={setEditStatusForPopup}
              />
            )}
            {activeTabName === "personalDetails" && (
              <PersonalDetailsTabAndCv
                usState={usState}
                countryList={countryList}
                languagesList={languagesList}
                data={data}
                setData={setData}
                setRefetch={setRefetch}
                resumeParsingData={resumeParsingData}
                setResumeParsingData={setResumeParsingData}
              />
            )}
            {activeTabName === "careerPreferences" && (
              <CareerPreferencesTab
                countryList={countryList}
                data={data}
                positionIndustryList={positionIndustryList}
                setRefetch={setRefetch}
              />
            )}
            {activeTabName === "integrations" && <Integrations />}
            {activeTabName === "managePassword" && (
              <ManagePasswordTab data={data} />
            )}
            {activeTabName === "visaInformation" && (
              <VisaInformationTab
                visaOption={visaTypes}
                data={data}
                setRefetch={setRefetch}
                countryList={countryList}
                nationalityList={nationalityList}
              />
            )}
            {activeTabName === "experienceDetails" && (
              <ExperienceDetailsTab
                data={data}
                setData={setData}
                setRefetch={setRefetch}
                countryList={countryList}
                positionIndustryList={positionIndustryList}
              />
            )}
            {activeTabName === "educationsDetails" && (
              <EducationsDetailsTab data={data} setRefetch={setRefetch} setData={setData} />
            )}
            {activeTabName === "skills" && (
              <SkillsDetailsTab data={data} setData={setData} setRefetch={setRefetch} resumeParsingData={resumeParsingData} />
            )}
            {activeTabName === "projects" && (
              <ProjectsDetailsTab data={data} setRefetch={setRefetch} setData={setData} />
            )}
            {activeTabName === "certificates" && (
              <CertificatesDetailsTab data={data} setRefetch={setRefetch} setData={setData} />
            )}
            {activeTabName === "awards" && (
              <AwardsDetailsTab
                data={data}
                setRefetch={setRefetch}
                setData={setData}
              />
            )}
          </div>
        </div>
      </section>
      {displayUploadPopup && editStatusForPopup && (
        <PopupPictureUpload
          setDisplayUploadPopup={setDisplayUploadPopup}
          data={data}
          setRefetch={setRefetch}
        />
      )}
    </AuthGuard>
  );
};

export default memo(CandidateProfileScreen);
