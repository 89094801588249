import "../styles/main.scss";
import SearchField from "../components/SearchField/SearchField";
import CheckBox from "../components/CheckBox/CheckBox";
import ShowDomainResult from '../components/DomainAssessmentResult/DomainAssessmentResult';
import TheadItem from "../components/Table/TheadItem";
import Pagination from "../components/Table/Pagination";
import { ICheckBox, IPagination, ISearch, ITableEmpty, ITableView, ITheadItem } from "../types/table-types";
import { IDomainResult } from '../types/domain-assessment-result-types';
import CustomRange from "../components/CustomRange";
import Loader from "../components/Table/Loader";
import SmallLoader from "../components/Table/SmallLoader";
import DashboardEmptyData from '../components/DashboardEmptyData';
import ArrowSVG from '../components/SVGs/ArrowSVG';
import FilterWithLimit from '../components/FilterWithLimit/FilterWithLimit';
import UnauthorizePage from '../components/FallbackPages/UnauthorizePage';
import NotFoundPage from '../components/FallbackPages/NotFoundPage';
import UpgradeSubscription from '../components/FallbackPages/UpgradeSubscriptionPage';
import { default as TableCardView } from "../components/Table/TableCardView/TableCardView";
import { IFilterLimit } from "../types/location-filter-types";
import WarningBox from "../components/WarningBox";
import LocationSelect from "../components/LocationSelect/SelectLocation";
import { SelectLocationProp } from '../types/select-location';
import ShowTooltip from '../components/Tooltip';
import { ToolTipProps } from '../types/tooltip-types';
import TableEmpty from "../components/Table/TableEmpty";
import NoActiveSubscriptionPage from "../components/FallbackPages/NoActiveSubscriptionPage";
import { getConfigForAuthorization } from "../utils/getConfigForAuthorization";
import CircleChart, { CircleChartType } from "../components/ReportsComponents/AnalyticsCircleChart";
import AnalyticsAreaChart, { AreaChartType } from "../components/ReportsComponents/AnalyticsAreaChart";

import { IButton } from "../types/button-type";
import ButtonComponent from "../components/ButtonComponent/ButtonComponent";
const selectOption = [
	{ value: 100, label: '100' },
	{ value: 50, label: '50' },
	{ value: 20, label: '20' },
	{ value: 10, label: '10' },
	{ value: 5, label: '5' },
	{ value: 3, label: '3' },
]

export const SearchFieldComponent = ({ searchValue, setSearchValue, placeholder }: ISearch) => <SearchField
	searchValue={searchValue} setSearchValue={setSearchValue} placeholder={placeholder} />;

export const CheckBoxComponent = ({ setStatus, status = false, addClass = null }: ICheckBox) => <CheckBox setStatus={setStatus} status={status} addClass={addClass} />

export const TheadItemComponent = (props: ITheadItem) => <TheadItem {...props} />

export const PaginationComponent = (props: IPagination) => <Pagination {...props} />

export const ShowDomainAnswersComponent = (props:IDomainResult)=><ShowDomainResult {...props} />

export const TableCardViewComp = (props: ITableView) => <TableCardView {...props} />
export const TableEmptyComp = (props: ITableEmpty) => <TableEmpty {...props} />

export const LoaderComponent = () => <Loader />
export const SmallLoaderComponent = () => <SmallLoader />
export const DashboardListArrowSVG = ()=><ArrowSVG/>
export const DashboardEmptyDataComponent = () => <DashboardEmptyData />

export const FilterWithLimitComponent = (props:IFilterLimit)=><FilterWithLimit {...props}/>
export const CustomRangeComponent = (props: any) => <CustomRange {...props} />
export const UnAuthorizePageComponent = () => <UnauthorizePage />
export const NotFoundPageComponent = () => <NotFoundPage />
export const UpgradeSubscriptionComponent = () => <UpgradeSubscription />
export const NoActiveSubscriptionComponent = () => <NoActiveSubscriptionPage />
export function GlobalConstants() {
	return {
		phoneValidationLength: 10
	}
}

export const WarningBoxComponent = ({ message, className }: { message: string, className? :string | null})=> <WarningBox message={message} className={className}/>
export const ShowToolTipComponent = (props: ToolTipProps) => <ShowTooltip {...props}/>
export const getConfig = () => getConfigForAuthorization()
export { AuthGuard,useHasPermission, useAuth,AuthProvider } from "./../components/AuthProvider"

export * from "./../components/Subscription";
export * from '../context/subscriptionContextProvider'

export const SelectLocationComponent = (props:SelectLocationProp) =><LocationSelect {...props}/>

export {getAllCitiesByState,getAllCountries,getAllSatesByCountry} from '../utils/getCountryStateCity'

export type {modulesType,permissionRoutesType,permissionType} from '../context/types'
export const AnalyticsCircleChartComponent = (props:CircleChartType)=><CircleChart {...props}/>
export const AnalyticsAreaChartComponent = (props:AreaChartType)=><AnalyticsAreaChart {...props}/>
export const Button = (props:IButton)=><ButtonComponent {...props}/>