import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { validatePhoneNumber } from "../../hook/validatePhoneNumber";
import axios from "axios";
import { getEnv, GlobalConstants } from "@urecruits/api";
import { AuthGuard, getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const userAvatar = require("../../image/icon/avatar.svg");
const cameraIc = require("../../image/icon/camera_ic.svg");
const editIc = require("../../image/icon/edit_ic.svg");
const { API_RECRUITMENT } = getEnv();

const QuickViewTab = ({ setDisplayUploadPopup, data, setRefetch, setEditStatusForPopup }) => {
	const [success, setSuccess] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [displayButton, setDisplayButton] = useState(false);
	const { phoneValidationLength } = GlobalConstants();

	//form fields state
	const [firstName, setFirstName] = useState("");
	const [avatar, setAvatar] = useState(userAvatar);
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [position, setPosition] = useState<any>("");
	const [officeEmail, setOfficeEmail] = useState("");
	const [officePhoneNumber, setOfficePhoneNumber] = useState(null);
	const [officePhoneNumberExtn, setOfficePhoneNumberExtn] = useState(null);
	const [mobileNumber, setMobileNumber] = useState(null);
	const [department, setDepartment] = useState("");
	const [jobLocation, setJobLocation] = useState("");
	const [reportingTo, setReportingTo] = useState("");

	//error
	const [firstNameError, setFirstNameError] = useState(false);
	const [middleNameError, setMiddleNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [officePhoneNumberError, setOfficePhoneNumberError] = useState(false);
	const [officePhoneNumberExtnError, setOfficePhoneNumberExtnError] = useState(false);
	const [mobileNumberError, setMobileNumberError] = useState(false);

	const setStates = () => {

		setFirstName(data.user.firstname);
		setMiddleName(data.user.middlename);
		setLastName(data.user.lastname);
		setPosition(data.position);
		setOfficeEmail(data.officeEmail);
		setOfficePhoneNumber(data.officePhone);
		setOfficePhoneNumberExtn(data.officePhoneExtn);
		setMobileNumber(data.user.phone);
		setDepartment(data.department);
		setJobLocation(data.location ? data.location.city + ", " + data.location.state : "");
		setReportingTo(data.reportingTo);
		setAvatar(data.user.avatar ? data.user.avatar : userAvatar);
	};
	useEffect(() => {
		if (data) {
			setStates();
		}
	}, [data]);

	const onFormSubmit = async (e) => {
		e.preventDefault();
		firstName.length === 0 && (setFirstNameError(true));
		middleName.length === 0 && (setMiddleNameError(true));
		lastName.length === 0 && (setLastNameError(true));
		!mobileNumber || (!validatePhoneNumber(mobileNumber) && mobileNumber.length <= phoneValidationLength) ? setMobileNumberError(true) : setMobileNumberError(false);
		!officePhoneNumber || officePhoneNumber.length > phoneValidationLength && (setOfficePhoneNumberError(true));

		if (formValidate() && (officePhoneNumberExtn === null ? true : officePhoneNumberExtn?.length <= 13)) {
			//need to write send logic
			const tmpUser = {
				avatar: avatar,
				firstname: firstName,
				middlename: middleName,
				lastname: lastName,
				phone: mobileNumber,
			};

			const tmpQuickView = {
				officePhone: officePhoneNumber,
				officePhoneExtn: officePhoneNumberExtn,
			};
			await axios.patch(`${API_RECRUITMENT}/api/recruiter`, tmpQuickView, getConfig());

			await axios.patch(`${API_RECRUITMENT}/api/user`, tmpUser, getConfig());
			setRefetch(new Date());
			setSuccess(true);

			setTimeout(() => {
				setSuccess(false);
				setEditMode(false)
				setDisplayButton(false)
			}, 2000);
		}
	};

	const formValidate = () => {
		return firstName && middleName && lastName && !mobileNumberError && validatePhoneNumber(mobileNumber)
			&& (!officePhoneNumber || officePhoneNumber.length < phoneValidationLength);
	};

	const onClearHandler = (e) => {
		e.preventDefault();
		setEditMode(false);
		setDisplayButton(false);
		setStates();
	};

	const onFormChange = () => {
		setDisplayButton(true);
	};

	return (
		<AuthGuard module='recruiter'>
			<div className="profile__right__inner">
				<div className="profile__head">
					<div className="profile__head__inner">
						<p className="profile__head__title">
							Quick View {editMode && (<span> - Editing Mode</span>)}
						</p>
						{
							!editMode && (
								<p className="profile__head__edit" onClick={() => setEditMode(true)}>
									<span>Edit</span>
									<img src={editIc} alt="edit icon" />
								</p>
							)
						}
					</div>
				</div>
				<form
					className={`profile__form ${editMode ? "" : "readonly"}`}
					onSubmit={(e) => onFormSubmit(e)}
					onChange={() => onFormChange()}
				>
					<div className="profile__form__group">
						<div
							className={`profile__form__avatar ${!editMode ? "default" : ""}`}
							onClick={() => {
								setEditStatusForPopup(editMode);
								setDisplayUploadPopup(true);
							}}
						>
							<div className="profile__form__animation">
								<img src={cameraIc} alt="icon" className="profile__form__animation--icon" />
							</div>
							<img src={avatar} alt="user avatar" className="profile__form__avatar--image" />
						</div>
						<div className="profile__form__group wrapped">
							<div className={`profile__form__item short ${firstNameError ? "error" : ""}`}>
								<label className="profile__form__label">First Name{editMode && <span> *</span>}</label>
								<input
									type="text"
									className="profile__form__input"
									placeholder={`${editMode ? "Enter first name" : "Not indicated"}`}
									value={firstName ? firstName : ""}
									readOnly={!editMode}
									onChange={(e) => {
										setFirstName(e.target.value);
										e.target.value.length <= 0 ? setFirstNameError(true) : setFirstNameError(false);
									}}
								/>
								<p className="error-message">This is required field</p>
							</div>
							<div className={`profile__form__item short ${middleNameError ? "error" : ""}`}>
								<label className="profile__form__label">Middle Name{editMode && <span> *</span>}</label>
								<input
									type="text"
									className="profile__form__input"
									placeholder={`${editMode ? "Enter middle name" : "Not indicated"}`}
									value={middleName ? middleName : ""}
									readOnly={!editMode}
									onChange={(e) => {
										setMiddleName(e.target.value);
										e.target.value.length <= 0 ? setMiddleNameError(true) : setMiddleNameError(false);
									}}
								/>
								<p className="error-message">This is required field</p>
							</div>
							<div className={`profile__form__item short ${lastNameError ? "error" : ""}`}>
								<label className="profile__form__label">Last Name{editMode && <span> *</span>}</label>
								<input
									type="text"
									className="profile__form__input"
									placeholder={`${editMode ? "Enter last name" : "Not indicated"}`}
									value={lastName ? lastName : ""}
									readOnly={!editMode}
									onChange={(e) => {
										setLastName(e.target.value);
										e.target.value.length <= 0 ? setLastNameError(true) : setLastNameError(false);
									}}
								/>
								<p className="error-message">This is required field</p>
							</div>
							<div className={`profile__form__item`}>
								<label className="profile__form__label">Position</label>
								<input
									type="text"
									className="profile__form__input readonly"
									readOnly
									value={position ? position.label : ""}
								/>
							</div>
						</div>
					</div>
					<div className="profile__form__group">
						<div className={`profile__form__item`}>
							<label className="profile__form__label">Office Email</label>
							<input
								type="text"
								className="profile__form__input readonly"
								value={officeEmail ? officeEmail : ""}
								readOnly
							/>
							<p className="error-message">This is required field</p>
						</div>
						<div className={`profile__form__item ${officePhoneNumberError ? "error" : ""}`}>
							<label className="profile__form__label">Office Phone Number</label>
							<input
								type="text"
								className="profile__form__input readonly"
								readOnly
								value={officePhoneNumber ? officePhoneNumber : ""}
							/>
						</div>
					</div>
					<div className="profile__form__group">
						<div className={`profile__form__item ${officePhoneNumberExtnError ? "error" : ""}`}>
							<label className="profile__form__label">Office Phone Number Extn</label>
							<PhoneInput
								international
								defaultCountry="US"
								value={officePhoneNumberExtn}
								disabled={!editMode}
								className={!editMode ? "default" : ""}
								onChange={(value: any) => {
									setDisplayButton(true);
									if (value) {
										setOfficePhoneNumberExtn(value);
										value.length > 13 ? setOfficePhoneNumberExtnError(true) : setOfficePhoneNumberExtnError(false);
									} else {
										setOfficePhoneNumberExtn(null);
										setOfficePhoneNumberExtnError(false);
									}
								}}
								placeholder={`${editMode ? "" : "Not indicated"}`}
							/>
							<p className="error-message">
								Phone number not correct
							</p>
						</div>
						<div className={`profile__form__item ${mobileNumberError ? "error" : ""}`}>
							<label className="profile__form__label">Mobile{editMode && <span> *</span>}</label>
							<PhoneInput
								international
								defaultCountry="US"
								value={mobileNumber}
								disabled={!editMode}
								className={!editMode ? "default" : ""}
								onChange={(value: any) => {
									if (value) {
										setMobileNumber(value);
										!validatePhoneNumber(value) || value.length > phoneValidationLength ? setMobileNumberError(true) : setMobileNumberError(false);
									} else {
										setMobileNumberError(true);
										setMobileNumber(value);
									}
								}}
								placeholder={`${editMode ? "" : "Not indicated"}`}
							/>
							<p className="error-message">
								Phone number not correct
							</p>
						</div>
					</div>
					<div className="profile__form__group">
						<div className="profile__form__item">
							<label className="profile__form__label">Department</label>
							<input
								type="text"
								className="profile__form__input readonly"
								readOnly
								value={department ? department : ""}
							/>
						</div>
						<div className="profile__form__item">
							<label className="profile__form__label">Job Location</label>
							<input
								type="text"
								className="profile__form__input readonly"
								readOnly
								value={jobLocation ? jobLocation : ""}
							/>
						</div>
					</div>
					<div className="profile__form__group">
						<div className="profile__form__item">
							<label className="profile__form__label">Reporting To</label>
							<input
								type="text"
								className="profile__form__input readonly"
								readOnly
								value={reportingTo ? reportingTo : ""}
							/>
						</div>
					</div>
					{
						displayButton && editMode ?
							<div className="profile__form__group">
								<div className="profile__form__item buttons">
									<button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
									</button>
									<button className="profile__form__submit button--filled" type="submit">Save</button>
								</div>
								{success ? <p className="success-message">All changes made</p> : null}
							</div>
							: null
					}
				</form>
			</div>
		</AuthGuard>
	);
};

export default QuickViewTab;