import { TableCardViewComp } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import { getEnv } from "@urecruits/api";
import { Link, useNavigate } from "react-router-dom";
import { ASSESSMENT_TYPE } from "../enums/CandidateAssessmentTypeEnums";
import defaultAvatar from "../image/temp-company.png";
import { store, useTypedSelector } from "../store";
import {
  setCurrentPageCandidateScoreboard,
  setLimitCandidateScoreboard,
  setSearchValueCandidateScoreboard,
  setSortByFilterCandidateScoreboard,
  setSortTypeFilterCandidateScoreboard,
  setTotalCountCandidateScoreboard,
} from "../store/reducers/candidateScoreboardReducer";

const { API_RECRUITMENT } = getEnv();

const hasAssessmentType = (assessmentType: string) => {
  return (
    assessmentType === ASSESSMENT_TYPE.DOMAIN_ASSESSMENT ||
    assessmentType === ASSESSMENT_TYPE.LIVE_TASK ||
    assessmentType === ASSESSMENT_TYPE.TAKE_HOME
  );
};

const CandidateScoreboardScreen = () => {
  const navigate = useNavigate();
  const scoreboardTable = useTypedSelector(
    (store) => store.candidate_scoreboard
  );

  const columns: Array<IColumns> = [
    {
      headerName: "Job Title",
      field: "title",
      pinnable: true,
      renderCell: (cellValue, rowData) => {
        return (
          <Link
            to={`/candidate/scoreboard/${rowData.jobId}`}
            className="candidate-table__link"
          >
            {rowData.job?.title || ""}
          </Link>
        );
      },
      mobileRenderCell: (cellValue, rowData) => {
        return (
          <Link to={`/job/${rowData.id}`} className="candidate-table__link">
            {rowData.job?.title || ""}
          </Link>
        );
      },
      visibility: true,
      isMobileTitle: true,
    },
    {
      headerName: "Company",
      field: "company",
      renderCell: (cellValue, rowData) => {
        // return cellValue.name || '';
        return (
          <div className="candidate-table__company">
            <img
              src={
                !!rowData.job?.comapny?.avatar
                  ? rowData.job?.comapny?.avatar
                  : defaultAvatar
              }
              alt="avatar"
              className="candidate-table__company__avatar"
            />
            <p className="candidate-table__company__text">
              {rowData.job.company.name || ""}
            </p>
          </div>
        );
      },
      mobileRenderCell: (cellValue, rowData) => {
        return (
          <div className="candidate-table__company">
            <img
              src={
                !!rowData.job?.comapny?.avatar
                  ? rowData.job?.comapny?.avatar
                  : defaultAvatar
              }
              alt="avatar"
              className="candidate-table__company__avatar"
            />
            <p className="candidate-table__company__text">
              {rowData.job?.company?.name || ""}
            </p>
          </div>
        );
      },
      visibility: true,
    },
    {
      headerName: "Assessment Name",
      field: "assessmentName",
      renderCell: (cellValue, rowData) =>
        `${rowData.job?.workflow?.title || ""}`,
      mobileRenderCell: (cellValue, rowData) =>
        `${rowData.job?.workflow?.title || ""}`,
      visibility: true,
    },
    {
      headerName: "TotalScores",
      field: "totalScores",
      renderCell: (cellValue, rowData) => `${rowData.totalScores || "0"}%`,
      mobileRenderCell: (cellValue, rowData) =>
        `${rowData.totalScores || "0"}%`,
      visibility: true,
      sort: true,
    },
    {
      headerName: "Actions",
      field: "actions",
      setActions: [
        {
          getAction: (rowData) => {
            if (rowData.round?.rounds) {
              const rounds = (rowData.round?.rounds).filter(
                (round) =>
                  hasAssessmentType(round.title) &&
                  ["Completed", "Rejected"].includes(round.status)
              );

              if (rounds && !!rounds.length) {
                return {
                  svg: (
                    <p className="candidate-table__link candidate-table__actions__text-link">
                      See Assessment Details
                    </p>
                  ),
                  title: "",
                  onClick: (rowData, event) => {
                    navigate(`/candidate/scoreboard/${rowData.jobId}`);
                  },
                  className: "",
                };
              }
            }
          },
        },
      ],
      visibility: true,
    },
  ];
  const createAPIString = () => {
    const pagination = scoreboardTable.pagination;
    return `${API_RECRUITMENT}/api/subscribe/candidate/scoreboard?limit=${
      pagination.limit
    }&offset=${(pagination.currentPage - 1) * pagination.limit}
    ${
      scoreboardTable.searchValue
        ? "&searchValue=" + scoreboardTable.searchValue
        : ""
    }
			${scoreboardTable.sortBy ? "&sortBy=" + scoreboardTable.sortBy : ""}
			${scoreboardTable.sortType ? "&sortType=" + scoreboardTable.sortType : ""}
    `;
  };

  const setSort = (field) => {
    store.dispatch(setSortByFilterCandidateScoreboard(field));
    const asc_desc =
      scoreboardTable.sortBy === field && scoreboardTable.sortType === "ASC"
        ? "DESC"
        : "ASC";
    store.dispatch(setSortTypeFilterCandidateScoreboard(asc_desc));
  };
  const setSerachValueCallback = (value: string) => {
    store.dispatch(setSearchValueCandidateScoreboard(value));
    store.dispatch(setCurrentPageCandidateScoreboard(1));
  };
  return (
    <>
      <section className="candidate-scoreboard">
        <div className="candidate-scoreboard__head">
          <div className="candidate-scoreboard__head__left">
            <h2 className="candidate-scoreboard__head__headline">Scoreboard</h2>
          </div>
        </div>
        <TableCardViewComp
          columns={columns}
          rowsData={{ api: createAPIString() }}
          sort={(field) => setSort(field)}
          store={store}
          storeName="candidate_scoreboard"
          useTypedSelector={useTypedSelector}
          pagination={{
            currentPage: scoreboardTable.pagination.currentPage,
            limit: scoreboardTable.pagination.limit,
            totalCount: scoreboardTable.pagination.totalCount,
            setCurrentPage: (page: number) => {
              store.dispatch(setCurrentPageCandidateScoreboard(page));
            },
            setLimit: (value: number) => {
              store.dispatch(setLimitCandidateScoreboard(value));
            },
            setTotalCount: (count: number) => {
              store.dispatch(setTotalCountCandidateScoreboard(count));
            },
          }}
          searchField={{
            placeholder: "Search job title",
            searchValue: scoreboardTable.searchValue,
            setSearchValue: setSerachValueCallback,
          }}
        />
      </section>
    </>
  );
};

export default CandidateScoreboardScreen;
