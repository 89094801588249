import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { getEnv } from "@urecruits/api";

const {API_RECRUITMENT} = getEnv()

export const selectSearchFuncLocation = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/location/${searchValue}`, getConfig())
		.then((response) => response.json())

	return req.map(item => {
		return {
			value: `${item.id}`,
			label: `${item.city}, ${item.state}`,
		}
	})
}

export const selectSearchFuncPosition = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/position/${searchValue}`, getConfig())
		.then((response) => response.json())

	return req
}
export const selectSearchFuncPositionLimit = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/position/filter?${searchValue}`, getConfig())
		.then((response) => response.json())

	return req
}
export const selectSearchFuncIndustry = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/industry/label/${searchValue}`, getConfig())
		.then((response) => response.json())

	return req
}