export enum MembersTab{
	NAME='Name',
	ID='ID',
	AGE='Age',
	DEPARTMENT='Department',
	ROLE='Role',
	EMAIL='Email',
	STATUS='Status',
	PHONE='Phone',
	DATE_OF_BIRTH='Date of Birth',
	DATE_OF_JOINING='Date of Joining',
	Education='Education',	
}
