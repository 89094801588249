const successSaveDraft = require("../../images/successSaveDraft.svg");

function DialogSuccessSaveDraft({ onDialog, onConfirm = () => { } }) {
  return (
    <div className="dialog-success" onClick={() => onDialog(false)}>
      <div
        className="dialog-success__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-success__head">
          <h2 className="dialog-success__head__title"></h2>
          <button
            className="dialog-success__head__cross-ico"
            onClick={() => {
              onDialog(true);
              onDialog(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <img
          className="dialog-success__center-img"
          src={successSaveDraft}
          alt="cancel-icon"
        />
        <h2 className="dialog-success__head__title">
          Successfully Sent to Draft
        </h2>
        <div style={{ marginLeft: "unset" }} className="dialog-success__footer">
          <div
            className="dialog-success__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="dialog-success__footer__container__gotit"
              onClick={() => onConfirm && onConfirm()}
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogSuccessSaveDraft;
