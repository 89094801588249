import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  AnalyticsAreaChartComponent,
  AnalyticsCircleChartComponent,
  getConfig, TableCardViewComp
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
// import useClickOutside from "../hook/useClickOutside";
// import { JobsTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import {
  defaultFiltersJobs,
  // setChangeWorkflowListJobs,
  setCurrentPageJobs,
  setDateSelectedJobs, setLimitJobs,
  setSearchValueJobs,
  setSortByFilterJobs,
  setSortTypeFilterJobs,
  setStatusFilterJobs,
  setTableItemsJobs,
  setTopButtonsJobs,
  setTotalCountJobs
} from "../store/reducers/jobsTableReducer";
import {
  setAreaJobs,
  setAreaDuration,
  setAreaStatus,
} from "../store/reducers/jobDetailViewReducerArea";
import {
  setPieDuration,
  setPieJobs,
} from "../store/reducers/jobDetailViewReducerPie";
import { sortingFunc } from "../utils/sortingFuncForTables";
import JobsFilterItems from "../components/Jobs/JobsFilterItems";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { IJobsTableItem } from "../types/redux/jobs";
import { transformDate } from "../utils/transformDate";
// import DeletePopupJobs from "../components/Jobs/popups/DeletePopupJobs";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
// import ApproveJobPopup from "../components/Jobs/popups/ApproveJobPopup";
// import RejectPopupJobs from "../components/Jobs/popups/RejectPopupJobs";
// import ChangeWorkflowPopup from "../components/Jobs/popups/ChangeWorkflowPopup";

const { API_RECRUITMENT, API_HR_ANALYTICS } = getEnv();

const getFilters = (state) => state.jobs.filters;
const tableFunc = (state) => state.jobs;
const jobsAreaFunc = (state) => state.jobs_area;
const jobsPieFunc = (state) => state.jobs_pie;
const getFilterDataFunc = (state) => state.jobs.filterInfo;
const getLimitFunc = (state) => state.jobs.pagination.limit;
const FilterItems = (state) => <JobsFilterItems activeTab={state} />;


const getJobStatus = (status) => {
  if (status === "publish") return {label:"Published", className:"green"};
  else if (status === "approval") return {label:"Pending", className:"yellow"};
  else if (status === "draft") return {label:"Draft", className:"red"};
  else if (status === "closed") return {label:"Closed", className:"gray"};
  else if (status === "onhold") return {label:"On Hold", className:"orange"};
  else return {label:"Pending", className:"yellow"};
}

const columns: Array<IColumns> = [
  {
    field: "jobTitle",
    headerName: "Job Title",
    pinnable: true,
    visibility: true,
    isMobileTitle: true,
    renderCell(cellValue, rowData) {
      return <>
        {rowData.status === "publish" ? (
          <Link
            to={`/recruitment/job/${rowData.id}/matched-profiles`}
            className="manage-team__text manage-team__text--link jobs-table__text__capitalize "
          >
            {cellValue}
          </Link>
        ) : (
          <p className="manage-team__text manage-team__text--green jobs-table__text__capitalize ">
            {cellValue}
          </p>
        )}
      </>
    },
    mobileRenderCell(cellValue, rowData) {
      return <>
        {rowData.status === "publish" ? (
          <Link
            to={`/recruitment/job/${rowData.id}/matched-profiles`}
            className="manage-team__text manage-team__text--link jobs-table__text__capitalize "
          >
            {cellValue}
          </Link>
        ) : (
          <p className="manage-team__text manage-team__text--green jobs-table__text__capitalize ">
            {cellValue}
          </p>
        )}
      </>
    },
  },
  {
    field: "employer",
    headerName: "Employer",
    visibility: true,
  },
  {
    field: 'salary',
    headerName: 'Salary Range',
    visibility: true,
    renderCell: (cellValue, rowData) => {
      return <>
        <p className="manage-team__text manage-team__text--gray7 jobs-table__text__salary">
          {cellValue}
          <span className="manage-team__text manage-team__text--gray5">
            {" "}
            (per year)
          </span>
        </p>
      </>
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <>
        <p className="manage-team__text manage-team__text--gray7 jobs-table__text__salary">
          {cellValue}
          <span className="manage-team__text manage-team__text--gray5">
            {" "}
            (per year)
          </span>
        </p>
      </>
    }
  },
  {
    field: 'postedOn',
    headerName: 'Posted On',
    visibility: true,
  }, {
    field: 'status',
    headerName: 'Status',
    visibility: true,
    renderCell: (cellValue, rowData) => {
      const status = getJobStatus(cellValue);
      return <>
        <div className="table-dropdown-with-color-btn">
          <div
            className={"table-dropdown-with-color-btn__wrap"}
          >
            <div className="manage-team__text manage-team__text--black table-dropdown-with-color-btn__button ">
              <p className={`table__status-block--${status.className}`}>
                {status.label}
              </p>
            </div>
          </div>
        </div>
      </>
    },
    mobileRenderCell: (cellValue, rowData) => {
      const status = getJobStatus(cellValue);
      return <>
        <div className="table-dropdown-with-color-btn">
          <div
            className={"table-dropdown-with-color-btn__wrap"}
          >
            <div className="manage-team__text manage-team__text--black table-dropdown-with-color-btn__button ">
              <p className={`table__status-block--${status.className}`}>
                {status.label}
              </p>
            </div>
          </div>
        </div>
      </>
    }
  }
]
const JobsReportScreen = () => {
  const [orderPopup, setOrderPopup] = useState(false);
  const [horizontalScrollState, setHorizontalScrollState] = useState(false);
  const navigate = useNavigate();
const [isFilerSubmit,setIsFilterSubmit] = useState(false);
const [isEmptyFilter,setIsEmptyFilter] = useState(false);
  const orderPopupRef = useRef<null | HTMLButtonElement>(null);
  const tableRef = useRef(null);

  const table = useTypedSelector(tableFunc);
  const area = useTypedSelector(jobsAreaFunc);
  const pie = useTypedSelector(jobsPieFunc);


  const filterJobsByStatus = (status) => {
    let statusVal;
    if (area && area.status === "posted") statusVal = "publish";
    else if (area && area.status === "approval") statusVal = "approval";
    else if (area && area.status === "draft") statusVal = "draft";
    setJobsAreaHandler(area.duration, status);
  };

  const areaChartOperations = [
    {
      buttonText: "Posted Jobs",
      onChange: filterJobsByStatus,
      isActive: area && area.jobStatus === "publish" ? "active" : "",
      buttonValue: "publish",
    },
    {
      buttonText: "On Hold Jobs",
      onChange: filterJobsByStatus,
      isActive: area && area.jobStatus === "onhold" ? "active" : "",
      buttonValue: "onhold",
    },
    {
      buttonText: "Draft",
      onChange: filterJobsByStatus,
      isActive: area && area.jobStatus === "draft" ? "active" : "",
      buttonValue: "draft",
    },
    {
      buttonText: "Closed",
      onChange: filterJobsByStatus,
      isActive: area && area.jobStatus === "closed" ? "active" : "",
      buttonValue: "closed",
    },
  ];
  const changeAreaHandler = (duration) => {
    setJobsAreaHandler(duration, area.jobStatus);
  };
  const changePieDurationHandler = (duration: string) => {
    setJobsPieHandler(duration);
  };
  const filterArray = [
    table.filters.searchValue,
    table.filters.sortBy,
    table.filters.sortType,
    table.filters.date.from,
    table.filters.date.to,
    table.filters.status,
    table.pagination.limit,
  ];
  useLayoutEffect(() => {
    setJobsHandler(
      table.pagination.currentPage,
      table.pagination.limit,
      table.filters
    );
    setJobsAreaHandler(area.duration, area.jobStatus);
    setJobsPieHandler(pie.duration);
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeFunc);

    return () =>
      document.removeEventListener("visibilitychange", visibilityChangeFunc);
  }, [...filterArray, table.pagination.currentPage]);

  const visibilityChangeFunc = () => {
    if (document.visibilityState === "visible") {
      setJobsHandler(
        table.pagination.currentPage,
        table.pagination.limit,
        table.filters
      );
    }
  };

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValueJobs(value));
    setJobsHandler(1, table.pagination.limit, {
      ...table.filters,
      searchValue: value,
    });
  }, filterArray);

  const emptyTableCallback = useCallback(() => {
    navigate("/job/create");
  }, []);

  const tdOrderCallback = useCallback(
    (value) =>
      sortingFunc(
        table,
        value,
        setSortTypeFilterJobs,
        setSortByFilterJobs,
        setJobsHandler
      ),
    filterArray
  );
  useTableClickAndDragScroll(tableRef);
  return (
    <section className="jobs-report">
      <Outlet />

      <div className="hr-analytics__table-screen-top">
        <ul className="hr-analytics__table-screen-top__breadcrumbs">
          <li className="hr-analytics__table-screen-top__breadcrumbs__item">
            <Link
              to="/hr-analytics/reports"
              className="hr-analytics__table-screen-top__breadcrumbs__link"
            >
              Reports Center
            </Link>
          </li>
          <li className="hr-analytics__table-screen-top__breadcrumbs__item">
            <p className="hr-analytics__table-screen-top__breadcrumbs__link disabled">
              Job Report
            </p>
          </li>
        </ul>
        <div className="hr-analytics__table-screen-top__wrapper">
          <h2 className="hr-analytics__table-screen-top__title">Job Report</h2>
        </div>
      </div>

      <div className="graph-wrapper">
        <div className="graph-job-summary-circle">
          <AnalyticsCircleChartComponent
            title={"Job Summary"}
            pieChartData={{
              data: pie.jobsData,
              totalCount: pie.totalJobs,
              showCount: true
            }}
            durantionChange={{
              changePieDurationHandler: changePieDurationHandler,
              duration: pie.duration
            }}
            pieColors={['#029CA5', '#FF8541', '#737980', '#FEE045', '#FE4672']}
            countText={"Total jobs"}
          />
        </div>
        <div className="graph-job-summary-area">
          <AnalyticsAreaChartComponent
            title=""
            data={{
              data: area.jobsData,
              duration: area.duration
            }}
            areaChartOperations={areaChartOperations}
            dataKey="doc_count"
            changeAreaHandler={changeAreaHandler}
            xAxisTickDataKey="key_as_string"
          />
        </div>
      </div>
      <TableCardViewComp
      columns={columns}
      rowsData={{rowData:table.tableItems}}
      searchField={{
        searchValue: table.filters.searchValue,
        setSearchValue: setSearchCallback,
        placeholder: "Search jobs"
      }}
      filters={{
        filter: table.filters,
        filterData: table.filterInfo,
        filterItems: FilterItems,
        resetFilters: resetFunc,
        isFilterSubmit:isFilerSubmit,
        setIsFilterSubmit:setIsFilterSubmit,
        isEmptyFilter:isEmptyFilter,
        setIsEmptyFilter:setIsEmptyFilter
      }}
      />
    </section>
  );
};
export default memo(JobsReportScreen);

export const setJobsHandler = (page: number, limit: number, filters: any) => {
  store.dispatch(setCurrentPageJobs(page));
  const getData = async () => {
    try {
      await axios(
        `${API_HR_ANALYTICS}/api/analytics/jobs/?limit=${limit}
		&offset=${(page - 1) * limit}
		&search=${encodeURIComponent(filters.searchValue)}
		&dateFrom=${filters.date.from ? toJSONLocalPlusDay(filters.date.from) : ""}
		&dateTo=${filters.date.to ? toJSONLocalPlusDay(filters.date.to) : ""}
		${filters.status.map((item) => `&status=${item}`).join("")}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`,
        getConfig()
      ).then((res) => {
        store.dispatch(setTotalCountJobs(res.data.total.value));
        const items = transformArrayToTableData(res.data.hits);
        store.dispatch(setTableItemsJobs(items));

        if (filters.status.length === 1) {
          if (filters.status[0] === "publish")
            store.dispatch(setTopButtonsJobs("posted"));
          else if (filters.status[0] === "approval")
            store.dispatch(setTopButtonsJobs("approval"));
          else if (filters.status[0] === "draft")
            store.dispatch(setTopButtonsJobs("draft"));
          else store.dispatch(setTopButtonsJobs("all"));
        } else {
          store.dispatch(setTopButtonsJobs("all"));
        }
      });
    } catch (err) {
      console.log("Error fetching jobs table data", err);
    }
  }
    ;
  getData().then();
};
export const setJobsAreaHandler = (duration: string, status: string) => {
  const getData = async () => {
    try {
      store.dispatch(setAreaDuration(duration));
      store.dispatch(setAreaStatus(status));
      await axios(
        `${API_HR_ANALYTICS}/api/analytics/jobs/area?duration=${duration}
		&Jobstatus=${status}`,
        getConfig()
      ).then((res) => {
        store.dispatch(setAreaJobs(res.data.jobs_status.buckets));
      });
    } catch (err) {
      console.log("Error fetching jobs area data", err);
    }

  }
  getData().then();
};

export const setJobsPieHandler = (duration: string) => {
  const getData = async () => {
    try {

      store.dispatch(setPieDuration(duration));
      await axios(
        `${API_HR_ANALYTICS}/api/analytics/jobs/pie?duration=${duration}`,
        getConfig()
      ).then((res) => {
        const data = res.data.jobs_status.buckets;
        let piData = [];
        if (data) {
          Object.keys(data).forEach((key) => {
            piData.push({ key: key.slice(0, 1).toUpperCase() + key.slice(1), doc_count: data[key].doc_count })
          })
        }
        if (piData.length > 0) {
          let transformData = []
          if (piData.find(i => i.key == 'Publish')) {
            transformData.push(piData.find(i => i.key == 'Publish'))
          }
          if (piData.find(i => i.key == 'On-hold')) {
            transformData.push(piData.find(i => i.key == 'On-hold'))
          }
          if (piData.find(i => i.key == 'Closed')) {
            transformData.push(piData.find(i => i.key == 'Closed'))
          }
          if (piData.find(i => i.key == 'Pending')) {
            transformData.push(piData.find(i => i.key == 'Pending'))
          }
          if (piData.find(i => i.key == 'Draft')) {
            transformData.push(piData.find(i => i.key == 'Draft'))
          }

          piData = transformData
        }
        store.dispatch(
          setPieJobs({
            jobsData: piData,
            totalJobs: res.data.total_jobs.value,
          })
        );
      });
    } catch (err) {
      console.log("Error fetching jobs pie data", err);
    }
  };
  getData().then();
};

export function toJSONLocalPlusDay(date: any) {
  let local = new Date(date);
  local.setDate(local.getDate() + 1);

  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

const setLimitHandler = (value: any) => {
  store.dispatch(setLimitJobs(value));
};
const transformArrayToTableData = (array: any): Array<IJobsTableItem> => {
  //TODO add missed fields
  return array.map((itemData) => {
    const { job_posted: item } = itemData._source;
    return {
      id: item.id,
      jobTitle: item.title,
      salary: `$${item.salaryYearMin} - $${item.salaryYearMax}`,
      postedOn: transformDate(item.createdAt),
      status: item.status,
      employer: item.employer,
      remoteLocations: item.remoteLocation,
    };
  });
};
const resetFunc = (pagination) => {
  store.dispatch(setDateSelectedJobs({ from: null, to: null }));
  store.dispatch(setSortByFilterJobs("id"));
  store.dispatch(setSortTypeFilterJobs("DESC"));
  store.dispatch(setSearchValueJobs(""));
  store.dispatch(setStatusFilterJobs([]));
  setJobsHandler(1, pagination, defaultFiltersJobs);
};
