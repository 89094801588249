import { memo, useState } from "react";
import { getEnv } from "@urecruits/api";

import deleteIc from "../../image/icon/delete_image.svg";
import axios from "axios";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv();

const DeletePicturePopup = ({setDeletePicturePopup, deletePicturePopup, allImages, setRefetch}) => {
	const token: string = localStorage.getItem("token");
	const [errorNotification, setErrorNotification] = useState("");

	const onDeletePictureHandler = async () => {
		const result = allImages.filter(dataItem => dataItem.location !== deletePicturePopup.location);

		await axios.patch(`${API_RECRUITMENT}/api/company`, {gallery: result},
			getConfig())
			.then(res => {
				if (res.status === 200) {
					setDeletePicturePopup({...deletePicturePopup, visible: false, name: "", currentItem: ""});
					setRefetch(new Date());
				}
			});
	};

	return (
		<div className="popup">
			<div className="popup__step">
				<div className="popup__step__head">
					<p className="popup__step__headline">
						Delete image
					</p>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
					     className="popup__step__close"
					     onClick={() => setDeletePicturePopup({...deletePicturePopup, visible: false})}
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
				<div className="popup__step__body">
					<img src={deleteIc} alt="delete icon" className="popup__step__image"/>
					<p className="popup__step__info">
						{`Are you sure you want to delete ${deletePicturePopup.name}?`}
					</p>
				</div>
				<div className="popup__step__control end">
					<div
						className="popup__step__button button--empty"
						onClick={() => setDeletePicturePopup({...deletePicturePopup, visible: false})}
					>
						Cancel
					</div>
					<div
						className="popup__step__button button--filled"
						onClick={onDeletePictureHandler}
					>
						Delete
					</div>
					{
						errorNotification.length > 0 && (
							<p className="error-message">{errorNotification}</p>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default memo(DeletePicturePopup);