import { getEnv } from "@urecruits/api";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import { store, useTypedSelector } from "../store";
import { setOffer } from "../store/reducers/offerLetterReducer";
import HelloSign from "hellosign-embedded";
import { EditorModals } from "../components/Popups/editor-modals";
import { OfferLetterStatus, OfferKeys } from "../enums/usersOffers/UsersOffersEnums";
import { Link } from "react-router-dom";
import { getConfig, SmallLoaderComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();
const client = new HelloSign();
const params = {offerId : "", jobId:'', userId:''};
const url = window.location.origin;
client.on('createTemplate', (data) => {
    const patchData = async () => {
        await axios.patch(
            `${API_RECRUITMENT}/api/offer/${params.offerId}`, 
            {
                // [OfferKeys.HELLOSIGNTEMPLATEID] : data.templateId, 
                [OfferKeys.LETTERSTATUS]: OfferLetterStatus.GENERATED},
            getConfig()
        ).then(() => {
           window.location.href = `${url}/recruitment/offers/preview/${params.jobId}/${params.userId}`;
        });
    };
   patchData().then();

});


const getOfferFunc = state => state.offer_letter.offer;
const OfferViewScreen = () => {
    const [modal, setModal] = useState<boolean | string>(false);
    const [openTemplate, setOpenTemplate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { offerId } = useParams();
    params.offerId = offerId
    const offer = useTypedSelector(getOfferFunc);
    params.jobId = offer.jobId;
    params.userId = offer.userId;
    
    if(openTemplate){
        const getTemplate = async()=>{
            const templateUrl = await getTemplateUrl(offerId);
            client.open(templateUrl, {
                clientId: "f7936db2d10d97599d15ca1514dbecbf",
                skipDomainVerification: true,
                //redirectTo:`${url}/recruitment/offers/preview/${offerId}`              
            });
        }  
        getTemplate();  
    }
    

    const handleHellosign = async () => {
         setModal("signature");
         setOpenTemplate(false);
   
    }

    useLayoutEffect(() => {
        setIsLoading(true);
        setOfferHandler(offerId).finally(()=>setIsLoading(false));
        setOpenTemplate(false);
    }, [offerId]);


    return (
        <>
        {isLoading ? <SmallLoaderComponent/> :
        (  <section className="offer-view">
        <div className="offer-view__head">
            <div className="offer-preview__head__left">
                <p className="offer-view__head__headline">Offer Letter: {offer.jobTitle} </p>
            </div>
            <div className="modal__actions">
              <Link
                className="modal__actions__cancel__text"
                to={`/recruitment/offers/${offer.jobId}`}
              >
                Back to Candidate List
              </Link>
              </div>
        </div>

        <div className="offer-view__inner">
            <div className="offer-body">
                <div dangerouslySetInnerHTML={{ __html: offer.offerBody }} />
            </div>
        </div>

        <EditorModals modal={modal} setModal={setModal} setOpenTemplate={setOpenTemplate}/>

        <div className="offer-view__footer">
            <button className="offer-view__footer__action" onClick={handleHellosign}>Add signatures</button>
        </div>
    </section>)}
    </>
    );
}


const getTemplateUrl = async (offerId) => {
    const response = await axios.get(`${API_RECRUITMENT}/api/offer/hellosign/create-embedded-template-draft/${offerId}`, getConfig());
    return response.data.template.edit_url;
}

export const setOfferHandler = async (offerId) => {
       if(!offerId) return null;
   
       const response =  await axios(
            `${API_RECRUITMENT}/api/offer/${offerId}`,
            getConfig()
        ).then(({ data }) => {
            store.dispatch(setOffer(data));
        });

        return response;
}

export default OfferViewScreen;