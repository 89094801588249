import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";

export const postData = async (api, data) => {
  const res = await axios.post(api, data, getConfig());
  if (res && res.data) {
    return res.data;
  }
};

export default postData;
