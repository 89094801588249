import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent,
	AuthGuard, useHasPermission,
	getConfig
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { JobsDetailViewTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { IJobsTableItem } from "../types/redux/jobs";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";

import {
	defaultFiltersDetailViewJobs,
	seTableItemsDetailViewJob, setApplyJobDetailViewJob,
	setCurrentPageDetailViewJobs,
	setEducationalFilterDetailViewJob,
	setEmptySearchDetailViewJob,
	setEmptyTableDetailViewJob,
	setExperienceFilterDetailViewJob,
	setLimitDetailViewJobs,
	setLocationFilterDetailViewJob,
	setPercentFilterDetailViewJob,
	setSalaryYearFilterDetailViewJob,
	setSearchValueDetailViewJob,
	setSelectedItemsDetailViewJob,
	setSkillsFilterDetailViewJob,
	setSortByDetailViewJob,
	setSortTypeDetailViewJob,
	setStatusFilterDetailViewJob, setTitleDetailViewJob,
	setTotalCountDetailViewJobs
} from "../store/reducers/jobDetailViewReducerMatched";
import TableCheckbox from "../components/Global/table/TableCheckbox";
import MatchedJobFilter from "../components/DetailedViewJob/MatchedProfile/MatchedJobFilter";
import MatchedProfileTBody from "../components/DetailedViewJob/MatchedProfile/MatchedProfileTBody";
import MobileTableDetailedView from "../components/DetailedViewJob/MatchedProfile/MobileTableDetailedView";
import StartWorkflowPopup from '../components/DetailedViewJob/MatchedProfile/StartWorkflowPopup';
const {API_RECRUITMENT} = getEnv()
const getFilters = state => state.jobs_matched.filters
const tableFunc = state => state.jobs_matched
const getFilterDataFunc = state => state.jobs_matched.filterInfo
const getLimitFunc = state => state.jobs_matched.pagination.limit
//TODO replace component
const FilterItems = state => <MatchedJobFilter activeTab={state}/>
const FilterPopupInnerWrap = (setState) => {
	const params = useParams()
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const limit = useTypedSelector(getLimitFunc)
	const filterArray = [
		filters.searchValue, filters.sortBy, filters.sortType, limit, filters.location, filters.education,
		filters.skills, filters.experience, filters.salary_year, filters.percentage, filters.status, filters.applyJob
	]
	const setDataCallback = useCallback((page: number, limit: number, filters: any) => {
		setDetailViewJobsHandler(page, limit, filters, params.id)
	}, [filterArray],);
	const resetFunc = useCallback((pagination) => {
		store.dispatch(setSkillsFilterDetailViewJob([]))
		store.dispatch(setPercentFilterDetailViewJob([0, 100]))
		store.dispatch(setEducationalFilterDetailViewJob([]))
		store.dispatch(setSalaryYearFilterDetailViewJob([0, 1000000]))
		store.dispatch(setExperienceFilterDetailViewJob([0, 50]))
		store.dispatch(setLocationFilterDetailViewJob([]))
		store.dispatch(setSearchValueDetailViewJob(''))
		store.dispatch(setSortByDetailViewJob('id'))
		store.dispatch(setSortTypeDetailViewJob('ASC'))
		store.dispatch(setStatusFilterDetailViewJob([]))
		store.dispatch(setApplyJobDetailViewJob(false))
		setDataCallback(1, pagination, defaultFiltersDetailViewJobs)
	}, [limit],);
	return <FilterWrapper setFilterPopup={setState}
	                      resetFunc={resetFunc}
	                      filters={filters}
	                      filterData={filtersData}
	                      submitFunc={setDataCallback}
	                      limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}
const JobsScreen = () => {
	const{checkUserPermission} = useHasPermission()
	const [orderPopup, setOrderPopup] = useState(false);
	const [popup, setPopup] = useState({visible:false,jobId:0,userId:0,userName:''});
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const [refetch, setRefetch] = useState(true);
	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);

	const table = useTypedSelector(tableFunc)

	useLayoutEffect(() => {
		if(refetch){
			checkUserPermission('job-post','view') && setDataCallback(table.pagination.currentPage, table.pagination.limit, table.filters)
			setRefetch(false)
		}
		return () => {
			store.dispatch(setEmptySearchDetailViewJob(false))
			store.dispatch(setEmptyTableDetailViewJob(false))
			store.dispatch(seTableItemsDetailViewJob([]))
			resetFunc(table.pagination.limit)
			setRefetch(false)
		}
	}, [refetch]);

	const showStartWorkflowPopup=({userId,userName})=>{
		setPopup(prev=>({...prev,visible:true,jobId:+params.id,userId,userName}))
	}
	const closeStartWorkflowPopup=()=>{
		setPopup(prev=>({...prev,visible:false,jobId:null,userId:null,userName:''}))
	}

//TODO copy of this array in filterPopupInnerWrap comp
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination.limit, table.filters.location, table.filters.education,
		table.filters.skills, table.filters.experience, table.filters.salary_year, table.filters.percentage, table.filters.status, table.filters.applyJob
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueDetailViewJob(value))
		setDataCallback(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);

	const emptyTableCallback = useCallback(() => {
		navigate('/recruitment/jobs')
	}, [])
	const checkBoxHandlerAll = useCallback(() => {
		if (table.selectedItems.length === table.tableItems.length && table.tableItems.length !== 0) {
			store.dispatch(setSelectedItemsDetailViewJob([]))
		} else {
			store.dispatch(setSelectedItemsDetailViewJob(table.tableItems.map((item) => item.id)))
		}
	}, [table.selectedItems, table.tableItems],);

	//TODO:copy of this functions in filterPopupInnerWrap comp
	const setDataCallback = useCallback((page: number, limit: number, filters: any) => {
		setDetailViewJobsHandler(page, limit, filters, params.id)
	}, [filterArray],);
	const resetFunc = useCallback((pagination) => {
			store.dispatch(setSkillsFilterDetailViewJob([]))
			store.dispatch(setPercentFilterDetailViewJob([0, 100]))
			store.dispatch(setEducationalFilterDetailViewJob([]))
			store.dispatch(setSalaryYearFilterDetailViewJob([0, 1000000]))
			store.dispatch(setExperienceFilterDetailViewJob([0, 50]))
			store.dispatch(setLocationFilterDetailViewJob([]))
			store.dispatch(setSearchValueDetailViewJob(''))
			store.dispatch(setSortByDetailViewJob('id'))
			store.dispatch(setSortTypeDetailViewJob('ASC'))
			store.dispatch(setStatusFilterDetailViewJob([]))
			store.dispatch(setApplyJobDetailViewJob(false))
			setDataCallback(1, pagination, defaultFiltersDetailViewJobs)
		},
		[table.pagination.limit],
	);

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeDetailViewJob, setSortByDetailViewJob, setDataCallback), filterArray,);
	useTableClickAndDragScroll(tableRef)
	useClickOutside(orderPopupRef, setOrderPopup)
	return <section>
		<AuthGuard module='job-post' permission='view'>
			{
				popup.visible && <StartWorkflowPopup onClose={closeStartWorkflowPopup} popup={popup} setRefetch={setRefetch}/>
			}
		<div className='table-screen-top'>
			<div className='table-screen-top__wrapper'>
				<h2>{table.title}</h2>
				<div className='table-screen-top__buttons-group'>
					<button
						className={`table-screen-top__buttons-group__item ${table.filters.applyJob ? '' : 'active'}`}
						onClick={() => {
							store.dispatch(setApplyJobDetailViewJob(false))
							setDataCallback(1, table.pagination.limit, {...table.filters, applyJob: false})
						}}>
						Matched profiles
					</button>
					<button
						className={`table-screen-top__buttons-group__item ${table.filters.applyJob ? 'active' : ''}`}
						onClick={() => {
							store.dispatch(setApplyJobDetailViewJob(true))
							setDataCallback(1, table.pagination.limit, {...table.filters, applyJob: true})
						}}>
						Applied candidates
					</button>
					<button className='table-screen-top__buttons-group__item' onClick={()=>navigate(`/job/${params.id}`)} >
						Job view
					</button>
				</div>
			</div>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<TopFilterButton>
						{
							FilterPopupInnerWrap
						}
					</TopFilterButton>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={'Search candidates'}/>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup}/>
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<JobsDetailViewTableOrder setOrderPopup={setOrderPopup}/>
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptySearch ? <NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc}/>
							: <TableEmpty handler={emptyTableCallback} cta={'To jobs'} title={'Table is empty'}
							              desc={'There no users yet'}/>
					)
					:
					<>
						<table className='table' ref={tableRef} onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">

							<td
								className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead jobs-table__column__middle`}>
								<TableCheckbox id={'all'}
								               handler={checkBoxHandlerAll}
								               checked={table.selectedItems.length === table.tableItems.length && table.tableItems.length !== 0}/>
								<TheadItemComponent title={table.fixedTab.displayName} handler={null} dbName={''}/>
							</td>
							{
								table.tabFilter.map((item) => {
									return item.active &&
                      <td className={`table__td  table__td--thead jobs-table__column__default`}
                          key={item.id}>
                          <TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
                                              dbName={item.dbName}/>
                      </td>
								})
							}

							</thead>
							<MatchedProfileTBody horizontalScrollState={horizontalScrollState} showPopup={showStartWorkflowPopup}/>
						</table>

						<div className='table-mobile'>
							{
								table.tableItems.map(item => {
									return <MobileTableDetailedView key={item.id} item={item} showPopup={showStartWorkflowPopup} />
								})
							}
						</div>
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setDataCallback}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
					</>
			}
		</div>
		</AuthGuard>
	</section>
}
export default memo(JobsScreen)


export const setDetailViewJobsHandler = (page: number, limit: number, filters: any, params: string) => {
	store.dispatch(setCurrentPageDetailViewJobs(page))
	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/subscribe/scoreboard/${params}?limit=${limit}
			&offset=${(page - 1) * limit}
			&search=${encodeURIComponent(filters.searchValue)}
			&percentageMin=${filters.percentage[0]}
			&percentageMax=${filters.percentage[1]}
			&salaryYearMin=${filters.salary_year[0]}
			&salaryYearMax=${filters.salary_year[1]}
			&experienceMin=${filters.experience[0]}
			&experienceMax=${filters.experience[1]}
			${filters.status.map(item => `&status=${item}`).join("")}
			${filters.location.map(item => `&locations=${item.value}`).join("")}
			${filters.education.map(item => `&degree=${item}`).join("")}
			${filters.skills.map(item => `&skills=${item}`).join("")}
			&sortType=${filters.sortType}
			&applyJob=${filters.applyJob ? "applyJob" : ""}
			&sortBy=${filters.sortBy}`, getConfig()).then(res => {
			store.dispatch(setTotalCountDetailViewJobs(res.data.scoreboard?.count))
			const items = transformArrayToTableData(res.data.scoreboard?.rows)
			store.dispatch(seTableItemsDetailViewJob(items))
			store.dispatch(setTitleDetailViewJob(res.data?.job?.title))
			if (items.length === 0) {
				if (filters.searchValue === '' && filters.location.length === 0 && filters.education.length === 0 && filters.skills.length === 0 &&
					filters.experience[0] === 0 && filters.experience[1] === 50 && filters.salary_year[1] === 1000000 && filters.salary_year[0] === 0 && filters.percentage[0] === 0
					&& filters.percentage[1] === 100 && filters.status.length === 0
				) {
					store.dispatch(setEmptyTableDetailViewJob(true))
				} else {
					store.dispatch(setEmptySearchDetailViewJob(true))
				}
			} else {
				store.dispatch(setEmptySearchDetailViewJob(false))
				store.dispatch(setEmptyTableDetailViewJob(false))
			}
		})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitDetailViewJobs(value))
}
const transformArrayToTableData = (array: any): Array<IJobsTableItem> => {
	return array.map(item => {
		return {
			id: item.user.id,
			firstname: item.user.firstname,
			middlename: item.user.middlename,
			lastname: item.user.lastname,
			avatar: item.user.avatar,
			percent: item.match,
			location: item.user.candidate.location?.city ? `${`${item.user.candidate.location?.city}, ${item.user.candidate.location?.state}`}` : "",
			experience: item.user.candidate.experience,
			salary: item.user.candidate.preferencesExpectedCTC,
			phone: item.user.phone,
			email: item.user.email,
			status: item.status,
			scores: item.applyJob ? item.totalScores || 0 : "",
			summary: !!item.summary,
			workflow:!!item.job?.workflowId,
			applyJob:item.applyJob
		}
	})
}
