import { useSelector } from "react-redux";
import { footerSelectors } from "../../store/footer/footer.selectors";

export const Terminal = () => {
  const { isTerminalLoading, submissionResults } = useSelector(footerSelectors.getAllState);
  return (
    <div className="test-cases">
      {/* TODO:// Split component is commented because it is causing error. The error needs to be fixed */}
      {/* <Split
        sizes={[99, 1]}
        direction="vertical"
        className={cn("split-vertical")}
        style={{ width: "325px" }}
        gutterSize={1}
       
        > */}
      {
        isTerminalLoading ? <p>Loading...</p>
          : 
          <>

            {(!isTerminalLoading && !!submissionResults.length) &&
              submissionResults.map((result, index) => (
                <div className="test-cases__container" key={result.token || index}>
                  {submissionResults.length > 1 && <p className="test-cases__result-sequence">{`Result-${index + 1}`}</p>}
                  {!!result?.stdout || !!result?.compile_output ? (
                    <>
                      <span className="test-cases__result__title">Code result:</span>
                      <span dangerouslySetInnerHTML={{ __html: result?.stdout?.replace(/\n/g, '<br>') }} className="test-cases__result__message" />
                      <span className="test-cases__result__message">{result?.compile_output}</span>
                    </>
                  ) : !!result?.message ? (
                    <>
                      <span className="test-cases__result__title error">Error:</span>
                      <span className="test-cases__result__message">
                        {result?.message}
                      </span>
                      <span dangerouslySetInnerHTML={{ __html: result?.stderr?.replace(/\n/g, '<br>') }} className="test-cases__result__message error" />
                    </>

                  ) : null}
                </div>
              )) 
            }
            {
              !isTerminalLoading && !submissionResults.length &&  (
                <p>Hit run to try out some code!</p>
              )
            }
            {/* <div className="test-cases__footer">
          <EditorFeedback />
        </div> */}

            {/* </Split> */}
          </>}
    </div>
  );
};
