import { memo, useEffect, useRef, useState } from 'react';
import ExperienceDetailsTabItem from './ExperienceDetailsTabItem';
import axios from 'axios';
import { positionData } from '../../../staticData/positionData';
import { getEnv } from "@urecruits/api";
import patchData from "../../../hook/patchData";
import { AuthGuard, getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const editIc = require('../../../image/icon/edit_ic.svg')
const plusIc = require('../../../image/icon/plus_ic.svg')

const ExperienceDetailsTab = ({ data, setRefetch, countryList, positionIndustryList }) => {
  const token: string = localStorage.getItem('token')
  const [validateStatus, setValidateStatus] = useState(false)
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [addedMoreClicked, setAddedMoreClicked] = useState(false)
  const addedMoreButtonRef = useRef(null)
  const submitButtonRef = useRef(null)

  //form state
  const [experienceDetails, setExperienceDetails] = useState([])

  useEffect(() => {
    if (data) {
      setExperienceDetails(data.experienceDetails)
      setAddedMoreClicked(() => false)
    }
  }, [data])

  const onFormChange = () => {
    setDisplayButton(true)
  }
  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)
    setExperienceDetails(data.experienceDetails)
  }
  const {API_RECRUITMENT} = getEnv()

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidateStatus(false)

    if (validateStatus) {
      setSuccess(true)
      for (const item of experienceDetails) {
         await patchData(`${API_RECRUITMENT}/api/recruiter/experience`, item).then((res) => {
          res.status === 200 ? setSuccess(true) : setSuccess(false)
        })
      }
      setRefetch(new Date())

      setTimeout(() => {
        setSuccess(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }

  const onAddEducationHandler = async () => {
    if (editMode) {
      // empty object
      const obg = {
        companyName: '',
        position: '',
        location: '',
        start: null,
        end: null,
        recruiterId: data.id,
      }

      setValidateStatus(false)

      if (validateStatus && !addedMoreClicked) {
        setAddedMoreClicked(() => true)
        for (const item of experienceDetails) {
          await patchData(`${API_RECRUITMENT}/api/recruiter/experience`, item).then((res) => {

          })
        }
        await axios.post(`${API_RECRUITMENT}/api/recruiter/experience`, obg, getConfig()).then((res) => {
          setRefetch(new Date())
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }

  return (
    <AuthGuard module='recruiter'>
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Experience Details {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        {
          experienceDetails.map((item, index) => {
            return (
              <ExperienceDetailsTabItem
                experienceDetails={experienceDetails}
                item={item}
                setExperienceDetails={setExperienceDetails}
                editMode={editMode}
                key={item.id}
                validateStatus={validateStatus}
                setValidateStatus={setValidateStatus}
                displayButton={displayButton}
                addedMoreButtonRef={addedMoreButtonRef}
                submitButtonRef={submitButtonRef}
                index={index}
                countryList={countryList}
                recruiterId={data.id}
                setRefetch={setRefetch}
                setDisplayButton={setDisplayButton}
                positionOption={positionData}
              />
            )
          })
        }
        {
          editMode ?
            <div className="profile__form__group">
              <div
                className={`profile__form__more`}
                ref={addedMoreButtonRef}
                onClick={() => onAddEducationHandler()}
              >
                <img src={plusIc} alt="plust icon" className="profile__form__more--icon"/>
                <p className="profile__form__more--text">Add one more experience</p>
              </div>
            </div>
            : null
        }
        {
          displayButton && editMode ?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel</button>
                <button className="profile__form__submit button--filled" ref={submitButtonRef}>Save</button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
    </AuthGuard>
  )
}

export default memo(ExperienceDetailsTab)